import session from '@/api/session';

export enum TrackerTaskActions {
  START_TASK = 'START_TASK',
  STOP_TASK = 'STOP_TASK',
}

export type ManageTaskTrackingBody = {
  action: TrackerTaskActions;
};

export default {
  manageTaskTracking({ taskId, requestBody }: { taskId: number; requestBody: ManageTaskTrackingBody }) {
    return session.patch<null>(`/tasks/tracker/task-tracking/${taskId}/`, requestBody);
  },
};
