export default {
  error: 'An error has occurred when retrieving history',
  toTask: 'To card',
  taskChanges: 'Changes in card',
  change: 'Change',
  when: 'When',
  initiator: 'Initiator',
  withoutStatus: 'Without status',

  executor: {
    added: 'added',
    deleted: 'deleted',
    executor: 'Assignee',
    responsible: 'Supervisor',
  },

  stage: {
    added: 'added',
    deleted: 'deleted',
  },

  files: {
    added: 'added',
    deleted: 'deleted',
  },

  tag: {
    added: 'added',
    deleted: 'deleted',
  },

  links: {
    blocks: 'Blocks',
    blocked: 'Blocked',
  },

  checklist: {
    isDeleted: '(Checklist is deleted)',
    added: 'added',
    deleted: 'deleted',
  },

  archived: 'Sent to archive',
  restored: 'Restored from archive',

  description: 'Description has been changed',

  taskCreated: 'The card has been created',
};
