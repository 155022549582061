export default {
  filters: {
    status: 'By status',
    priority: 'By priority',
    stage: 'By stage',
    executor: 'By assignee',
  },
  listing: {
    board: 'Board',
    list: 'List',
    matrix: 'Matrix',
  },
  enhancedSearch: 'Filters + search',
  search: 'Search',
  addTask: 'Card',
};
