import type { MessageContext } from 'vue-i18n';

export default {
  title: ({ named }: MessageContext) => {
    if (named('resolved')) {
      return `Checklist (${named('resolved')}/${named('total')})`;
    }

    return 'Checklist';
  },
  error: 'Error editing checklist',
};
