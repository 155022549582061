import { type App, computed, readonly } from 'vue';

import MQ from './private/MQ.vue';
import { useMediaQuery } from './private/hooks';

import styles from '@/styles/_variables.module.scss';

const DESKTOP_MQ = `(min-width: ${styles.screenMd})`;

const mediaQuery = {
  install: (app: App) => {
    const isDesktop = useMediaQuery(DESKTOP_MQ);

    const target = computed(() => (isDesktop.value ? 'desktop' : 'phone'));
    app.provide('mq', readonly(target));
    // TODO(Refactoring for future): replace with inject or hook
    app.config.globalProperties.$mq = readonly(target);
    app.component('MQ', MQ);
  },
};

export const initMediaQuery = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.use(mediaQuery);
    resolve();
  });
};
