const createInitialState = () => ({
  previousTitle: [],
});

const state = createInitialState();

const mutations = {
  setTitle(_skip, title) {
    state.previousTitle = document.title;
    document.title = title;
  },
  setPreviousTitle(state) {
    document.title = state.previousTitle;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },

  SET_TITLE({ commit }, title) {
    commit('setTitle', title);
  },

  SET_PREVIOUS_TITLE({ commit }) {
    commit('setPreviousTitle');
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
};
