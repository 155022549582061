import { z } from 'zod';

import { financesGroups } from '@/models/finances/Finance';

export enum AccountTypeCodes {
  Account = 'bank_account',
  Card = 'bank_card',
  Cash = 'cash',
  EMoney = 'e_money',
}

export enum Currency {
  RUB = 'rub',
  EUR = 'eur',
  USD = 'usd',
}

export const accountField = z.object({
  id: z.number().optional(),
  name: z.string(),
  value: z.string().optional(),
});

export const financesAccount = z.object({
  id: z.number(),
  name: z.string(),
  account_type: z.nativeEnum(AccountTypeCodes),
  group: z.number().nullable(),
  group_detail: financesGroups.nullable(),
  balance: z.string(),
  record_account_balance: z.boolean(),
  date_recorded_account_balance: z.string().nullable(),
  currency: z.nativeEnum(Currency),
  archived: z.boolean(),
  fields: z.array(accountField),
  fields_to_delete: z.array(z.number()).optional(),
});

export const accountTemplate = z.object({
  id: z.number(),
  name: z.string(),
  type: z.nativeEnum(AccountTypeCodes),
  fields: z.array(accountField),
  can_delete: z.boolean(),
});

export const accountSettings = z.array(z.string());

export type FinancesAccount = z.infer<typeof financesAccount>;
export type AccountTemplate = z.infer<typeof accountTemplate>;
export type AccountField = z.infer<typeof accountField>;
export type AccountSettings = z.infer<typeof accountSettings>;
