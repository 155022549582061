import templates from './templates';

import actions from '@/locales/en/tasks/actions';
import cards from '@/locales/en/tasks/cards';
import details from '@/locales/en/tasks/details/index';
import filters from '@/locales/en/tasks/filters';
import history from '@/locales/en/tasks/history';
import listing from '@/locales/en/tasks/listing';
import preferences from '@/locales/en/tasks/preferences';
import repeat from '@/locales/en/tasks/repeat';
import screenshots from '@/locales/en/tasks/screenshots';
import shared from '@/locales/en/tasks/shared';
import statusForm from '@/locales/en/tasks/statusForm';
import subtasks from '@/locales/en/tasks/subtasks';
import taskCover from '@/locales/en/tasks/taskCover';

export default {
  noData: 'No results. Try to change your filtering settings',
  notSelected: 'not selected',
  createTask: 'Create card',
  newTask: 'New card',
  viewers: 'card viewers',

  install:
    'Before you start your card, please install the {tracker} so you can track your time and collect your stats.',
  runTracker: 'start your tracker',

  user: {
    noTasks: {
      title: 'You have no cards for now',
      createFirstTask: 'Create your first card and set yourself as assignee;',
    },
  },

  project: {
    noTasks: {
      title: 'There are no cards in your project for now',
      createTasks: 'Create cards to perform',
    },
  },

  listing,
  preferences,
  details,
  history,
  filters,
  screenshots,
  repeat,
  cards,
  statusForm,
  shared,
  subtasks,
  actions,
  templates,
  taskCover,
};
