import Translation from '@/utils/translation';

import { svgIcons } from '@/constants/icons';

export enum customFieldCodes {
  USERS = 'users',
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  FILES = 'file',
  LINK = 'link',
  EMAIL = 'email',
  PHONE = 'phone',
  PRICE = 'cost',
  TAGS = 'menu',
  DURATION = 'duration',
}

const customFields = [
  {
    code: customFieldCodes.USERS,
    title: new Translation('constants.tasks.customFields.users.title'),
    description: new Translation('constants.tasks.customFields.users.description'),
    icon: {
      name: svgIcons.USERS,
      fill: false,
    },
  },
  {
    code: customFieldCodes.TEXT,
    title: new Translation('constants.tasks.customFields.text.title'),
    description: new Translation('constants.tasks.customFields.text.description'),
    icon: {
      name: svgIcons.TEXT,
      fill: false,
    },
  },
  {
    code: customFieldCodes.NUMBER,
    title: new Translation('constants.tasks.customFields.number.title'),
    description: new Translation('constants.tasks.customFields.number.description'),
    icon: {
      name: svgIcons.POUND,
      fill: false,
    },
  },
  {
    code: customFieldCodes.DATE,
    title: new Translation('constants.tasks.customFields.date.title'),
    description: new Translation('constants.tasks.customFields.date.description'),
    icon: {
      name: svgIcons.CALENDAR,
    },
  },
  {
    code: customFieldCodes.FILES,
    title: new Translation('constants.tasks.customFields.files.title'),
    description: new Translation('constants.tasks.customFields.files.description'),
    icon: {
      name: svgIcons.ATTACHMENT,
      fill: false,
    },
  },
  {
    code: customFieldCodes.DURATION,
    title: new Translation('constants.tasks.customFields.duration.title'),
    description: new Translation('constants.tasks.customFields.duration.description'),
    icon: {
      name: svgIcons.CLOCK,
    },
  },
  {
    code: customFieldCodes.LINK,
    title: new Translation('constants.tasks.customFields.link.title'),
    description: new Translation('constants.tasks.customFields.link.description'),
    icon: {
      name: svgIcons.LINK,
    },
  },
  {
    code: customFieldCodes.EMAIL,
    title: new Translation('constants.tasks.customFields.email.title'),
    description: new Translation('constants.tasks.customFields.email.description'),
    icon: {
      name: svgIcons.AT,
    },
  },
  {
    code: customFieldCodes.PHONE,
    title: new Translation('constants.tasks.customFields.phone.title'),
    description: new Translation('constants.tasks.customFields.phone.description'),
    icon: {
      name: svgIcons.PHONE,
      fill: false,
    },
  },
  {
    code: customFieldCodes.PRICE,
    title: new Translation('constants.tasks.customFields.price.title'),
    description: new Translation('constants.tasks.customFields.price.description'),
    icon: {
      name: svgIcons.PRICE,
    },
  },
  {
    code: customFieldCodes.TAGS,
    title: new Translation('constants.tasks.customFields.tags.title'),
    description: new Translation('constants.tasks.customFields.tags.description'),
    icon: {
      name: svgIcons.TASK_TAGS,
      fill: false,
    },
  },
];

export type CustomField = (typeof customFields)[number];

export enum DateFieldCodes {
  DATE = 'date',
  TIME = 'time',
  DATE_TIME = 'datetime',
}
export const dateCustomFieldTypes = [
  {
    code: DateFieldCodes.DATE,
    title: new Translation('constants.tasks.customFields.dateTypes.date'),
  },
  {
    code: DateFieldCodes.TIME,
    title: new Translation('constants.tasks.customFields.dateTypes.time'),
  },
  {
    code: DateFieldCodes.DATE_TIME,
    title: new Translation('constants.tasks.customFields.dateTypes.datetime'),
  },
];

export default customFields;
