export default {
  subtask: 'Subtask',
  subtasks: 'Subtasks',
  openToSeeSubtasks: 'Open a task to see subtasks',
  newSubtask: 'New subtask',
  deleteConfirm: {
    title: 'Are you sure you want to permanently delete the subtask?',
    btnCancel: 'Cancel',
    btnConfirm: 'Delete',
  },
  subtaskTimerToggle: 'Include subtask time in the timer',
};
