import { ViewTypes } from '@/models/views/View';

import { svgIcons } from '@/constants/icons';
import { icons } from '@/constants/views/viewIcons';

export type ViewIcon = { name: string; fill?: boolean } | '';

export default class ViewHelper {
  public static getIcon({ icon, type, emoji }: { icon: string; type: ViewTypes; emoji: string }): ViewIcon {
    if (!emoji && !icon) {
      return this.getDefaultIcon(type);
    }
    return icons.find(({ name }) => name === icon) ?? '';
  }

  private static getDefaultIcon(type: ViewTypes): ViewIcon {
    switch (type) {
      case ViewTypes.Board:
        return { name: svgIcons.BOARD };
      case ViewTypes.List:
        return { name: svgIcons.LIST };
      case ViewTypes.Matrix:
        return { name: svgIcons.MATRIX, fill: false };
      case ViewTypes.Calendar:
        return { name: svgIcons.CALENDAR };
      case ViewTypes.Files:
        return { name: svgIcons.ATTACHMENT, fill: false };
      case ViewTypes.Gantt:
        return { name: svgIcons.GANTT, fill: false };
      case ViewTypes.Timeline:
        return { name: svgIcons.TIMELINE, fill: false };
      case ViewTypes.Folder:
        return { name: svgIcons.FOLDER };
      default:
        return { name: svgIcons.BOARD };
    }
  }
}
