import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { portalNames } from '@/constants/portals';

export const handbookRoutes = [
  {
    path: 'datasource',
    name: pageNames.teams.HANDBOOK_DATASOURCE,
    component: () => import('@/pages/teams/handbook/DataSources.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.dataSources'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoDataSources'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.dataSources'),
        },
      ],
    },
  },
  {
    path: 'datasource/:datasourceId(\\d+)/',
    name: pageNames.teams.HANDBOOK_DATASOURCE_DETAILS,
    component: () => import('@/pages/teams/handbook/DatasourceDetails.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId), datasourceId: Number(route.params.datasourceId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.dataSources'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoDatasourceDetails'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.dataSources'),
          url: {
            name: pageNames.teams.HANDBOOK_DATASOURCE,
          },
        },
        {
          portal: portalNames.breadcrumbs.datasource,
        },
      ],
    },
  },

  {
    path: 'statuses',
    name: pageNames.teams.HANDBOOK_STATUSES,
    component: () => import('@/pages/teams/handbook/Statuses.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.statuses'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoStatuses'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.statuses'),
        },
      ],
    },
  },

  {
    path: 'tags',
    name: pageNames.teams.HANDBOOK_TAGS,
    component: () => import('@/pages/teams/handbook/Tags.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.tags'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoTags'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.tags'),
        },
      ],
    },
  },

  {
    path: 'custom-fields',
    name: pageNames.teams.HANDBOOK_CUSTOM_FIELDS,
    component: () => import('@/pages/teams/handbook/CustomFields.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.customFields'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoCustomFields'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.customFields'),
        },
      ],
    },
  },
  {
    path: 'card-types',
    name: pageNames.teams.HANDBOOK_CARD_TYPE,
    component: () => import('@/pages/teams/handbook/CardTypesPage.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.cardTypes'),
      seoTitle: new Translation('routes.teamSettings.handbook.cardTypes'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.cardTypes'),
        },
      ],
    },
  },
  {
    path: 'card-types/:cardTypeId(\\d+)/',
    name: pageNames.teams.HANDBOOK_CARD_TYPE_DETAILS,
    component: () => import('@/pages/teams/handbook/CardTypeDetail.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId), cardTypeId: Number(route.params.cardTypeId) }),
    meta: {
      title: new Translation('routes.teamSettings.handbook.cardType'),
      seoTitle: new Translation('routes.teamSettings.handbook.seoCardType'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.handbook.cardTypes'),
          url: {
            name: pageNames.teams.HANDBOOK_CARD_TYPE,
          },
        },
      ],
    },
  },
];
