import CRUD from '@/api/feeds/CRUD';
import channels from '@/api/feeds/channels';
import comments from '@/api/feeds/comments';
import files from '@/api/feeds/files';
import likes from '@/api/feeds/likes';
import poll from '@/api/feeds/poll';

export default {
  ...CRUD,
  ...files,
  ...likes,
  ...comments,
  ...poll,
  ...channels,
};
