export default {
  placeholderName: 'Enter the title',
  standardFields: 'Standard fields',
  customFields: 'Custom fields',
  createField: 'Add a field',
  checklist: 'Check list',
  noItems: 'No items available',
  noUsages: 'Not used',
  noModules: 'No modules',
  description: 'Description',
  files: 'Files',
  subTasks: 'Subtasks',
  comment: 'Comments',
  executor: 'Executor',
  responsible: 'Responsible',
  deadline: 'Deadline',
  status: 'Status',
  priority: 'Priority',
  estimate: 'Estimate',
  stage: 'Stage',
  connections: 'Links',
  labels: 'Labels',
  cost: 'Cost',
};
