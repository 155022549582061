import apiFeeds from '@/api/feeds';

const root = { root: true };

const state = {
  childrenComments: {},
};

const mutations = {
  addChildrenComments(state, { comments, parentId }) {
    const parentComments = state.childrenComments[parentId];

    if (!parentComments) {
      state.childrenComments[parentId] = comments;
      return;
    }

    state.childrenComments[parentId] = {
      ...parentComments,
      ...{
        count: comments.count,
        num_pages: comments.num_pages,
        results: [...(comments.results || [])],
      },
    };
  },

  deleteChildrenComments(state, { parentId, commentId }) {
    const parentComments = state.childrenComments[parentId];
    const parentCommentsList = parentComments.results || [];

    state.childrenComments[parentId] = {
      ...parentComments,
      ...{
        count: parentCommentsList.length - 1,
        results: parentCommentsList.filter((comment) => comment.id !== commentId),
        update: Math.random(),
      },
    };
  },

  deleteAllChildrenComments(state, parentId) {
    delete state.childrenComments[parentId];
  },

  updateChildrenComments(state, { parentId, commentId, commentValue }) {
    const parentComments = state.childrenComments[parentId];
    const parentCommentsList = parentComments.results || [];

    state.childrenComments[parentId] = {
      ...parentComments,
      ...{
        results: parentCommentsList.map((comment) => {
          if (comment.id === commentId) {
            return commentValue;
          }
          return comment;
        }),
        update: Math.random(),
      },
    };
  },

  reset(state) {
    state.files = [];
  },
};

const actions = {
  async CREATE_COMMENT({ commit, rootState }, { feedId, requestBody }) {
    try {
      const { data: comment } = await apiFeeds.addFeedComment({ feedId, requestBody });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);

      if (!feed.comments) return null;

      if (requestBody.parent) return null;

      const feedCommentsList = feed.comments?.results || [];
      commit(
        'feeds/updateFeed',
        {
          ...feed,
          comments: {
            ...feed.comments,
            ...{
              results: [...feedCommentsList, comment || {}],
              count: feedCommentsList.length + 1,
            },
          },
        },
        root,
      );
      return true;
    } catch (e) {
      console.error('Ошибка при попытки создать комментарий для новости');
      throw e;
    }
  },

  async EDIT_COMMENT({ commit, rootState }, { feedId, commentId, requestBody, parentId }) {
    try {
      const { data: comment } = await apiFeeds.editFeedComment({ feedId, commentId, requestBody });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);

      if (parentId) {
        commit('updateChildrenComments', { commentId, parentId, commentValue: comment });
        commit(
          'feeds/updateFeed',
          {
            ...feed,
            comments: {
              ...feed.comments,
              update: Math.random(),
            },
          },
          root,
        );
        return;
      }

      commit(
        'feeds/updateFeed',
        {
          ...feed,
          comments: {
            ...feed.comments,
            results: feed.comments.results.map((comm) => {
              if (comm.id === commentId) return comment;
              return comm;
            }),
          },
        },
        root,
      );
      return true;
    } catch (e) {
      console.error('Ошибка при попытки создать комментарий для новости');
      throw e;
    }
  },

  async DELETE_COMMENT({ commit, rootState }, { feedId, commentId, parentId }) {
    try {
      await apiFeeds.deleteFeedComment({ feedId, commentId });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);
      commit(
        'feeds/updateFeed',
        { ...feed, feed_likes: feed.feed_likes.filter((like) => like.id !== commentId) },
        root,
      );
      const feedCommentsList = feed.comments?.results || [];

      if (parentId) {
        commit('deleteChildrenComments', { commentId, parentId });
        commit(
          'feeds/updateFeed',
          {
            ...feed,
            comments: {
              ...feed.comments,
              update: Math.random(),
            },
          },
          root,
        );
        commit('deleteAllChildrenComments', commentId);
        return;
      }

      if (feedCommentsList.length === 1) {
        commit(
          'feeds/updateFeed',
          {
            ...feed,
            ...{
              comments: null,
              is_have_comments: false,
            },
          },
          root,
        );
        return;
      }
      commit(
        'feeds/updateFeed',
        {
          ...feed,
          comments: {
            ...feed.comments,
            ...{
              results: feedCommentsList.filter((comment) => comment.id !== commentId),
              count: feedCommentsList.length - 1,
            },
          },
        },
        root,
      );
    } catch (e) {
      console.error('Ошибка при удалении лайка новости', e);
      throw e;
    }
  },

  async GET_FIRST_FEED_COMMENTS({ commit, rootState }, { feedId, getParams = {} }) {
    try {
      const { data: comments } = await apiFeeds.getFeedComments({ feedId, getParams });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);
      if (!feed.comments) {
        commit('feeds/updateFeed', { ...feed, comments }, root);
        return;
      }
      const feedCommentsList = feed.comments?.results || [];
      commit(
        'feeds/updateFeed',
        {
          ...feed,
          comments: {
            ...feed.comments,
            results: [...feedCommentsList, ...(comments.results || [])],
          },
        },
        root,
      );
    } catch (e) {
      console.error('Ошибка при получении комментариев к новости', e);
      throw e;
    }
  },

  async GET_FEED_COMMENT_CHILDREN({ commit }, { feedId, getParams = {}, parentId }) {
    try {
      const { data: comments } = await apiFeeds.getFeedChildrenComment({ feedId, getParams, parentId });
      commit('addChildrenComments', { comments, parentId });
    } catch (e) {
      console.error('Ошибка при получении ответов на комментарий к новости', e);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
