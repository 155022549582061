import routerHelper from '@/router/helper';
import goTo404Page from '@/router/helper/goTo404Page';

const checkResponse = ({ response = {} }) => {
  if (response.status === 403 && response.data.detail) {
    routerHelper.goToNoAccessPage();
  }
  if (response.status === 404) {
    goTo404Page();
  }
};

export default checkResponse;
