export default {
  selectPlan: 'Select plan',

  continueWithFree: 'Continue with free plan',
  activateTrial: 'Activate trial period',

  tariff: {
    saving: 'Saving {price}',
    freeForever: 'Free, forever',
    description: 'Per participant per month if paid for the {period}. {price} per participant if paid per month.',
    monthDescription: 'The longer subscription period you choose, the more you save.',
    popular: 'Popular',

    connect: 'Connect',
    connected: 'Connected',
    connectFree: 'Connect for free for {days}',

    allFeatures: 'All features {tariff}',

    soon: 'soon',
  },

  freeTariff: {
    connected: 'Connected',
    label: 'Free',
    connect: 'Connect',
    usersCount: 'Up to {count} employees',
  },
};
