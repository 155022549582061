import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageTeam } from '@/constants/permissions/listPermissions';
import { portalNames } from '@/constants/portals';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'members/:memberId',
    component: () => import('@/pages/teams/TeamMember.vue'),
    beforeEnter: requirePermission,
    meta: {
      title: new Translation('routes.team.teamSettings'),
    },
    children: [
      {
        path: '',
        meta: {
          parent: 'TeamSettings.Members',
          title: new Translation('routes.team.members'),
          seoTitle: new Translation('routes.team.seoMembers'),
          breadcrumbs: [
            {
              title: new Translation('routes.teamSettings.team'),
              url: {
                name: pageNames.teams.TEAM_SETTINGS,
              },
            },
            {
              title: new Translation('routes.team.members'),
              url: {
                name: pageNames.teams.TEAM_SETTINGS_MEMBERS,
              },
            },
            {
              portal: portalNames.breadcrumbs.teamSettingsMember,
            },
          ],
        },
        name: pageNames.teams.TEAM_SETTINGS_MEMBERS_MEMBER,
        component: () => import('@/components/team/member/General.vue'),
      },
    ],
  },
];
