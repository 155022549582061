import apiProjects from '@/api/projects';

const root = { root: true };

const createInitialState = () => ({
  projects: [],
  projectSelected: null,
  projectSelectedMembers: [],
});

const state = createInitialState();

const getters = {
  projectsActive(state) {
    return state.projects.filter((project) => !project.archived);
  },
};

const mutations = {
  setProjects(state, projects) {
    state.projects = [...state.projects, projects];
  },
  addProject({ projects }, project) {
    projects.push(project);
  },

  setProjectSelected(state, project) {
    state.projectSelected = project;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },

  async GET_PROJECTS(_skip, { teamId, ordering = null }) {
    try {
      const getParams = {};

      if (ordering !== null) {
        getParams.ordering = ordering;
      }

      const requestData = {
        teamId,
        getParams,
      };

      return await apiProjects.getProjects(requestData);
    } catch (e) {
      console.error('Ошибка при получении проектов');
      throw e;
    }
  },

  async GET_PROJECTS_SELECTED_TEAM({ commit, dispatch, rootState }, ordering = false) {
    try {
      commit('loading/startLoading', 'projects', root);
      const teamSelected = rootState.teams.teamSelected;

      if (ordering && teamSelected) {
        const { data: projects } = await dispatch('GET_PROJECTS', {
          teamId: teamSelected.id,
          ordering: '-date_archived',
        });
        commit('setProjects', projects);
      } else if (teamSelected) {
        const { data: projects } = await dispatch('GET_PROJECTS', { teamId: teamSelected.id });
        commit('setProjects', projects);
      }
    } catch (e) {
      console.error('Ошибка при получении проектов текущей команды');
      throw e;
    } finally {
      commit('loading/finishLoading', 'projects', root);
    }
  },

  async SELECT_PROJECT({ commit }, projectId) {
    try {
      const { data: project } = await apiProjects.getProjectDetails(projectId);
      commit('setProjectSelected', project);
    } catch (e) {
      console.error('Ошибка при выборе проекта');
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
