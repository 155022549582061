import groupActions from './groupActions';

export default {
  board: {
    addStatus: 'Status',
    createStatus: 'Create status',
  },

  list: {
    task: 'Card',
    dueDate: 'Due date',
    id: 'ID',
    tags: 'Tags',
    project: 'Project',
    executor: 'Assignee',
    executors: 'Assignees',
    users: 'Users',
  },

  matrix: {
    quickly: 'Urgent',
    notQuickly: 'Not urgent',
    important: 'Important',
    notImportant: 'Not important',

    noTasks: 'No cards',
  },

  calendar: {
    withoutDeadline: 'No deadline',

    noTasks: 'Move card here to remove the deadline',
  },

  confirm: {
    archive: 'Are you sure you want to move your card to archive?',
    btnConfirm: 'Archive',
    btnCancel: 'Cancel',
  },

  today: 'Today',
  yesterday: 'Yesterday',
  tomorrow: 'Tomorrow',
  addTask: 'Add card',
  newTask: 'New card',
  executorNotSelected: 'Executor not selected',
  enterStageName: 'Enter stage name',

  groupActions,
};
