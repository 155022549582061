export default {
  create: 'Create new tag',
  edit: 'Edit tag',

  name: 'Name',
  tagName: 'Name of tag',
  color: 'Color',

  save: 'Save',
  cancel: 'Cancel',
  delete: 'Delete',

  unpin: 'Unpin',
  editTag: 'Edit',
  confirm: 'Are you sure you want to delete «{tag}» tag?',

  placeholder: 'Input name',

  teamTags: 'Team tags',
  popup: {
    createTag: 'Create tag',
    noTags: 'No tags',
    noTag: 'There is no such tag',
    noAttached: 'No tags attached',
    create: 'Create a new tag or go to Team Tags',
  },
  notification: 'This tag already exists',
};
