export default {
  blocked: 'User blocked',

  online: {
    today: 'Last seen online at {time}',
    yesterday: 'Seen online yesterday at {time}',
    beforeYesterday: 'Seen online the day before yesterday at {time}',
    date: 'Has been online on {date}',
  },
};
