import session from '@/api/session';
import { taskTemplate, type TaskTemplateDueDateType, taskTemplateListItem } from '@/models/tasks/TaskTemplate';

import ZodHelper from '@/helpers/ZodHelper';

import type { TaskPriorityCodes } from '@/constants/tasks/taskPriorities';

import type { Nullable } from '@/types/utility';

type GetTemplatesParams = { datasourceId: number; getParams?: { search?: string } };
type DeleteTemplateParams = GetTemplatesParams & { templateId: number };
type DeleteTemplates = GetTemplatesParams & {
  requestBody: {
    task_templates: number[];
  };
};

type CreateTemplateParams = GetTemplatesParams & {
  requestBody: { name: string; type: number };
};
type EditTemplateParams = DeleteTemplateParams & {
  requestBody: {
    name?: string;
    description?: string;
    priority?: TaskPriorityCodes;
    planned_time_minutes?: number;
    stage?: Nullable<number>;
    task_labels_add?: number;
    task_labels_remove?: number;
    cost?: string;
    currency_value?: number;
    is_favorite?: boolean;
    status?: Nullable<number>;
    type?: number;
    schedule_type?: TaskTemplateDueDateType;
    custom_schedule_start_day_of_month?: number;
    custom_schedule_start_day_of_week?: number;
    custom_schedule_end_day_of_month?: number;
    custom_schedule_end_day_of_week?: number;
    custom_schedule_start_time?: Nullable<string>;
    custom_schedule_end_time?: Nullable<string>;
    prev_value?: Nullable<number>;
  };
};

type DuplicateTemplateParams = DeleteTemplateParams;
type CreateTaskFromTemplateParams = DeleteTemplateParams & {
  requestBody: {
    status?: number;
    stage?: number;
    priority?: TaskPriorityCodes;
    executor?: number;
    task_label?: number;
    task_deadline_date?: string;
    task_template: number;
  };
};
type GetTemplateDetailsParams = DeleteTemplateParams;

export const apiTaskTemplatesCRUD = {
  getTemplates({ datasourceId, getParams }: GetTemplatesParams) {
    return ZodHelper.request(
      () => session.get(`tasks/task-templates/${datasourceId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(taskTemplateListItem.array()),
    );
  },
  getTemplateDetails({ datasourceId, templateId }: GetTemplateDetailsParams) {
    return ZodHelper.request(() => session.get(`tasks/task-templates/${datasourceId}/${templateId}/`), taskTemplate);
  },
  deleteTemplate({ datasourceId, templateId }: DeleteTemplateParams) {
    return session.delete(`tasks/task-templates/${datasourceId}/${templateId}/`);
  },
  deleteTemplates({ datasourceId, requestBody }: DeleteTemplates) {
    return session.post(`tasks/task-templates/${datasourceId}/mass-delete/`, requestBody);
  },
  createTemplate({ datasourceId, requestBody }: CreateTemplateParams) {
    return ZodHelper.request(() => session.post(`tasks/task-templates/${datasourceId}/`, requestBody), taskTemplate);
  },
  editTemplate({ datasourceId, templateId, requestBody }: EditTemplateParams) {
    return ZodHelper.request(
      () => session.patch(`tasks/task-templates/${datasourceId}/${templateId}/`, requestBody),
      taskTemplate,
    );
  },
  toggleTemplateFavorite({ datasourceId, templateId }: DeleteTemplateParams) {
    return session.post(`tasks/task-templates/${datasourceId}/switch-favorite/${templateId}/`);
  },
  createTaskFromTemplate({ datasourceId, templateId, requestBody }: CreateTaskFromTemplateParams) {
    return session.post(`tasks/task-templates/${datasourceId}/${templateId}/`, requestBody);
  },
  createTemplateFromTask({ datasourceId, taskId }: { datasourceId: number; taskId: number }) {
    return session.post(`tasks/task-templates/${datasourceId}/save/${taskId}/`);
  },
  duplicateTemplate({ datasourceId, templateId }: DuplicateTemplateParams) {
    return ZodHelper.request(
      () => session.post(`tasks/task-templates/${datasourceId}/${templateId}/copy/`),
      taskTemplateListItem,
    );
  },
};
