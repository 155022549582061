import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canUseFeed } from '@/constants/permissions/listBillingPermissions';
import { portalNames } from '@/constants/portals';

const requirePermission = createPermissionHook(canUseFeed, { name: pageNames.errors.PAGE_NO_ACCESS }, 'billing');

export default [
  {
    path: 'feeds',
    name: pageNames.feeds.FEEDS_PAGE,
    meta: {
      seoTitle: new Translation('routes.feeds.feeds'),
      breadcrumbs: [
        {
          title: new Translation('routes.feeds.feeds'),
        },
      ],
    },
    component: () => import('@/pages/feeds/Feeds.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'feeds/:feedId',
    name: pageNames.feeds.FEED_DETAILS_PAGE,
    meta: {
      seoTitle: new Translation('routes.feeds.feeds'),
      breadcrumbs: [
        {
          title: new Translation('routes.feeds.feeds'),
          url: {
            name: pageNames.feeds.FEEDS_PAGE,
          },
        },
        {
          portal: portalNames.breadcrumbs.feedNumber,
        },
      ],
    },
    component: () => import('@/pages/feeds/FeedDetails.vue'),
    beforeEnter: requirePermission,
  },
];
