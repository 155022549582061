const LAST_TEAM_LS_KEY = 'lastTeamId';

export default class TeamHelper {
  public static setLastTeamId(id: string) {
    localStorage.setItem(LAST_TEAM_LS_KEY, id);
  }

  public static getLastTeamId() {
    const lastTeam = localStorage.getItem(LAST_TEAM_LS_KEY);
    return Number.isNaN(Number(lastTeam)) ? null : lastTeam;
  }
}
