import session from '@/api/session';

export interface AddTaskResponsibleData {
  requestBody: {
    task: number;
    responsible: number;
  };
}
export interface TaskResponsibleStatusData {
  taskId: number;
  userId: number;
  requestBody: {
    is_confirmed_closing: boolean;
  };
}

export default {
  addTaskResponsible({ requestBody }: AddTaskResponsibleData) {
    return session.post(`/tasks/responsible/create/`, requestBody);
  },
  deleteTaskResponsible(taskId: number, responsibleId: number) {
    return session.delete(`/tasks/responsible/${taskId}/delete/${responsibleId}/`);
  },
  updateTaskResponsibleStatus({ taskId, userId, requestBody }: TaskResponsibleStatusData) {
    return session.patch(`/tasks/responsible/${taskId}/update/${userId}/`, requestBody);
  },
};
