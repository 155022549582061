export default {
  create: 'Create a payout',
  executor: 'Assignee',

  rate: 'Rate',
  selectedRate: '{rate} per hour',
  setRate: 'Specify rate',
  selectExecutor: 'Select an assignee',

  period: 'Time interval',
  commission: 'Commission',
  amount: 'How much',
};
