import activity from '@/locales/en/constants/activity';
import billing from '@/locales/en/constants/billing/index';
import countries from '@/locales/en/constants/countries';
import payouts from '@/locales/en/constants/payouts';
import projects from '@/locales/en/constants/projects';
import reports from '@/locales/en/constants/reports';
import stages from '@/locales/en/constants/stages';
import tasks from '@/locales/en/constants/tasks/index';
import teams from '@/locales/en/constants/teams';
import timeline from '@/locales/en/constants/timeline/index';
import user from '@/locales/en/constants/user';
import wizard from '@/locales/en/constants/wizard';

export default {
  teams,
  user,
  billing,
  countries,
  tasks,
  projects,
  reports,
  payouts,
  activity,
  stages,
  wizard,
  timeline,
};
