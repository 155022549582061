export default {
  priceTypes: {
    amount: 'Amount',
    percent: 'Percentage',
  },

  listing: {
    payouts: 'Payouts',
    executors: 'Assignees',
  },
};
