import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageTeam } from '@/constants/permissions/listPermissions';
import { portalNames } from '@/constants/portals';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'billing/payments',
    name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYMENTS,
    meta: {
      title: new Translation('routes.teamSettings.billing'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.billing'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING,
          },
        },
        {
          title: new Translation('routes.teamSettings.billingPayments'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYMENTS,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Billing.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'billing/payers',
    name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYERS,
    meta: {
      title: new Translation('routes.teamSettings.billing'),
      seoTitle: new Translation('routes.teamSettings.billing'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.billing'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING,
          },
        },
        {
          title: new Translation('routes.teamSettings.billingPayers'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYERS,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Billing.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'billing/payers/payer',
    name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYERS_PAYER,
    meta: {
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.billing'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING,
          },
        },
        {
          title: new Translation('routes.teamSettings.billingPayers'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_PAYERS,
          },
        },
        {
          portal: portalNames.breadcrumbs.billingPayerAction,
        },
      ],
    },
    component: () => import('@/pages/billing/EditPayer.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'billing/cards',
    name: pageNames.teams.TEAM_SETTINGS_BILLING_CARDS,
    meta: {
      title: new Translation('routes.teamSettings.billing'),
      seoTitle: new Translation('routes.teamSettings.billing'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.billing'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING,
          },
        },
        {
          title: new Translation('routes.teamSettings.billingCards'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_CARDS,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Billing.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'billing/cards/card',
    name: pageNames.teams.TEAM_SETTINGS_BILLING_CARDS_CARD,
    meta: {
      title: new Translation('routes.billing.cardSettings'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.billing'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING,
          },
        },
        {
          title: new Translation('routes.teamSettings.billingCards'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_CARDS,
          },
        },
        {
          title: new Translation('routes.billing.addCard'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_BILLING_CARDS_CARD,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Card.vue'),
    beforeEnter: requirePermission,
  },
];
