export default {
  menu: {
    views: 'Views',
    teams: 'Teams',
    team: 'Team',
    finances: 'Finances',
    directions: 'Directions',
    salary: 'Salary',
    articles: 'Articles',
    accounts: 'Accounts',
    transactions: 'Transactions',
    reports: 'Reports',
    reportConsolidated: 'Consolidated report',
    reportActivity: 'Activity',
    counterparts: 'Counterparts',
    feeds: 'Feeds',
  },
  views: {
    favorite: 'Favorite',
    allInOne: 'All-in-one',
    cases: 'My tasks',
    archive: 'Archive',
  },
  filters: {
    title: 'My filters',
  },
  footer: {
    upgradeTariff: 'Improve tariff',
    extendTariff: 'Extend tariff',
    trial: 'Trial period',
    selectTariff: 'Select tariff',

    teamSettings: 'Team settings',
    downloadTracker: 'Download Tracker',

    help: 'Help',
    helpTitle: 'Got questions? Write or check out the instructions 😉',
    helpPlaceholder:
      "We have taken care of your comfortable work. So check out our knowledge base, and if you still can't find the answer to your question, write to support or sign up for a demo to learn how to customize Shtab for business.",
    faq: 'Instructions and FAQs',
    demo: 'Online demo to solve business problems',
    social: 'Our social networks',
    knowledgeBase: 'Knowledge Base',
    write: 'Write',
    signUp: 'Sign Up',
    telegram: 'Telegram',
    vk: 'VK',
    youtube: 'Youtube',

    logout: 'Logout',
  },
  collapse: {
    open: 'Expand',
    closed: 'Collapse',
  },
  billing: {
    finances: 'Change the tariff to open the tool - finance',
  },
};
