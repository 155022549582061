import router from '@/router';

import apiAuth from '@/api/auth';

import AxiosHelper from '@/helpers/AxiosHelper';
import routerHelper from '@/router/helper';

import RequestError from '@/errors/RequestError';

const root = { root: true };

// Проверяем localStorage при загрузке приложения
const tokenAuth = localStorage.getItem('AUTH');
const tokenCentrifuge = localStorage.getItem('centrifuge');

if (tokenAuth) {
  AxiosHelper.getHeaders('Shared') && AxiosHelper.deleteHeaders('Shared');
  AxiosHelper.setHeaders('Authorization', `Token ${tokenAuth}`);
}

const initialState = {
  token: tokenAuth,
  tokenCentrifuge,
};

const getters = {
  isAuthenticated: (state) => Boolean(state.token),
};

const mutations = {
  setToken(state, token) {
    localStorage.setItem('AUTH', token);
    AxiosHelper.setHeaders('Authorization', `Token ${token}`);
    state.token = token;
  },
  setTokenCentrifuge(state, token) {
    localStorage.setItem('centrifuge', token);
    state.tokenCentrifuge = token;
  },
  deleteTokens(state) {
    localStorage.removeItem('AUTH');
    localStorage.removeItem('centrifuge');
    AxiosHelper.deleteHeaders('Authorization');
    state.token = null;
    state.tokenCentrifuge = null;
  },
};

const actions = {
  SOCIAL_LOGIN({ commit }, { token, centrifugoToken }) {
    try {
      commit('setToken', token);
      commit('setTokenCentrifuge', centrifugoToken);
    } catch (e) {
      console.error('Ошибка при социальной авторизации пользователя');
      throw e;
    }
  },
  async LOGIN({ commit }, { username, password }) {
    try {
      const {
        data: { token, user, centrifugo_token },
      } = await apiAuth.login(username, password);
      commit('setToken', token);
      commit('setTokenCentrifuge', centrifugo_token);
      return user;
    } catch (e) {
      console.error('Ошибка при авторизации пользователя');
      throw e.response;
    }
  },
  async LOGOUT({ commit, state, dispatch }) {
    try {
      await apiAuth.logout(state.token);
      commit('deleteTokens');
      await router.push({ name: routerHelper.pageNames.auth.LOGIN });
      dispatch('RESET_DATA', null, root);
    } catch (e) {
      console.error('Ошибка при выходе пользователя из системы');
      throw e;
    }
  },
  async REGISTER(_skip, requestBody) {
    try {
      await apiAuth.register(requestBody);
    } catch (e) {
      throw new RequestError(e);
    }
  },
  async CHANGE_PASSWORD(_skip, payload) {
    try {
      await apiAuth.changePassword(payload);
    } catch (e) {
      console.error('Ошибка при изменении пароля пользователя');
      throw new RequestError(e);
    }
  },
  async ACTIVATE_ACCOUNT({ commit }, requestBody) {
    try {
      const {
        data: { token, centrifugo_token },
      } = await apiAuth.activateAccount(requestBody);
      commit('setToken', token);
      commit('setTokenCentrifuge', centrifugo_token);
    } catch (e) {
      console.error('Ошибка при активации аккаунта пользователя');
      throw e;
    }
  },
  async RESEND_ACTIVATION_LETTER(_skip, requestBody) {
    try {
      await apiAuth.resendActivationLetter(requestBody);
    } catch (e) {
      console.error('Ошибка при повторной отправке письма для активации профиля');
      throw e;
    }
  },
  async RESTORE_PASSWORD(_skip, username) {
    try {
      await apiAuth.restorePassword(username);
    } catch (e) {
      console.error('Ошибка при восстановлении пароля пользователя');
      throw e;
    }
  },
  async RESTORE_ACCOUNT(_skip, data) {
    try {
      await apiAuth.restoreAccount(data);
    } catch (e) {
      console.error('Ошибка при восстановлении аккаунта пользователя');
      throw e;
    }
  },
  async RESTORE_CHECK_TOKEN(_skip, requestBody) {
    try {
      await apiAuth.restoreCheckToken(requestBody);
    } catch (e) {
      console.error('Ошибка при проверке токена для восстановления пароля');
      throw e;
    }
  },
  async RESTORE_CHANGE_PASSWORD(_skip, requestBody) {
    try {
      const { data } = await apiAuth.restoreChangePassword(requestBody);
      return data;
    } catch (e) {
      console.error('Ошибка при изменении пароля пользователя при восстановлении');
      throw e;
    }
  },
  async REGISTER_INVITED_USER({ commit }, requestBody) {
    try {
      const { data } = await apiAuth.registerInvitedUser(requestBody);
      commit('setTokenCentrifuge', data.centrifugo_token);
      return data;
    } catch (e) {
      console.error('Ошибка при регистрации приглашенного в команду пользователя');
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
