export default {
  creation: 'Created',
  deadline: 'Deadline',
  name: 'Name',
  priority: 'Priority',
  status: 'Status',
  duration: 'Duration',
  executor: 'Assignee',
  responsible: 'Supervisor',
  stage: 'Stage',
  checklist: 'Checklist',
  comment: 'Comment',
  commentEdited: 'Comment (edited)',
  description: 'Description',
  dueDate: 'Due date',
  cost: 'Cost',
  tag: 'Tag',
  subtask: 'Subtask',
  links: 'Links',
  file: 'Files',
  archive: 'Archive',
};
