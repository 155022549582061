export const customField = {
  customFields: 'Custom fields',
  subtitle: 'The fields will be added in team {name}',
  manageFields: 'Field management',
  defaultFields: 'Default field',
  createdFields: 'Created fields',
  emptyCustomFields: "You don't have custom fields yet",
  fullAvailable: 'Available to everyone',

  enterTitle: 'Enter the title',
  visibility: 'Visibility',
  visibilityTooltip: 'The field will always be visible to the owner and administrator',
  create: 'Create',
  save: 'Save',
  cancel: 'Cancel',

  format: 'Format',
  enterFormat: 'Enter format',
  values: 'Values',
  multipleChoice: 'Multiple choice',

  descriptions: {
    date: 'Make appointments, calls, deadlines, etc.',
    duration: 'Keep track of rework time, estimate the time spent on the project, etc.',
    email: 'Up to 10 email addresses per task',
    files: 'Up to 10 files per task. You can limit files by type (.txt, .pdf, .jpg, etc.)',
    link: 'Up to 10 links per issue',
    number: 'Indicate the number of people on the project, inventory numbers, etc.',
    phone: 'Up to 10 phone numbers per task',
    price: 'Specify the price of the work (₽, $, €)',
    tags: 'This will help you additionally filter tasks by line of work, a specific project, etc',
    text: 'Describe in detail the task for the developer, Tone of Voice for the copywriter, etc.',
    users:
      'You can specify the name of a group of people (subscribers, editors, etc.), each of them will see the task for themselves and will not miss anything important',
  },

  constants: {
    executors: 'Executors',
    responsible: 'Responsible',
    dueDate: 'Due date',
    duration: 'Duration',
    status: 'Status',
    priority: 'Priority',
    stage: 'Stage',
    links: 'Links',
    tags: 'Tags',
    cost: 'Cost',
  },
};
