import profile from '@/store/modules/user/actions/profile';
import permissions from '@/store/modules/user/modules/permissions';

const USD_CURRENCY = {
  code: 'USD',
  symbol: '$',
};
const RUB_CURRENCY = {
  code: 'RUB',
  symbol: '₽',
};

const state = {
  userProfile: null,
};

const getters = {
  userCurrency(state) {
    // The structure must match the structure of the team's currency, for the same work with currencies
    return state.userProfile?.country === 'RU' ? RUB_CURRENCY : USD_CURRENCY;
  },
};

const mutations = {
  setUserProfile(state, profile) {
    state.userProfile = profile;
  },
  setUserAvatar(state, avatar) {
    state.userProfile = {
      ...state.userProfile,
      avatar,
    };
  },
  incrementUnreadUpdatesCount(state) {
    state.userProfile = { ...state.userProfile, unreaded_notices_count: state.userProfile.unreaded_notices_count + 1 };
  },
  decrementUnreadUpdatesCount(state) {
    state.userProfile = { ...state.userProfile, unreaded_notices_count: state.userProfile.unreaded_notices_count - 1 };
  },
  resetUnreadUpdatesCount(state) {
    state.userProfile = { ...state.userProfile, unreaded_notices_count: 0 };
  },

  resetUserProfile(state) {
    state.userProfile = null;
  },
};

const actions = {
  RESET({ commit }) {
    commit('resetUserProfile');
    commit('permissions/reset');
  },

  ...profile,
};

export default {
  namespaced: true,

  modules: {
    permissions,
  },

  state,
  getters,
  actions,
  mutations,
};
