import apiTeams from '@/api/teams';

import TeamHelper from '@/helpers/TeamHelper';

import { OWNER } from '@/constants/permissions/listRoles';

import RequestError from '@/errors/RequestError';
import member from '@/store/modules/teams/modules/member';

const root = { root: true };

const createInitialState = () => ({
  teams: [],
  teamSelected: null,
});

const state = createInitialState();

const getters = {
  teamSelectedCanUsersDeleteFlag() {
    return state.teamSelected?.can_usual_user_remove_tasks;
  },

  teamSelectedCurrency() {
    return state.teamSelected?.currency;
  },
};

const mutations = {
  setTeams(state, teams) {
    state.teams = teams;
  },
  updateTeam(state, teamNew) {
    state.teams = state.teams.map((team) => (team.id === teamNew.id ? teamNew : team));
  },

  setTeamSelected(state, team) {
    state.teamSelected = team;
    TeamHelper.setLastTeamId(team.id);
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
    commit('member/reset');
  },

  async CREATE_TEAM({ dispatch }, requestBody) {
    try {
      const { data: team } = await apiTeams.createTeam(requestBody);
      localStorage.setItem('userRole', JSON.stringify(OWNER));
      dispatch('RESET_DATA', null, root);

      return team;
    } catch (e) {
      console.error('Ошибка при создании команды');
      throw e;
    }
  },
  async SET_TEAM_AVATAR({ commit }, { teamId, data }) {
    try {
      const { data: team } = await apiTeams.setTeamAvatar({ teamId, data });
      commit('setTeamSelected', team);
      commit('updateTeam', team);
    } catch (e) {
      console.error('Ошибка при добавлении аватара команды');
      throw e;
    }
  },
  async GET_TEAMS({ commit }) {
    try {
      const { data: teams } = await apiTeams.getTeams();
      commit('setTeams', teams);
      return teams;
    } catch (e) {
      console.error('Ошибка при получении списка команд');
      throw e;
    }
  },
  async UPDATE_TEAM({ commit }, requestData) {
    try {
      const { data: team } = await apiTeams.updateTeam(requestData);
      commit('updateTeam', team);
      commit('setTeamSelected', team);
      return team;
    } catch (e) {
      console.error('Ошибка при обновлении информации о команде');
      throw e;
    }
  },
  async UPDATE_TEAM_MEMBER(
    {
      state: {
        teamSelected: { id: teamId },
      },
    },
    requestData,
  ) {
    try {
      await apiTeams.updateTeamMember({ teamId, ...requestData });
    } catch (e) {
      console.error('Ошибка при обновлении данных члена команда');
      throw new RequestError(e);
    }
  },
  async GET_TEAM_MEMBERS(_skip, teamId) {
    try {
      const { data: teamMembers } = await apiTeams.getTeamMembers(teamId);
      return teamMembers;
    } catch (e) {
      console.error('Ошибка при редактировании права пользователя');
      throw e;
    }
  },
  async INVITE_PERSON(_skip, requestData) {
    try {
      const { data } = await apiTeams.invitePerson(requestData);
      return data;
    } catch (e) {
      console.error('Ошибка при приглашении пользователя в команду');
      throw new RequestError(e);
    }
  },
  async CHECK_INVITATION_TOKEN(_skip, tokenInvitation) {
    try {
      const { data } = await apiTeams.checkInvitationToken(tokenInvitation);
      return data;
    } catch (e) {
      console.error('Ошибка при проверке токена для приглашения в команду');
      throw e;
    }
  },
  async DELETE_TEAM({ state, dispatch, commit }, teamId) {
    try {
      await apiTeams.deleteTeam(teamId);
      const teams = await dispatch('GET_TEAMS');

      const isSelectedTeamWasDeleted = teamId === state.teamSelected.id;
      if (isSelectedTeamWasDeleted) {
        teams.length ? await dispatch('SET_TEAM_SELECTED', teams[0]) : commit('setTeamSelected', null);
      }
    } catch (e) {
      console.error('Ошибка при удалении команды');
      throw e;
    }
  },
  async SEND_DELETE_TEAM(_skip, requestData) {
    try {
      await apiTeams.sendDeleteTeam(requestData);
    } catch (e) {
      console.error('Ошибка при удалении команды');
      throw e;
    }
  },
  async DELETE_TEAM_MEMBER(_skip, { teamMemberId, teamId }) {
    try {
      await apiTeams.deleteTeamMember({ teamMemberId, teamId });
    } catch (e) {
      console.error('Ошибка при удалении члена команды');
      throw e;
    }
  },
  async INITIALIZE_TEAM({ commit, dispatch, rootGetters }, teamId) {
    try {
      // При первой загрузке приложения нет смысла делать ресет
      if (!rootGetters['loading/isLoading']('app')) {
        dispatch('RESET_DATA_CONTENT', null, root);
      }

      const { data: team } = await apiTeams.getTeamDetails(teamId);
      commit('setTeamSelected', team);
      commit('billing/setTariff', team.billing_settings, root);

      await Promise.all([
        dispatch('user/permissions/INITIALIZE_USER_PERMISSIONS', team.team_member, root),
        dispatch('billing/permissions/INITIALIZE_BILLING_PERMISSIONS', team.billing_settings, root),
      ]);
    } catch (e) {
      console.error('Ошибка при выборе команды', e);
      throw e;
    }
  },
  async ACCEPT_INVITATION({ dispatch }, token) {
    try {
      const requestBody = {
        token,
      };
      await apiTeams.acceptInvitation(requestBody);
      await dispatch('GET_TEAMS');
    } catch (e) {
      console.error('Ошибка при принятии приглашения в команду');
      throw e;
    }
  },
  async DECLINE_INVITATION(_skip, token) {
    try {
      const requestBody = {
        token,
      };
      await apiTeams.declineInvitation(requestBody);
    } catch (e) {
      console.error('Ошибка при отклонении приглашения в команду');
      throw e;
    }
  },
};

export default {
  namespaced: true,

  modules: {
    member,
  },

  state,
  getters,
  actions,
  mutations,
};
