export default {
  filters: {
    all: 'Current projects',
    favorites: 'Favorites',
  },

  files: {
    open: 'Open',
    rename: 'Rename',
    download: 'Download',
    share: 'Link',
    toTrash: 'Move to Trash',
    restore: 'Restore',
    delete: 'Delete permanently',

    byName: 'By name',
    byDate: 'By upload date',
    byType: 'By type',
    bySize: 'By size',
    byDelete: 'By date of removal',
  },
};
