import router from '@/router';

import pageNames from '@/router/helper/pageNames';

const goToDefaultPage = (teamId) => {
  const defaultPage = {
    name: pageNames.views.USER_VIEW,
  };

  if (teamId) defaultPage.params = { teamId };

  router.push(defaultPage);
};

export default goToDefaultPage;
