import i18n from '@/plugins/i18n';

import Translation from '@/utils/translation';

import type { Dict } from '@/types/utility';

const useTranslate = () => {
  const translate = (title: Translation | string, values?: Dict<any>) => {
    if (title instanceof Translation) {
      return values ? i18n.t(title.path, values) : i18n.t(title.path);
    }
    return values ? i18n.t(title, values) : i18n.t(title);
  };

  return { translate };
};

export default useTranslate;
