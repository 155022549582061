export const merge = {
  submit: 'Merge',
  cancel: 'Cancel',

  status: {
    info: 'The status will be deleted, all tasks will move to the selected status',
    title: 'Status merging',
    selectStatus: 'Select status',
  },
  tag: {
    info: 'The tag will be deleted, all tasks will move to the selected tag',
    title: 'Tag merging',
    selectTag: 'Select tag',
  },
  cardType: {
    info: 'The card type will be removed, all cards with this type will be converted to the selected card type',
    title: 'Merging a card type',
    selectCardType: 'Select card type',
  },
};
