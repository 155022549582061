import { z } from 'zod';

import { user } from '@/models/User';

export const taskTrackedTime = z
  .object({
    users_data: z.array(
      user
        .extend({ time: z.number(), is_tracking: z.boolean(), finished: z.boolean().nullable() })
        .transform((value) => ({
          ...value,
          isTracking: value.is_tracking,
          isFinished: value.finished ?? false,
        })),
    ),
    task_data: z.object({
      total_wasted_time: z.number(),
    }),
  })
  .transform((value) => ({
    users: value.users_data,
    totalTime: value.task_data.total_wasted_time,
  }));

export type TaskTrackedTime = z.infer<typeof taskTrackedTime>;
