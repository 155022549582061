import apiFeeds from '@/api/feeds';

const root = { root: true };

const actions = {
  async MAKE_VOTE({ commit, rootState }, { pollId, feedId, requestBody }) {
    const { feeds = {} } = rootState;
    const feed = feeds.feeds.find((it) => it.id === feedId);

    try {
      const { data: __feed = {} } = await apiFeeds.makeVote({ pollId, requestBody });
      commit('feeds/updateFeed', { ...feed, poll: __feed.poll }, root);
    } catch (e) {
      console.error('Ошибка при попытке проголосовать');
      const { response = {} } = e;

      if (response.data && response.data.stoped) {
        commit('feeds/updateFeed', { ...feed, poll: { ...feed.poll, stoped: true } }, root);
      }
      throw e.response.data || {};
    }
  },

  async UPDATE_POLL({ commit, rootState }, { feedId, pollId, requestBody }) {
    try {
      const { data: updatedPoll = {} } = await apiFeeds.updatePoll({ pollId, requestBody });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);
      commit('feeds/updateFeed', { ...feed, poll: { ...feed.poll, ...updatedPoll } }, root);
    } catch (e) {
      console.error('Ошибка при попытке редактировать опрос', e);
      throw e;
    }
  },

  UPDATE_VOTE({ commit, rootState }, { feedId }) {
    const { feeds = {} } = rootState;
    const feed = feeds.feeds.find((it) => it.id === feedId);
    commit('feeds/updateFeed', { ...feed, poll: { ...feed.poll, editMode: true } }, root);
  },
};

export default {
  namespaced: true,
  actions,
};
