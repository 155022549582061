export default {
  preferences: {
    tariff: 'Plan management',
    add: 'Add',
    gb: ' GB',
    allFiles: 'All files',
    trash: 'Trash',
    clearTrash: 'Clear trash',
  },
  selectedFiles: {
    selected: 'Selected: {selected} files',
    download: 'Download',
    trash: 'Move to trash',
    restore: 'Restore',
    delete: 'Delete',
  },
  progressUpload: {
    title: 'Uploading files',
    time: '{time} mins remaining',
    success: 'Files uploaded successfully',
    withError: 'Check the files which have not been uploaded',
    cancel: 'Cancel',
    confirm: {
      title: 'Are you sure you want to cancel uploading files?',
      btnCancel: 'Back',
      btnConfirm: 'Cancel upload',
    },
  },
  list: {
    name: 'Name',
    uploaded: 'Uploaded',
    type: 'Type',
    size: 'Size',
    deletionDate: 'Deletion date',
  },
  actions: {
    confirm: {
      move: {
        title: 'Are you sure you want to move file(s) to trash?',
        btnConfirm: 'Move',
      },
      delete: {
        title: 'Are you sure you want to permanently delete files from trash?',
        btnConfirm: 'Delete',
      },
      btnCancel: 'Cancel',
    },
    notification: 'File(s) restored successfully',
  },

  limitConfirm: {
    title: 'Files upload is not available',
    description:
      'File size is greater than available storage for the team You may increase available storage in Plan settings.',
    btnCancel: 'Back to files',
    btnConfirm: 'Plan management',
  },
  leaveConfirm: {
    title: 'You may lose data if you leave this page',
    btnCancel: 'Stay here',
    btnConfirm: 'Leave',
  },

  noResults: 'No results',
  trashIsEmpty: 'Trash is empty',
};
