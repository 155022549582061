import apiFeeds from '@/api/feeds';

const root = { root: true };

const actions = {
  async CREATE_LIKE({ commit, rootState }, { feedId, requestBody }) {
    try {
      const { data: like } = await apiFeeds.createFeedLike({ feedId, requestBody });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);
      commit('feeds/updateFeed', { ...feed, feed_likes: [...feed.feed_likes, like] }, root);
    } catch (e) {
      console.error('Ошибка при попытке поставить лайк новости');
      throw e;
    }
  },

  async DELETE_LIKE({ commit, rootState }, { feedId, likeId }) {
    try {
      await apiFeeds.deleteFeedLike({ likeId });
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === feedId);
      commit('feeds/updateFeed', { ...feed, feed_likes: feed.feed_likes.filter((like) => like.id !== likeId) }, root);
    } catch (e) {
      console.error('Ошибка при удалении лайка новости', e);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  actions,
};
