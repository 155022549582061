import i18n from '@/plugins/i18n';

import apiDataSources from '@/api/dataSources';

import { BillingHelper } from '@/utils/BillingHelper';
import directS3Upload from '@/utils/directS3Upload';

import { maxFileSizeAtFreePlans } from '@/constants/billing/maxFileSizeAtFreePlans';

const root = { root: true };

const createInitialState = () => ({
  files: [],
  maxPages: null,
  filesSelected: [],
  loadProgress: [],
});

const state = createInitialState();

const mutations = {
  setFiles(state, files) {
    state.files = files;
  },
  setMaxPages(state, maxPages) {
    state.maxPages = maxPages;
  },
  setLoadProgress(state, progress) {
    const item = state.loadProgress.find((item) => item.name === progress.name);

    if (item) {
      if (progress.isError) {
        return (item.count = -1);
      }

      if (progress.count < 95) {
        item.count = progress.count;
        item.timeBeforeUpload = progress.timeBeforeUpload;
      }

      if (progress.isFinish) {
        item.count = 100;
        item.timeBeforeUpload = 0;
      }
    } else if (progress.count < 100) {
      state.loadProgress.push(progress);
    }
  },

  addSelectedFile(state, file) {
    state.filesSelected.push(file);
  },
  addFile(state, file) {
    state.files.unshift(file);
  },

  updateFile(state, newFile) {
    state.files = state.files.map((file) => {
      if (file.id === newFile.id) {
        return newFile;
      }
      return file;
    });
  },

  deleteSelectedFile(state, fileId) {
    const index = state.filesSelected.findIndex((file) => file.id === fileId);
    state.filesSelected.splice(index, 1);
  },
  deleteLoadProgress(state, name) {
    state.loadProgress = state.loadProgress.filter((progress) => progress.name !== name);
  },
  deleteFile(state, fileId) {
    const index = state.files.findIndex((file) => file.id === fileId);
    state.files.splice(index, 1);
  },

  resetSelectedFiles(state) {
    state.filesSelected = [];
  },
  resetLoadProgress(state) {
    state.loadProgress = [];
  },
  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },

  async GET_VIEW_FILES({ commit }, requestData) {
    try {
      commit('loading/startLoading', 'projectFiles', root);

      const {
        data: { results: files, num_pages: maxPages },
      } = await apiDataSources.getFiles(requestData);

      commit('setFiles', files);
      commit('setMaxPages', maxPages);
    } catch (e) {
      console.error('Ошибка при получении файлов проекта', e);
      throw e;
    } finally {
      commit('loading/finishLoading', 'projectFiles', root);
    }
  },

  async EDIT_VIEW_FILES_PLACE({ commit }, requestData) {
    try {
      const { data: files } = await apiDataSources.editFilesPlace(requestData);
      files.forEach((file) => {
        commit('deleteFile', file.id);
      });
    } catch (e) {
      console.error('Ошибка при изменении расположения проекта', e);
      throw e;
    }
  },
  async EDIT_VIEW_FILE_NAME({ commit }, requestData) {
    try {
      const { data: file } = await apiDataSources.editFileName(requestData);
      commit('updateFile', file);
    } catch (e) {
      console.error('Ошибка при изменении названия файла проекта', e);
      throw e;
    }
  },

  async ADD_VIEW_FILES({ commit, rootGetters, dispatch }, requestData) {
    try {
      if (!BillingHelper.isFileSizeValid(requestData.fd.get('file'), rootGetters['billing/planType'])) {
        dispatch(
          'notifications/SHOW_ERROR',
          i18n.t('notifications.fileUploadLimit', { mbSize: Math.floor(maxFileSizeAtFreePlans / 1000000) }),
          root,
        );
        return;
      }

      if (JSON.parse(import.meta.env.VITE_DIRECT_S3_UPLOAD)) {
        const { teamId, datasourceId } = requestData;
        const { file } = await directS3Upload({
          id: teamId,
          preSignRequest: ({ fileData, signature }) =>
            apiDataSources.preSignFileForS3({ teamId, datasourceId, fileData, signature }),
          file: requestData.file,
        });
        commit('addFile', file);
      } else {
        const { data: file } = await apiDataSources.addFiles(requestData);
        commit('addFile', file);
      }

      if (requestData.config && requestData.config.name) {
        commit('setLoadProgress', { name: requestData.config.name, count: 100, isFinish: true });
      }
    } catch (e) {
      commit('setLoadProgress', { name: requestData.config.name, count: 100, isError: true });
      console.error('Ошибка при добавлении файлов проекта', e);
      throw e;
    }
  },

  async ADD_TEAM_DOCUMENTS({ commit, rootGetters, dispatch }, requestData) {
    try {
      if (!BillingHelper.isFileSizeValid(requestData.fd.get('file'), rootGetters['billing/planType'])) {
        dispatch(
          'notifications/SHOW_ERROR',
          i18n.t('notifications.fileUploadLimit', { mbSize: Math.floor(maxFileSizeAtFreePlans / 1000000) }),
          root,
        );
        return;
      }

      const { data: file } = await apiDataSources.addTemporaryFiles(requestData);
      commit('addFile', file);

      if (requestData.config && requestData.config.name) {
        commit('setLoadProgress', { name: requestData.config.name, count: 100, isFinish: true });
      }
      return file;
    } catch (e) {
      commit('setLoadProgress', { name: requestData.config.name, count: 100, isError: true });
      console.error('Ошибка при добавлении файлов проекта', e);
      throw e;
    }
  },
  async DELETE_VIEW_FILES({ commit }, requestData) {
    try {
      await apiDataSources.deleteFiles(requestData);
      const { datasource_files: files } = requestData.requestBody;
      files.forEach((file) => {
        commit('deleteFile', file);
      });
    } catch (e) {
      console.error('Ошибка при удалении файлов поекта', e);
      throw e;
    }
  },
  async DELETE_ALL_FILES({ commit }, projectId) {
    try {
      await apiDataSources.deleteAllFiles(projectId);
      commit('reset');
    } catch (e) {
      console.error('Ошибка при скачивании файлов поекта', e);
      throw e;
    }
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  actions,
};
