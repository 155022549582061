import activity from '@/locales/en/modals/activity/index';
import billing from '@/locales/en/modals/billing/index';
import cardType from '@/locales/en/modals/cardType';
import createPayout from '@/locales/en/modals/createPayout';
import files from '@/locales/en/modals/files/files';
import globalSearch from '@/locales/en/modals/globalSearch';
import profile from '@/locales/en/modals/profile/index';
import share from '@/locales/en/modals/share';
import tableSettings from '@/locales/en/modals/tableSettings';
import task from '@/locales/en/modals/task/index';
import { teams } from '@/locales/en/modals/teams';

export default {
  footerSave: 'Save',
  cardType,
  activity,
  billing,
  task,
  createPayout,
  profile,
  files,
  teams,
  tableSettings,
  share,
  globalSearch,
};
