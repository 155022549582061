import { localize } from '@vee-validate/i18n';
import * as allRules from '@vee-validate/rules';
import PhoneNumber from 'awesome-phonenumber';
import { defineRule, configure } from 'vee-validate';

import dictionary from '@/constants/veevalidate';

export const initVeeValidate = async () => {
  await new Promise((resolve) => {
    configure({
      generateMessage: localize(dictionary),
    });

    // TODO(Refatoring for future): defining all rules is has a bad affect on the size of the bundle
    Object.keys(allRules).forEach((rule) => {
      defineRule(rule, allRules[rule]);
    });

    defineRule('is_different_password', (value, [target]) => value !== target);
    defineRule('phone_number', (value) => {
      const phoneNumber = new PhoneNumber(value, 'RU');
      return phoneNumber.isValid();
    });
    defineRule('phone_number_start_on_plus', (value) => value.startsWith('+'));
    defineRule('alpha_and_num', (value) => /^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/.test(value));

    resolve();
  });
};
