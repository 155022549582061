export default {
  roles: {
    admin: 'Administrator',
    manager: 'Manager',
    user: 'User',
    owner: 'Lead',
  },
  memberFilters: {
    all: 'All members',
    users: 'Users',
    managers: 'Managers',
    blocked: 'Blocked',
  },
  handbook: {
    datasource: {
      title: 'Title',
      usedIn: 'Used in',
      key: 'Key',
      comment: 'Comment',
    },

    datasourceTabs: {
      general: 'General',
      statuses: 'Statuses',
      stages: 'Stages',
      customFields: 'Custom fields',
      templates: 'Templates',
    },

    status: {
      color: 'Color',
      title: 'Title',
      cardsCount: 'Cards count',
    },

    customField: {
      title: 'Title',
      type: 'Type',
      dataSources: 'Databases',
    },

    tag: {
      color: 'Color',
      title: 'Title',
    },

    stage: {
      color: 'Color',
      title: 'Title',
      period: 'Period',
      comment: 'Comment',
    },

    template: {
      title: 'Title',
      executor: 'Executor',
      repeat: 'Repeat',
    },

    cardTypes: {
      title: 'Title',
      content: 'Content',
      comment: 'Comment',
    },
  },
};
