import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageTeam } from '@/constants/permissions/listPermissions';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'tariff',
    name: pageNames.teams.TEAM_SETTINGS_TARIFF,
    meta: {
      title: new Translation('routes.teamSettings.tariff'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.tariff'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_TARIFF,
          },
        },
      ],
    },
    component: () => import('@/components/billing/general/Billing.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'members',
    name: pageNames.teams.TEAM_SETTINGS_MEMBERS,
    meta: {
      title: new Translation('routes.teamSettings.members'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.members'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_MEMBERS,
          },
        },
      ],
    },
    component: () => import('@/components/team/settings/members/Members.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'imports',
    name: pageNames.teams.TEAM_SETTINGS_IMPORTS,
    meta: {
      title: new Translation('routes.teamSettings.imports'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.imports'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_IMPORTS,
          },
        },
      ],
    },
    component: () => import('@/components/billing/general/TeamImports.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'imports',
    name: pageNames.teams.TEAM_SETTINGS_IMPORTS,
    meta: {
      title: new Translation('routes.teamSettings.imports'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.imports'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_IMPORTS,
          },
        },
      ],
    },
    component: () => import('@/components/billing/general/TeamImports.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'integrations',
    name: pageNames.teams.TEAM_SETTINGS_INTEGRATIONS,
    meta: {
      title: new Translation('routes.teamSettings.integrations'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.integrations'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_INTEGRATIONS,
          },
        },
      ],
    },
    component: () => import('@/components/billing/general/integrations/Integrations.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'billing',
    name: pageNames.teams.TEAM_SETTINGS_BILLING,
    redirect: 'payments',
  },
  {
    path: 'general',
    name: pageNames.teams.TEAM_SETTINGS_GENERAL,
    meta: {
      title: new Translation('routes.teamSettings.general'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.general'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_GENERAL,
          },
        },
      ],
    },
    component: () => import('@/components/team/settings/General.vue'),
    beforeEnter: requirePermission,
  },
];
