export const canDeleteTeam = 'canDeleteTeam';
export const canManageTeam = 'canManageTeam';

export const canCreateViews = 'canCreateViews';
export const canManageViews = 'canManageViews';

export const canViewTasks = 'canViewTasks';
export const canCreateTasks = 'canCreateTasks';
export const canManageTasks = 'canManageTasks';
export const canDeleteTasks = 'canDeleteTasks';
export const canCreateTaskComments = 'canCreateTaskComments';

export const canManagePayouts = 'canManagePayouts';

export const canViewReports = 'canViewReports';

export const canManageActivity = 'canManageActivity';

export const canAddManualTime = 'canAddManualTime';

export default [
  canCreateTaskComments,
  canViewTasks,
  canDeleteTeam,
  canManageTeam,
  canCreateTasks,
  canManageTasks,
  canDeleteTasks,
  canManagePayouts,
  canViewReports,
  canManageActivity,
  canCreateViews,
  canManageViews,
];
