import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';

import { i18n } from '@/plugins/i18n';
import router from '@/router';
import store from '@/store';

import I18nHelper from '@/helpers/I18nHelper';
import PushNotificationsHelper from '@/helpers/PushNotificationsHelper';

import App from './App.vue';
import { initAllPlugins } from './plugins';

import { initAllDirectives } from '@/directives/global';
// import '@/registerServiceWorker';

// Add route module to store
sync(store, router);
PushNotificationsHelper.initialize();
const app = createApp(App);

// split ru-Ru
const browserLocale = window.navigator.language.split('-')[0];

Promise.all([
  initAllPlugins(app),
  initAllDirectives(app),
  ...(I18nHelper.isSupportedLocale(browserLocale) ? [I18nHelper.setLocale(browserLocale)] : []),
]).then(() => {
  app.use(router).use(store).use(i18n);

  app.mount('#app');
});
