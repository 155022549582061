import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { update } from '@/models/Update';

import ZodHelper from '@/helpers/ZodHelper';

type GetUpdatesParams = {
  userId: number;
  getParams: {
    page: number;
  };
  config: Pick<AxiosRequestConfig, 'signal'>;
};
type EditUpdateParams = {
  userId: number;
  requestBody: {
    id: number;
    is_read?: boolean;
    is_deleted?: boolean;
  };
};

export const apiUpdatesCRUD = {
  getUpdates({ userId, getParams, config }: GetUpdatesParams) {
    return ZodHelper.request(
      () => session.get(`notices/notice/${userId}/`, { ...config, params: getParams }),
      ZodHelper.getPaginationModel(update.array()),
    );
  },
  editUpdate({ userId, requestBody }: EditUpdateParams) {
    return session.patch<null>(`notices/notice/${userId}/`, [requestBody]);
  },
  deleteAll() {
    return session.get<null>('/notices/notice/delete-all/');
  },
  readAll(requestData: { id: number; is_read: boolean }) {
    return session.post('notices/notice/mark-as-read/', requestData);
  },
};
