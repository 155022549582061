export default {
  start: 'Time start',
  end: 'Time end',
  task: 'Card',
  disable: 'The function is not available on your plan',
  datasource: 'Database',
  source: 'Type',
  duration: 'Total time',
  noRules: 'Not enough rights',
  types: {
    desktop: 'Computer',
    manual: 'Manual',
    idle: 'Idle',
    mobile: 'Mobile',
  },
  full: 'Time slot busy',
  split: 'Split',
  delete: 'Delete',
  add: 'Add',
  edit: 'Edit',
  details: 'Details work time',
  editTime: 'Change time',
  nothing: 'Here nothing yet',
  modals: {
    delete: {
      solo: 'Do you really want to delete timeslot?',
      title: 'Do you really want to delete {slots} timeslots?',
      delete: 'Delete',
      cancel: 'Cancel',
    },
  },
};
