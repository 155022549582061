export default {
  member: {
    title: 'Invite a member',
    description: 'Enter an email of a new member and press enter to send an invitation.',
  },

  settings: {
    title: 'Team settings',
    description: 'Track your account balance and storage, add new members to your team and manage existing ones.',
  },

  create: {
    title: 'Welcome to Shtab!',
    description: 'Create your team and start working on projects together',
  },

  billing: {
    title: 'Track your billing',
    description:
      'Customize your tariff and subscription fees, control the amount of storage and the number of employees.',
  },

  members: {
    title: 'Change your team composition',
    description:
      'Edit the roles and access levels of employees. Remove members you no longer work with from your team.',
  },
};
