import { z } from 'zod';

export const currencyModel = z.object({
  id: z.number(),
  decimals: z.number(),
  code: z.string(),
  symbol: z.string(),
});

export type Currency = z.infer<typeof currencyModel>;
