export default {
  addMember: 'Add member',
  inviteToTeam: 'Invite to team',

  teamMembers: 'Team members',
  searchMember: 'Search members',

  members: 'Members',
  role: 'Role',
  rights: 'Rights',

  enterRole: 'Enter role',
  notSpecified: 'not indicated',
  unblock: 'Unblock',
  delete: 'Delete',

  noItems: 'No results. Please modify your search',

  memberBlocked: 'Member blocked',
  inviteSend: 'Team invitation has be sent to the e-mail address provided',
  error: 'Unknown error',

  limit: {
    free: {
      title: 'Free plan limit has been exceeded',
      desc: 'Maximum number of members for free plan: {n}. We recommend you upgrade to a paid plan to increase your member slots.',
      switch: 'Choose a plan',
    },
    count: {
      title: 'Your team has a maximum number of participants',
      desc: 'If you continue, {0} will be written off from your team balance, and member slots will increase to {1}',
      confirm: 'Confirm',
    },

    cancel: 'Cancel',
  },

  confirm: {
    delete: 'Are you sure you want to delete {user}?',
    cancelInvite: 'Are you sure you want to cancel invitation for {user}?',
    back: 'Back',
    btnDelete: 'Delete',
    btnConfirm: 'Cancel invitation',
  },

  notification: {
    delete: 'User {user} has been deleted',
    cancelInvite: 'Invitation for user {user} has been cancelled',
    resendInvite: 'A repeated invitation has been sent to the e-mail address',
  },
};
