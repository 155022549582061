export default {
  month: 'month',
  threeMonths: '3 months',
  sixMonths: '6 months',
  year: 'year',
  twoYears: '2 years',
  threeYears: '3 years',

  discount: 'Discount {count}%',
};
