import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { teamTag } from '@/models/teams/TeamTag';

import ZodHelper from '@/helpers/ZodHelper';

import type { ViewScreens } from '@/constants/views/viewScreens';

import getRequestUrl from '../utils/getRequestUrl';

type GetTags = {
  teamId: number;
  getParams: {
    search?: string;
    exclude_view?: number;
    view?: number;
    type?: ViewScreens;
    task?: number;
  };
};
type GetTagsPaginated = GetTags & {
  getParams: {
    page: number;
  };
  config?: Pick<AxiosRequestConfig, 'signal'>;
};
type CreateTag = Pick<GetTags, 'teamId'> & {
  requestBody: {
    team: number;
    name: string;
    color: string;
    datasource?: number;
  };
};
type EditTag = Pick<GetTags, 'teamId'> & {
  tagId: number;
  requestBody: {
    team: number;
    name: string;
    color: string;
  };
};
type MergeTag = Pick<EditTag, 'teamId' | 'tagId'> & {
  requestBody: {
    merge_to: number;
  };
};
type DeleteTag = Pick<EditTag, 'teamId' | 'tagId'>;

export default {
  getTagsPaginated({ teamId, getParams, config }: GetTagsPaginated) {
    const sharedUrl = `shared/views/${teamId}/${getParams.view}/labels/paginated/`;
    const defaultUrl = `teams/labels/${teamId}/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams, ...config }),
      ZodHelper.getPaginationModel(teamTag.array()),
    );
  },
  getTags({ teamId, getParams }: GetTags) {
    const sharedUrl = `shared/views/${teamId}/${getParams.view}/labels/`;
    const defaultUrl = `teams/labels/${teamId}/no_paginated/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams }),
      teamTag.array(),
    );
  },

  createTag({ teamId, requestBody }: CreateTag) {
    return ZodHelper.request(() => session.post(`/teams/labels/${teamId}/`, requestBody), teamTag);
  },

  editTag({ teamId, tagId, requestBody }: EditTag) {
    return session.patch(`/teams/labels/${teamId}/${tagId}/`, requestBody);
  },
  mergeTag({ teamId, tagId, requestBody }: MergeTag) {
    return session.post(`/teams/labels/${teamId}/merge/${tagId}/`, requestBody);
  },

  deleteTag({ teamId, tagId }: DeleteTag) {
    return session.delete(`/teams/labels/${teamId}/${tagId}/`);
  },
};
