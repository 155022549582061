import session from '@/api/session';
import { tasksUserFilter, tasksUserFilterDetails } from '@/models/tasks/TasksUserFilter';

import ZodHelper from '@/helpers/ZodHelper';

import getRequestUrl from '../utils/getRequestUrl';

import type { Nullable } from '@/types/utility';

type GetUserFilters = { teamId: number };
type CreateUserFilter = GetUserFilters & {
  requestBody: {
    task_filter: string;
    team: number;
  };
};
type DeleteUserFilter = GetUserFilters & { filterId: number };
type EditUserFilter = DeleteUserFilter & {
  requestBody: {
    name: string;
    team: number;
    emoji: string;
    icon: string;
    avatar: Nullable<string>;
    prev_value?: Nullable<number>;
  };
};
type GetUserFilterDetails = {
  teamId?: Nullable<number>;
  viewId?: Nullable<number>;
  requestBody: {
    creators: Array<number>;
    responsibles: Array<number>;
    executors: Array<number>;
    statuses: Array<number>;
    tags: Array<number>;
  };
};

export const filters = {
  getUserFilters({ teamId }: GetUserFilters) {
    return ZodHelper.request(() => session.get(`tasks/filters/${teamId}/`), tasksUserFilter.array());
  },
  getUserFilterDetails({ teamId, viewId, requestBody }: GetUserFilterDetails) {
    const sharedUrl = `/shared/views/${teamId}/${viewId}/filters/`;
    const defaultUrl = '/tasks/filters/';

    return ZodHelper.request(
      () => session.post(getRequestUrl(defaultUrl, sharedUrl), requestBody),
      tasksUserFilterDetails,
    );
  },

  createUserFilter({ teamId, requestBody }: CreateUserFilter) {
    return ZodHelper.request(() => session.post(`tasks/filters/${teamId}/`, requestBody), tasksUserFilter);
  },

  editUserFilter({ teamId, filterId, requestBody }: EditUserFilter) {
    return ZodHelper.request(() => session.patch(`tasks/filters/${teamId}/${filterId}/`, requestBody), tasksUserFilter);
  },

  deleteUserFilter({ teamId, filterId }: DeleteUserFilter) {
    return session.delete(`tasks/filters/${teamId}/${filterId}/`);
  },
};
