import { checkInvitationToken, restoreCheckToken } from '@/router/hooks/auth';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

export default [
  {
    path: 'login',
    name: pageNames.auth.LOGIN,
    meta: {
      title: new Translation('routes.auth.auth'),
    },
    component: () => import('@/pages/noAuthenticated/Login.vue'),
  },
  {
    path: 'login/success',
    name: pageNames.auth.LOGIN_SUCCESS,
    redirect: { path: '/' },
  },
  {
    path: 'registration',
    name: pageNames.auth.REGISTRATION,
    meta: {
      title: new Translation('routes.auth.registration'),
    },
    component: () => import('@/pages/noAuthenticated/registration/Registration.vue'),
  },
  {
    path: 'registration-success',
    name: pageNames.auth.REGISTRATION_SUCCESS,
    meta: {
      title: new Translation('routes.auth.regSuccess'),
    },
    props: true,
    component: () => import('@/pages/noAuthenticated/registration/RegistrationSuccess.vue'),
  },
  {
    path: 'restore-account',
    name: pageNames.auth.RESTORE_ACCOUNT,
    meta: {
      title: new Translation('routes.auth.restoreAccount'),
    },
    component: () => import('@/pages/noAuthenticated/restore/RestoreAccount.vue'),
  },
  {
    path: 'restore-password',
    name: pageNames.auth.RESTORE_PASSWORD,
    meta: {
      title: new Translation('routes.auth.restorePassword'),
    },
    component: () => import('@/pages/noAuthenticated/restore/RestorePassword.vue'),
  },
  {
    path: 'restore-account-success/:email',
    name: pageNames.auth.RESTORE_ACCOUNT_SUCCESS,
    meta: {
      title: new Translation('routes.auth.restoreSuccess'),
    },
    props: true,
    component: () => import('@/pages/noAuthenticated/restore/RestoreAccountSuccess.vue'),
  },
  {
    path: 'restore-check-token',
    name: pageNames.auth.RESTORE_CHECK_TOKEN,
    component: () => new Promise((resolve) => resolve()),
    beforeEnter: restoreCheckToken,
  },
  {
    path: 'restore-change-password',
    name: pageNames.auth.RESTORE_CHANGE_PASSWORD,
    meta: {
      title: new Translation('routes.auth.restoreAccount'),
    },
    component: () => import('@/pages/noAuthenticated/restore/RestoreChangePassword.vue'),
  },
  {
    path: 'activate',
    name: pageNames.auth.ACTIVATE_ACCOUNT,
    meta: {
      title: new Translation('routes.auth.activateAccount'),
    },
    component: () => import('@/pages/noAuthenticated/registration/AccountActivation.vue'),
  },
  {
    path: 'invitation-check-token',
    name: pageNames.auth.INVITATION_CHECK_TOKEN,
    component: () => new Promise((resolve) => resolve()),
    beforeEnter: checkInvitationToken,
  },
  {
    path: 'registration-invited-user',
    name: pageNames.auth.REGISTRATION_INVITED_USER,
    meta: {
      title: new Translation('routes.auth.registration'),
    },
    component: () => import('@/pages/noAuthenticated/invite/RegistrationInvitedUser.vue'),
  },
  {
    path: 'social',
    name: pageNames.auth.SOCIAL,
    meta: {
      title: new Translation('routes.auth.socialAuth'),
    },
    component: () => import('@/pages/noAuthenticated/Social.vue'),
  },
];
