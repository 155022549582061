import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageTeam } from '@/constants/permissions/listPermissions';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.views.USER_VIEW });

export default [
  {
    path: 'billing/card/error',
    meta: {
      title: new Translation('routes.billing.cardError'),
      breadcrumbs: [
        {
          title: new Translation('routes.billing.cardError'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Error.vue'),
    beforeEnter: requirePermission,
  },
];
