import type { App } from 'vue';

const computeSize = (currentLength: any, maxLength: any) => {
  if (!currentLength) {
    return 1;
  }

  if (maxLength) {
    return currentLength < maxLength ? currentLength : maxLength;
  }
  return currentLength;
};

export const initInputAutowidth = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.directive('autowidth', {
      beforeMount(el, binding) {
        const maxLength = binding.value?.maxLength;
        const currentLength = el.value.length;

        if (el.tagName !== 'INPUT')
          throw new Error('Директива autowidth может использоваться только для элементов input');
        el.size = computeSize(currentLength, maxLength);
      },
      updated(el, binding) {
        const maxLength = binding.value?.maxLength;
        const currentLength = el.value.length;

        el.size = computeSize(currentLength, maxLength);
      },
    });

    resolve();
  });
};
