import i18n from '@/plugins/i18n';

import { format } from 'date-fns';
import { ru, enUS as en } from 'date-fns/locale';

const locales = { ru, en };

const formatDate = (date, formatStr = 'PP') => {
  const options = {
    locale: locales[i18n.locale.value],
  };

  if (!date) return i18n.t('common.noDate').toString();

  return typeof date === 'string' ? format(new Date(date), formatStr, options) : format(date, formatStr, options);
};

export default formatDate;
