import router from '@/router';

import pageNames from '@/router/helper/pageNames';

const goTo404Page = () => {
  const Page404 = {
    name: pageNames.errors.PAGE_NOT_FOUND,
  };
  router.push(Page404);
};

export default goTo404Page;
