import { maxFileSizeAtFreePlans } from '@/constants/billing/maxFileSizeAtFreePlans';

const enum PlanTypes {
  Free = 'free',
  Paid = 'paid',
}

export class BillingHelper {
  static isFileSizeValid = (file: File, planType: PlanTypes): boolean => {
    return planType === PlanTypes.Paid || file.size <= maxFileSizeAtFreePlans;
  };
}
