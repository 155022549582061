import type { AxiosError, AxiosResponse } from 'axios';

import routerHelper from '@/router/helper';

import type { Nullable } from '@/types/utility';

export default class RequestError extends Error {
  public readonly response: Nullable<AxiosResponse['data']> = null;

  public readonly status: Nullable<AxiosResponse['status']> = null;

  constructor(e: AxiosError) {
    super();

    if (e.response) {
      const messageFromApi = e.response.data?.message;

      if (messageFromApi) {
        this.message = messageFromApi;
      } else {
        const [firstResponseValue] = Object.values(e.response.data);
        this.message = Array.isArray(firstResponseValue)
          ? firstResponseValue.flat()[0]
          : firstResponseValue ?? e.message;
      }

      this.response = e.response.data;
      this.status = e.response.status;
    } else {
      this.message = e.message;
    }

    this.name = 'RequestError';
  }

  public redirectOnError(): void {
    if (this.status === 403) {
      routerHelper.goToNoAccessPage();
      return;
    }

    if (this.status === 404) {
      routerHelper.goTo404Page();
    }
  }
}
