import session from '@/api/session';

type TaskSharedTokenResponse = {
  'public-token': string;
};

export default {
  createTaskSharedToken(taskId: number) {
    return session.post<TaskSharedTokenResponse>(`shared/tasks/public/token/${taskId}/create/`);
  },

  deleteTaskSharedToken(taskId: number) {
    return session.patch<null>(`shared/tasks/public/token/${taskId}/delete/`);
  },
};
