import axios from 'axios';

import i18n from '@/plugins/i18n';
import router from '@/router';
import store from '@/store';

import AxiosHelper from '@/helpers/AxiosHelper';
import I18nHelper from '@/helpers/I18nHelper';
import routerHelper from '@/router/helper';

const session = axios.create({
  baseURL: import.meta.env.VITE_LOCATION,
});

session.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && store.getters['auth/isAuthenticated']) {
      store.dispatch('auth/LOGOUT');
      return router.push({ name: routerHelper.pageNames.auth.LOGIN });
    }

    // TODO need delete when change api
    // Api returns wrong error code in sharing when no access (401 instead of 403)
    if (error.response.status === 401 && AxiosHelper.getHeaders('Shared')) {
      return routerHelper.goToNoAccessPage();
    }

    return Promise.reject(error);
  },
);

session.interceptors.request.use((config) => {
  config.baseURL = I18nHelper.getApiLocale(i18n.locale.value);
  return config;
});

export default session;
