// Модуль используется, чтобы иметь возможность открыть вторую модалку поверх текущей.
// Нужно было для вывода картинок
const createInitialState = () => ({
  image: {
    isShow: false,
    imageSrc: '',
  },
});

const state = createInitialState();

const mutations = {
  setOverlay(state, { type, ...data }) {
    state[type] = data;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },

  SHOW_OVERLAY({ commit }, payload) {
    commit('setOverlay', { ...payload, isShow: true });
  },
  CLOSE_OVERLAY({ commit }, type) {
    commit('setOverlay', { type, isShow: false });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
