export default {
  finances: 'Finances',
  teamSettings: 'Team settings',
  tariff: 'Plan',
  tariffs: 'Plans',
  cardSettings: 'Card settings',
  topUpBalance: 'Add funds',
  addCard: 'Add card',
  cardError: 'Error adding card',
  addPayer: 'Add payer',
  invoice: 'Account',
};
