<template>
  <slot v-if="isSelectedTarget" />
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';

import { useMq } from '@/hooks/common/mediaQuery';

const props = defineProps({
  mq: {
    type: String as PropType<'desktop' | 'phone'>,
    required: true,
    validator: (value: string) => ['desktop', 'phone'].includes(value),
  },
});

const { isMobile, isDesktop } = useMq();

const isSelectedTarget = computed(() => (props.mq === 'phone' ? isMobile.value : isDesktop.value));
</script>
