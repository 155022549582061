export default {
  filters: 'Filters',
  stage: 'Stage',
  dueDate: 'Due date',
  status: 'Status',
  executor: 'Assignee',
  creator: 'Creator',
  responsible: 'Supervisor',
  cardType: 'Card type',
  tags: 'Tags',
  datasource: 'Database',
  content: 'Content',
  completed: 'Completed',
  createdDate: 'Date creation',
  completionDate: 'Date completion',
  priority: 'Priority',
  duration: 'Duration',
  timeTracked: 'Time tracking',

  resetFilters: 'Reset',
  reset: 'reset',
  notFoundMembers: 'No members found',
  notFoundStages: 'No stages found',
  notFoundDataSources: 'No databases found',
  notFoundViews: 'No views found',
  withoutExecutor: 'Without Executor',
  withoutResponsible: 'Without Responsible',
  withoutTag: 'Without Tag',
  withoutStage: 'Without Stage',
};
