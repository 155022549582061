import apiTeams from '@/api/teams';

const createInitialState = () => ({
  memberProjectsIds: [],
  mode: 'default',
});

const state = createInitialState();

const mutations = {
  setMemberProjectsIds(state, projectsIds) {
    state.memberProjectsIds = projectsIds;
  },
  toggleMode(state) {
    state.mode = state.mode === 'default' ? 'selection' : 'default';
  },
  setMode(state, mode) {
    state.mode = mode;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  async GET_TEAM_MEMBER_PROFILE(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    memberId,
  ) {
    try {
      const { data: teamMember } = await apiTeams.getTeamMemberProfile({ teamId, memberId });
      return teamMember;
    } catch (e) {
      console.error('Ошибка при получении профиля члена команды', e);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
