export default {
  share: 'Share',
  copy: 'Copy link',
  description: 'Users with link can comment and/or edit',
  message: 'These settings will be distributed to everyone who has the link',
  rightsPlaceholder: 'Setting up rights for a user not registered in Shtab',
  guest: 'Guest permissions',
  hideComments: 'Hide comments',
  accessEdit: 'Allow editing',
  accessLoadFiles: 'Allow file uploading and viewing',
  accessReact: 'Allow reactions',
  accessCreateTasks: 'Allow task creation',
  accessComment: 'Allow commenting',
  soon: 'Soon',
  guestPassword: 'Guest password',
  passwordMessage: 'Create an 8-digit password or you can Generate password',
  enterPassword: 'Enter password',
  passwordPlaceholder: 'Password that an unregistered user must enter when clicking on the link',
  embed: 'Embed',
  confirm: {
    title: 'Generate new link?',
    btnCancel: 'Cancel',
    btnConfirm: 'Yes',
  },
};
