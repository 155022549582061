import { z } from 'zod';

import { taskTypeMinimum } from '@/models/CardTypes';
import { datasource } from '@/models/Datasource';

import ViewHelper from '@/helpers/views/ViewHelper';

export enum ViewTypes {
  Board = 'board',
  Gantt = 'gantt',
  List = 'list',
  Matrix = 'matrix',
  Calendar = 'calendar',
  Files = 'files',
  Timeline = 'timeline',
  Folder = 'folder',
}

export enum ViewSubtaskCodes {
  Hidden = 'hidden',
  Revealed = 'revealed',
  Separately = 'separately',
}

export const cardSettings = z.object({
  status: z.boolean(),
  executors: z.boolean(),
  slug: z.boolean(),
  date: z.boolean(),
  repeat: z.boolean(),
  cardType: z.boolean(),
  interactiveButtons: z.boolean(),
  informationalButtons: z.boolean(),
  responsible: z.boolean(),
  tags: z.boolean(),
  viewers: z.boolean(),
  cover: z.boolean(),
  cost: z.boolean(),
});

export const viewBase = z
  .object({
    id: z.number(),
    type: z.nativeEnum(ViewTypes),
    name: z.string(),
    emoji: z.string(),
    icon: z.string(),
    avatar: z.string().nullable(),
    parent_id: z.number().nullable(),
  })
  .transform(({ parent_id, ...value }) => ({
    ...value,
    parent: parent_id,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const viewListItem = viewBase
  .innerType()
  .extend({
    is_favorite: z.boolean(),
    need_expand: z.boolean(),
  })
  .transform(({ parent_id, is_favorite, need_expand, ...value }) => ({
    ...value,
    parent: parent_id,
    needExpand: need_expand,
    isFavorite: is_favorite,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const viewWithDatasource = viewListItem
  .innerType()
  .extend({
    datasource_detail: datasource.nullish(),
  })
  .transform(({ parent_id, is_favorite, need_expand, datasource_detail, ...value }) => ({
    ...value,
    parent: parent_id,
    needExpand: need_expand,
    isFavorite: is_favorite,
    datasource: datasource_detail,
    icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
  }));

export const view = z
  .object({
    id: z.number(),
    type: z.nativeEnum(ViewTypes),
    name: z.string(),
    access: z.string(),
    datasource_detail: datasource.nullish(),
    emoji: z.string(),
    icon: z.string(),
    avatar: z.string().nullable(),
    locked: z.boolean(),
    created_by_id: z.number(),
    cards_settings: cardSettings.optional(),
    default_task_type: z
      .lazy(() => taskTypeMinimum)
      .nullable()
      .optional(),
    public_token: z.string().nullable(),
    can_create_comments_from_sharing: z.boolean(),
    parents: viewBase.optional().array(),
    subtask_display: z.nativeEnum(ViewSubtaskCodes),
  })
  .transform(
    ({
      datasource_detail,
      locked,
      default_task_type,
      cards_settings,
      public_token,
      can_create_comments_from_sharing,
      created_by_id,
      subtask_display,
      ...value
    }) => ({
      ...value,
      icon: ViewHelper.getIcon({ icon: value.icon, type: value.type, emoji: value.emoji }),
      datasource: datasource_detail,
      defaultCardType: default_task_type,
      isLockedStatuses: locked,
      cardsSettings: cards_settings,
      publicToken: public_token,
      canCreateCommentsFromSharing: can_create_comments_from_sharing,
      createdById: created_by_id,
      subtaskDisplay: subtask_display,
    }),
  );

export type CardSettings = z.infer<typeof cardSettings>;
export type View = z.infer<typeof view>;
export type ViewBase = z.infer<typeof viewBase>;
export type ViewListItem = z.infer<typeof viewListItem>;
export type ViewWithDatasource = z.infer<typeof viewWithDatasource>;

export const transformRequestParams = (params: Record<string, any> | string[]): string[] => {
  const transformationMap: Record<string, string> = {
    default_task_type: 'defaultCardType',
    locked: 'isLockedStatuses',
    cards_settings: 'cardsSettings',
    can_create_comments_from_sharing: 'canCreateCommentsFromSharing',
    subtask_display: 'subtaskDisplay',
  };

  return Array.isArray(params)
    ? params.map((key) => transformationMap[key] || key)
    : Object.keys(params).map((key) => transformationMap[key] || key);
};
