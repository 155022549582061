import session from '@/api/session';

export interface BlockedTaskData {
  taskId: number;
  requestBody: {
    deleted_task?: number;
    blocking_task?: number;
    blocked_task?: number;
    connection_type?: string;
  };
}

export interface BlockedTask {
  id: number;
  name: string;
  slug: string;
  linkId: number;
}
export interface BlockedByAnother {
  id: number;
  blocking_task: BlockedTask;
}
export interface BlockedByMe {
  id: number;
  blocked_task: BlockedTask;
}

export default {
  createBlocked({ taskId, requestBody }: BlockedTaskData) {
    return session.post(`tasks/task-block/create-lock/${taskId}/`, requestBody);
  },
  deleteBlocked({ taskId, linkId }: { taskId: number; linkId: number }) {
    return session.delete(`tasks/task-block/delete-lock/${taskId}/${linkId}`);
  },
};
