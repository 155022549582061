export default {
  screenshot: {
    downtime: {
      two: '2 mins',
      three: '3 mins',
      five: '5 mins',
      ten: '10 mins',
      fifteen: '15 mins',
      twenty: '20 mins',
      thirty: '30 mins',
      sixty: '60 mins',
      missing: 'Missing',
    },
    interval: {
      two: '2 mins',
      three: '3 mins',
      five: '5 mins',
      ten: '10 mins',
      fifteen: '15 mins',
      twenty: '20 mins',
      thirty: '30 mins',
    },
  },
};
