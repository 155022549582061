import session from '@/api/session';

export default {
  register(requestBody) {
    return session.post('users/user/registration/', requestBody);
  },
  registerInvitedUser(requestBody) {
    return session.put('users/user/update-password/invited/', requestBody);
  },
  activateAccount(requestBody) {
    return session.post('users/user/account-activation/', requestBody);
  },
  resendActivationLetter(requestBody) {
    return session.post('users/user/resend/', requestBody);
  },
  verify() {
    return session.post('users/user/verify/');
  },
};
