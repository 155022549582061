import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { taskTemplateFile } from '@/models/tasks/TaskTemplate';

import ZodHelper from '@/helpers/ZodHelper';

type DefaultParams = { datasourceId: number; templateId: number };
type DeleteTemplateFileParams = DefaultParams & { fileId: string };
type CreateTemplateFileParams = DefaultParams & {
  file: File;
  config?: Pick<AxiosRequestConfig, 'onUploadProgress' | 'cancelToken'>;
};

export const apiTaskTemplateFiles = {
  deleteTemplateFile({ datasourceId, templateId, fileId }: DeleteTemplateFileParams) {
    return session.delete(`tasks/task-templates/${datasourceId}/file/${templateId}/${fileId}/`);
  },
  createTemplateFile({ datasourceId, templateId, file, config }: CreateTemplateFileParams) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('task_template', String(templateId));

    return ZodHelper.request(
      () => session.post(`tasks/task-templates/${datasourceId}/file/${templateId}/`, formData, config),
      taskTemplateFile,
    );
  },
};
