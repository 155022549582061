import session from '@/api/session';
import { datasourceCustomField } from '@/models/datasource/DatasourceCustomField';

import ZodHelper from '@/helpers/ZodHelper';

type GetCustomFields = {
  teamId: number;
  datasourceId: number;
  getParams: {
    page: number;
    search?: string;
    exclude_view?: number;
  };
};
type AddCustomField = Pick<GetCustomFields, 'teamId' | 'datasourceId'> & {
  requestBody: { datasource: number; custom_field: string };
};
type DeleteCustomField = Pick<GetCustomFields, 'teamId' | 'datasourceId'> & { fieldId: number };

export default {
  getCustomFields({ teamId, datasourceId, getParams }: GetCustomFields) {
    return ZodHelper.request(
      () => session.get(`/datasource/${teamId}/fields/${datasourceId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(datasourceCustomField.array()),
    );
  },

  addCustomField({ teamId, datasourceId, requestBody }: AddCustomField) {
    return session.post(`/datasource/${teamId}/fields/${datasourceId}/`, requestBody);
  },

  deleteCustomField({ teamId, datasourceId, fieldId }: DeleteCustomField) {
    return session.delete(`/datasource/${teamId}/fields/${datasourceId}/${fieldId}/`);
  },
};
