export default {
  title: 'Add card',
  warning: 'You can only use a 3D Secure protected card.',

  number: 'Card number',
  owner: 'Owner:',
  expiration: 'Expiration date',
  cvc: 'CVC',

  save: 'Save',

  error: 'An error has occurred',
  notStore: 'We do not store all data for your banking card',
  paymentInfo: 'All payment information is transferred via secure channels.',
};
