const getMimeType = (fileType) => {
  switch (fileType) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    default:
      throw new Error('Не удалось найти соответствующий MIME-тип');
  }
};

export default (blobParts, fileType) => {
  const blob = new Blob([blobParts], {
    type: getMimeType(fileType),
  });
  const reader = new FileReader();

  reader.onload = () => {
    window.open(reader.result);
  };

  reader.readAsDataURL(blob);
};
