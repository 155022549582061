import consolidated from '@/locales/en/reports/consolidated';

export default {
  noProjects: 'No projects found Please modify your search',

  createProject: 'Create project',
  goToTasks: 'Go to tasks',
  seeStatistics: 'To view the stats for your team, create your project and begin working using the',
  tracker: 'Shtab tracker',
  getStarted: 'Start your work and turn on',
  timeTracker: ' time tracker ',
  results: 'to track statistics on your results',

  downloadReport: 'Download report',

  consolidated,
};
