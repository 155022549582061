import session from '@/api/session';

import type { SortingItem } from '@/constants/tasks/sortingItems';

import type { Nullable } from '@/types/utility';

export type GroupTasksRequestBody = {
  field: SortingItem['code'];
  field_from: Nullable<string | number>;
  field_to?: Nullable<string | number>;
  type: string;
  view?: string;
  order_field?: string;
};

interface ProjectViewSettingsBody {
  view_type: SortingItem['code'];
  column: string;
  table: number | string;
  position: number;
  width: number;
}

export default {
  editProjectViewSettings({ projectId, requestBody }: { projectId: string; requestBody: ProjectViewSettingsBody }) {
    return session.patch(`projects/tasks-positions/${projectId}/view-settings/update/`, requestBody);
  },

  sortGroupTasks({
    teamId,
    requestBody,
    getParams,
  }: {
    teamId: string;
    requestBody: GroupTasksRequestBody;
    getParams: Record<string, any>;
  }) {
    return session.post(`tasks/manage-tasks/${teamId}/sorting/`, requestBody, { params: getParams });
  },

  moveGroupTasks({
    teamId,
    requestBody,
    getParams,
  }: {
    teamId: string;
    requestBody: GroupTasksRequestBody;
    getParams: Record<string, any>;
  }) {
    return session.post(`tasks/manage-tasks/${teamId}/change-column/`, requestBody, { params: getParams });
  },

  deleteGroupTasks({
    teamId,
    requestBody,
    getParams,
  }: {
    teamId: string;
    requestBody: GroupTasksRequestBody;
    getParams: Record<string, any>;
  }) {
    return session.post(`tasks/manage-tasks/${teamId}/archive-tasks/`, requestBody, { params: getParams });
  },
};
