export default {
  create: {
    enterName: 'Enter name',
    period: 'Period within the project',
    hours: 'Hours',

    save: 'Save',
    cancel: 'Cancel',
  },
};
