import session from '@/api/session';

export default {
  getFeedFiles(feedId) {
    return session.get(`feeds/files/${feedId}/list/`);
  },
  addFeedFile({ teamId, fd, config }) {
    return session.post(`feeds/files/${teamId}/create/`, fd, config);
  },
  deleteFeedFile({ feedId, fileId }) {
    return session.delete(`feeds/files/${feedId}/delete/${fileId}/`);
  },
};
