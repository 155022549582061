import { z } from 'zod';

import { user } from '@/models/User';

export const taskExecutor = z.object({
  id: z.number(),
  finished: z.boolean(),
  executor: user,
});

export type TaskExecutor = z.infer<typeof taskExecutor>;
