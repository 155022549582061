export default {
  finances: 'Finances',
  directions: 'Directions',
  financesDirections: 'Finances - Directions',
  counterparts: 'Counterparts',
  financesCounterparts: 'Finances - Counterparts',
  financesArticles: 'Finances - Articles',
  articles: 'Articles',
  financesAccounts: 'Finances - Accounts',
  accounts: 'Accounts',
  financesTransactions: 'Finances - Transactions',
  transactions: 'Transactions',
};
