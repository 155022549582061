import { z } from 'zod';

import { AccountTypeCodes, Currency } from '@/models/finances/FinancesAccounts';

export enum IntegrationCodes {
  MAIL = 'mail',
  MODULBANK = 'modulebank',
  BITBUCKET = 'bitbucket',
  TINKOFF = 'tinkoff',
  GITHUB = 'github',
  TOCHKA_BANK = 'tochkaBank',
  GITLAB = 'gitlab',
  SENTRY = 'sentry',
  LAMBDATEST = 'lambdatest',
  QASE = 'qase',
  SLACK = 'slack',
  TEAMS = 'teams',
}

export const teamIntegration = z.object({
  first_sync: z.boolean(),
  got_token: z.boolean(),
  id: z.number(),
  integration_id: z.string().nullable(),
  integration_name: z.string(),
  integration_type: z.nativeEnum(IntegrationCodes),
  is_active: z.boolean(),
});

export const moduleBankDetails = z.object({
  int_type: z.string().optional(),
  accounts: z
    .array(
      z.object({
        bank_account_id: z.string(),
        id: z.number(),
        name: z.string(),
        balance: z.number(),
        currency: z.nativeEnum(Currency),
        account_type: z.nativeEnum(AccountTypeCodes),
        company_id: z.string(),
      }),
    )
    .nullish(),
});

export const integrationDetail = teamIntegration.extend({
  data: moduleBankDetails,
});

export type IntegrationDetail = z.infer<typeof integrationDetail>;
export type TeamIntegration = z.infer<typeof teamIntegration>;
