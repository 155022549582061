import apiUpdates from '@/api/updates';

import urlBase64ToUint8Array from '@/utils/urlBase64ToUint8Array';

export default class PushNotificationsHelper {
  public static async requestPermission() {
    const result = await Notification.requestPermission();
    return result === 'granted';
  }

  private static async sendSubData(subscription: PushSubscription) {
    const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/gi)?.[0].toLowerCase();
    const data = {
      status_type: 'subscribe',
      subscription: subscription.toJSON(),
      browser,
    };

    try {
      await apiUpdates.sendWebpushInfo(data);
    } catch (e) {
      console.error(e);
    }
  }

  public static async subscribe(reg: ServiceWorkerRegistration) {
    const subscription = await reg.pushManager.getSubscription();
    if (subscription) {
      PushNotificationsHelper.sendSubData(subscription);
      return;
    }

    const key = import.meta.env.VITE_PUSH_NOTIFICATIONS_KEY;
    const options = {
      userVisibleOnly: true,
      // if key exists, create applicationServerKey property
      ...(key && { applicationServerKey: urlBase64ToUint8Array(key) }),
    };

    const sub = await reg.pushManager.subscribe(options);
    PushNotificationsHelper.sendSubData(sub);
  }

  public static initialize() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (Notification.permission === 'granted') {
          PushNotificationsHelper.subscribe(registration);
        }
      });
    }
  }
}
