import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageActivity } from '@/constants/permissions/listPermissions';
import { portalNames } from '@/constants/portals';

const requirePermission = createPermissionHook(canManageActivity, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'activity',
    name: pageNames.activity.ACTIVITY,
    meta: {
      seoTitle: new Translation('routes.activity.activity'),
      breadcrumbs: [
        {
          title: new Translation('routes.activity.activity'),
        },
      ],
    },
    component: () => import('@/pages/activity/Activity.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'activity/:executorId',
    name: pageNames.activity.ACTIVITY_DETAILS,
    meta: {
      seoTitle: new Translation('routes.activity.seoActivityDetails'),
      breadcrumbs: [
        {
          title: new Translation('routes.activity.activity'),
          url: { name: pageNames.activity.ACTIVITY },
        },
        {
          portal: portalNames.breadcrumbs.activityUser,
        },
      ],
    },
    component: () => import('@/pages/activity/ActivityDetails.vue'),
  },
];
