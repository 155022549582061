import apiUser from '@/api/user';

import TeamHelper from '@/helpers/TeamHelper';

import { GUEST } from '@/constants/permissions/listRoles';
import { generateAnonimous } from '@/constants/user/anonimousMock';

import RequestError from '@/errors/RequestError';

export default {
  // Using for shared view
  INITIALIZE_USER_GUEST({ commit, dispatch }) {
    try {
      const profile = {
        id: 'guest',
        is_deleted: false,
        ...generateAnonimous(),
      };
      commit('setUserProfile', profile);
      dispatch('permissions/INITIALIZE_USER_PERMISSIONS', { role: GUEST, can_add_manual_time: false });
    } catch (e) {
      console.error('Ошибка при получении информации о пользователе', e);
      throw e;
    }
  },
  async INITIALIZE_USER({ commit }) {
    try {
      const { data: profile } = await apiUser.getUserProfile();
      TeamHelper.setLastTeamId(profile.last_team);
      commit('setUserProfile', profile);
    } catch (e) {
      console.error('Ошибка при получении информации о пользователе', e);
      throw e;
    }
  },
  async EDIT_USER_PROFILE({ commit }, data) {
    try {
      const { data: profileUpdated } = await apiUser.editUserProfile(data);
      commit('setUserProfile', profileUpdated);
    } catch (e) {
      console.error('Ошибка при редактировании информации о пользователе', e);
      throw new RequestError(e);
    }
  },

  async EDIT_USER_AVATAR({ commit }, data) {
    try {
      const {
        data: { avatar },
      } = await apiUser.editUserAvatar(data);
      commit('setUserAvatar', avatar);
    } catch (e) {
      console.error('Ошибка при обновлении аватара пользователя', e);
      throw new RequestError(e);
    }
  },
  async DELETE_USER_ACCOUNT() {
    try {
      await apiUser.deleteUserProfile();
    } catch (e) {
      console.error('Ошибка при удалении аккаунта', e);
      throw e;
    }
  },
};
