export default {
  rows: {
    name: 'Card Name',
    status: 'Status',
    executors: 'Executors',
    responsibles: 'Supervisors',
    priority: 'Priority',
    deadline: 'Deadline',
    tags: 'Tags',
    cost: 'Cost',
  },
  blocks: {
    resources: 'Resources',
    list: 'List',
  },
  periods: {
    hour: 'Hourly',
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
  },
  today: 'Today',
  gantt: 'Gantt chart',
  billing: 'Unlock the power of a tool to manage a project with dependencies between tasks',
  showTask: 'Show on Timeline',
  noDate: 'Set Deadline',
  addTask: 'Add cards',
  noDateAndCantManage: 'No date',
  cancel: 'Cancel',
  create: 'Create',
  createTask: 'Create Card',
  links: {
    type: 'Link Type - ',
    F: 'End',
    S: 'Start',
    delay: 'Delay',
    delete: 'Delete Link',
  },
  resources: {
    hours: 'Hours',
    tasks: 'Tasks',
  },
  cantMoveMilestone: 'Cannot Nest Milestone in Task',
};
