export default {
  consolidated: {
    types: {
      executors: 'Executor',
      cards: 'Card',
      views: 'View',
      datasource: 'Database',
    },
    title: 'Title',

    details: {
      tabs: {
        byTime: 'By time',
        byCards: 'By cards',
      },

      listingTypes: {
        byHours: 'By hours',
        byDays: 'By days',
        byWeeks: 'By weeks',
        byMonths: 'By months',
        byYears: 'By years',
      },

      groupItems: {
        byUsers: 'By users',
        byCards: 'By cards',
      },
    },
  },
};
