import variables from '@/styles/_variables.module.scss';

export default {
  inviteMembers: 'Invite members to the team',
  invite: 'Invite',
  invitationSent: 'Invitation is sent',
  resendInvitation: 'A repeated invitation has been sent to the e-mail address',

  cancelInvite: {
    title: `Are you sure you want to cancel the invitation for <span style="color: ${variables.colorPrimary}">{user}</span>?`,
    btnCancel: 'Back',
    btnConfirm: 'Cancel invitation',
  },
};
