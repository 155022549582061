import Centrifuge from 'centrifuge';

let socketConnection = null;

const root = { root: true };

const createInitialState = () => ({
  isConnected: false,
});

const state = createInitialState();

const getters = {};

const mutations = {
  setIsConnected(state, value) {
    state.isConnected = value;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    socketConnection?.disconnect();
    commit('reset');
  },

  CONNECT_SOCKET({ commit, rootState }) {
    const { tokenCentrifuge } = rootState.auth;

    if (!import.meta.env.VITE_LOCATION_SOCKET) {
      throw new Error('No centrifuge server location ');
    }

    if (tokenCentrifuge) {
      return new Promise((resolve, reject) => {
        const centrifuge = new Centrifuge(import.meta.env.VITE_LOCATION_SOCKET);
        centrifuge.setToken(tokenCentrifuge);

        centrifuge.on('connect', () => {
          socketConnection = centrifuge;
          commit('setIsConnected', true);
          resolve();
        });
        centrifuge.on('disconnect', () => {
          commit('setIsConnected', false);
          reject(new Error('Disconnect'));
        });
        centrifuge.on('error', () => {
          reject(new Error('Connection error'));
        });

        centrifuge.connect();
      });
    }
    throw new Error('No centrifuge token');
  },

  SUBSCRIBE_TO_CHANNEL(_skip, { channel, id, listener }) {
    const subscription = socketConnection?.subscribe(`${channel}#${id}`, listener);
    return subscription;
  },

  async UNSUBSCRIBE_FROM_CHANNEL(_skip, subscription) {
    await subscription.unsubscribe();
  },

  LISTENER_FEED_SOCKET({ commit, rootState }, { data }) {
    if (!data.action) return;

    const { feeds = {}, user = {} } = rootState;
    const {
      userProfile: { id: userId },
    } = user;
    const { feed = {}, feed_comment: feedComment = {}, initiator, feed_comment_parent: feedCommentParent = {} } = data;
    const { feed_recipients: feedRecipients = [], id: feedId, project } = feed;

    if (initiator === userId) return;

    const feedRecipientsIds = feedRecipients.map((fR) => fR.id);

    if (feedRecipientsIds.length && !feedRecipientsIds.includes(userId)) return;

    const { currentProject, feeds: feedsFromStore = [], comments: commentsFromStore = {} } = feeds;

    if (currentProject !== project) return;

    const feedFromStore = feedsFromStore.find((it) => it.id === feedId);

    const updateFeedComment = (comment) => {
      if (!feedFromStore || !feedFromStore.comments) {
        commit('feeds/updateFeed', { ...feedFromStore, is_have_comments: true }, root);
        return;
      }
      const feedCommentsList = feedFromStore.comments?.results || [];
      commit(
        'feeds/updateFeed',
        {
          ...feedFromStore,
          comments: {
            ...feedFromStore.comments,
            results: [...[comment], ...feedCommentsList],
          },
        },
        root,
      );
    };

    const updateFeedReplyComment = (feedCommentParent) => {
      if (!feedFromStore || feedCommentParent.parent) return;

      const parentCommentFormStore = commentsFromStore.childrenComments[feedCommentParent.id];
      const feedCommentsList = feedFromStore.comments?.results || [];

      if (!parentCommentFormStore) {
        commit(
          'feeds/updateFeed',
          {
            ...feedFromStore,
            comments: {
              ...feedFromStore.comments,
              results: feedCommentsList.map((feedComment) => {
                if (feedComment.id === feedCommentParent.id) {
                  return {
                    ...feedComment,
                    ...{
                      is_have_childrens: true,
                      notReadReplyCommentsCount: feedComment.notReadReplyCommentsCount + 1 || 1,
                    },
                  };
                }
                return feedComment;
              }),
            },
          },
          root,
        );
      }
    };

    switch (data.action) {
      case 'feed_create':
        commit('feeds/unshiftFeed', { ...feed, new: true }, root);
        break;
      case 'feed_comment_create':
        updateFeedComment(feedComment);
        break;
      case 'feed_comment_reply':
        updateFeedReplyComment(feedCommentParent);
        break;
      default:
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
