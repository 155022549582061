import activity from '@/locales/en/routes/activity';
import auth from '@/locales/en/routes/auth';
import billing from '@/locales/en/routes/billing';
import feeds from '@/locales/en/routes/feeds';
import finances from '@/locales/en/routes/finances';
import payouts from '@/locales/en/routes/payouts';
import projects from '@/locales/en/routes/projects';
import reports from '@/locales/en/routes/reports';
import tasks from '@/locales/en/routes/tasks';
import team from '@/locales/en/routes/team';
import teamSettings from '@/locales/en/routes/teamSettings';
import updates from '@/locales/en/routes/updates';
import wizard from '@/locales/en/routes/wizard';

export default {
  notFound: 'Error 404',
  noAccess: 'No access',
  mobileOnly: 'Redirect to mobile app',

  activity,
  billing,
  auth,
  payouts,
  reports,
  tasks,
  projects,
  team,
  updates,
  feeds,
  teamSettings,
  wizard,
  finances,
};
