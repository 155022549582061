export default {
  select: 'Select',

  dueDate: 'Due date',
  dueDateFrom: 'from',
  dueDateUntil: 'until',

  status: 'Status',
  priority: 'Priority',
  duration: 'Duration',
  addExecutor: 'Add assignee',
  noExecutors: 'No executors',
  executor: 'Assignees',
  addResponsible: 'Add supervisor',
  noResponsibles: 'No supervisors',
  responsible: 'Supervisors',

  stage: 'Stage',
  createStage: 'Create stage',
  noStages: 'No stages',

  links: 'Links',
  findTask: 'Find a card',
  blockedByMeLinksOption: 'Blocking',
  blockedFromOtherLinksOption: 'Blocked',
  blockedByOtherLinksOption: 'Blocked by',

  tags: 'Tags',
  cost: 'Cost',

  multiExecutors: {
    acceptTask: 'Accept card',
    toWork: 'Return to work',

    noResults: 'No results',
  },

  notSelected: 'Not selected',
  add: 'Add',

  blockedConfirm: {
    title: 'This card is pending completion of a related card',
    description: 'Are you sure you want to close it?',
    btnCancel: 'Cancel',
    btnConfirm: 'Yes, close the card',
    listTitle: 'Pending',
  },

  footer: {
    link: 'Link',
    linkToDocumentation: '{link} to documentation on how to use the tracker',
    time: 'Time {time}',
    showAll: 'Show all',
    workingTime: 'Working time',
    addTime: 'Add time',
  },
};
