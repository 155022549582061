import session from '@/api/session';

export default {
  restorePassword(requestBody) {
    return session.post('users/user/account-restore/', requestBody);
  },
  restoreCheckToken({ uid, restore_token: activation_token }) {
    return session.post('users/user/account-restore-check/', {
      uid,
      activation_token,
    });
  },
  restoreChangePassword(requestBody) {
    return session.post('users/user/account-restore-done/', requestBody);
  },
  restoreAccount(requestBody) {
    return session.put('/users/profile/activate/', requestBody);
  },
};
