import session from '@/api/session';

export default {
  getFeedComments({ feedId, getParams }) {
    return session.get(`feeds/comment/${feedId}/list-first/`, { params: getParams });
  },
  getFeedChildrenComment({ feedId, parentId, getParams }) {
    return session.get(`feeds/comment/${feedId}/get-childrens/${parentId}/`, { params: getParams });
  },
  addFeedComment({ feedId, requestBody }) {
    return session.post(`feeds/comment/${feedId}/create/`, requestBody);
  },
  editFeedComment({ feedId, commentId, requestBody }) {
    return session.patch(`feeds/comment/${feedId}/update/${commentId}/`, requestBody);
  },
  deleteFeedComment({ feedId, commentId }) {
    return session.delete(`feeds/comment/${feedId}/delete/${commentId}/`);
  },
};
