export default {
  account: {
    title: 'Restore account',
  },
  password: {
    title: 'Restore',
    description: 'Enter the email to which you will receive a letter with a link to restore your account',
    send: 'Send another e-mail?',
    enterEmail: 'Enter your e-mail address',
  },
  accountSuccess: {
    done: 'Done',
    sendEmail: 'Your password restore letter <br/> has been sent to ',
    login: 'Sign in',
    notReceive: "Didn't receive the letter?",
    sendAgain: 'Send again',
    again: 'Again!',
  },
  changePassword: {
    newPassword: 'New password',
    comeUp: 'Make a new password',
    repeat: 'Enter your password again',
    create: 'Create',
    success: 'The password has been changed successfully!',
  },

  backToLogin: '← Back',
  restore: 'Restore',
};
