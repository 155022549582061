import router from '@/router';

import pageNames from '@/router/helper/pageNames';

const goToRestoreAccount = (lastTeam) => {
  const goToRestoreAccount = {
    name: pageNames.auth.RESTORE_ACCOUNT,
    params: { restoreAccount: true, lastTeam },
  };
  router.push(goToRestoreAccount);
};

export default goToRestoreAccount;
