import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canViewReports } from '@/constants/permissions/listPermissions';

const requirePermission = createPermissionHook(canViewReports, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'consolidated',
    name: pageNames.reports.REPORT_CONSOLIDATED,
    meta: {
      title: new Translation('routes.reports.consolidatedReport'),
      seoTitle: new Translation('routes.reports.seoConsolidated'),
      breadcrumbs: [
        {
          title: new Translation('routes.reports.reports'),
        },
        {
          title: new Translation('routes.reports.consolidated'),
        },
      ],
    },
    props: (route) => ({ teamId: Number(route.params.teamId), type: route.query.type }),
    component: () => import('@/pages/reports/consolidated/ReportConsolidated.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'consolidated/details',
    name: pageNames.reports.REPORT_CONSOLIDATED_DETAILS,
    meta: {
      title: new Translation('routes.reports.consolidatedReport'),
      seoTitle: new Translation('routes.reports.seoConsolidatedDetails'),
      breadcrumbs: [
        {
          title: new Translation('routes.reports.reports'),
        },
        {
          title: new Translation('routes.reports.consolidated'),
          url: {
            name: pageNames.reports.REPORT_CONSOLIDATED,
          },
        },
      ],
    },
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    component: () => import('@/pages/reports/consolidated/ReportConsolidatedDetails.vue'),
    beforeEnter: requirePermission,
  },
];
