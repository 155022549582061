import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';

import { canManageTeam } from '@/constants/permissions/listPermissions';

import teamSettings from '@/router/routes/teamSettings';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'team/settings',
    redirect: { name: pageNames.teams.TEAM_SETTINGS_GENERAL },
    name: pageNames.teams.TEAM_SETTINGS,
    component: () => import('@/pages/teams/TeamSettings.vue'),
    children: [...teamSettings],
    beforeEnter: requirePermission,
  },
  {
    path: 'team/member/:memberId',
    redirect: 'team/settings/tariff',
  },
];
