export default {
  periods: {
    default: 'All',
    today: 'Today',
    currentWeek: 'This week',
    lastWeek: 'Last week',
    currentMonth: 'This month',
    lastMonth: 'Last month',
    period: 'User’s interval',
  },

  allDatasource: 'All databases',

  memberFilters: {
    active: 'Active',
    blocked: 'Blocked',
  },

  detailTabs: {
    screenshots: 'Screenshots',
    performance: 'Performance',
    timeline: 'Timeline',
    calendar: 'Calendar',
    time: 'Time',
    graph: 'Graph',
  },
};
