import feedUpdate from '@/locales/en/updates/list/feedUpdate';
import taskUpdate from '@/locales/en/updates/list/taskUpdate';

export default {
  inviteTeam: {
    title: 'You have been invited to join a team!',
    invited: 'has invited you to join a team',
    accept: 'Accept',
    decline: 'Decline',
    confirmed: 'Accepted',
    declined: 'Declined',
    notValid: 'The invitation is invalid',
  },
  memberTeam: {
    title: 'The team has got a new member!',
    added: ' has added a new member to the {team} team:',
  },
  taskNew: {
    title: 'New card',
    assigned: ' has assigned a new card to you ',
  },
  payoutNew: {
    title: 'New payout',
    sent: 'A new {payout} of {amount} has been sent to you',
    payout: 'Payout ',
    confirmed: 'Confirmed',
    confirm: 'Confirm',
  },
  deleteAccount: {
    title: 'A member has deleted its account',
    deleted: 'has deleted the account',
  },
  mention: {
    title: 'You have been mentioned',
    mentionedDesc: 'has mentioned you in a card description',
    mentionedComments: 'has mentioned you in a card comment',
  },
  responsible: {
    title: 'Card supervision',
    assigned: 'has assigned you to supervise a card',
  },
  blockTeam: {
    blocked: 'You have been blocked from your team',
    title: 'You have been blocked!',
  },
  taskComment: {
    inCard: 'in the card: ',
  },

  taskUpdate,
  feedUpdate,

  btnAnswer: 'Answer',
  today: 'Today',
  yesterday: 'Yesterday',
  newComment: 'New comment',
};
