export default {
  title: 'Your integrations',
  reference: 'List of connected services',
  connect: 'Connect',
  catalog: 'Catalog of integrations',
  list: {
    mail: 'Mail',
    modulbank: 'Bank Module',
    bitbucket: 'Bitbucket',
    gitlab: 'Gitlab',
    slack: 'Slack',
    github: 'Github',
    lambdatest: 'Lambdatest',
    tinkoff: 'Tinkoff',
    sentry: 'Sentry',
    qase: 'Qase',
    tochkaBank: 'Tochka Bank',
    teams: 'Teams',
  },
  description: {
    mail: 'Automatic card creation',
    modulbank: 'Synchronization of transactions with the bank',
    bitbucket: 'Extend card functions',
    gitlab: 'Extend card functions',
    slack: 'Bot Assistant',
    github: 'Card Extension',
    lambdatest: 'Automated Testing',
    tinkoff: 'Synchronize transactions with bank',
    sentry: 'Automated card creation',
    qase: 'Automated testing',
    tochkaBank: 'Synchronization of operations with the bank',
    teams: 'Bot Assistant',
  },
  label: {
    cards: 'Cards',
    finances: 'Finance',
    auto: 'Automation',
    soon: 'Soon',
  },
  empty: 'Nothing was found for your request',
  nodata: 'You have no integrations yet',

  confirm: {
    title: 'Are you sure you want to remove the integration?',
    btnCancel: 'Cancel',
    btnConfirm: 'Delete',
  },
};
