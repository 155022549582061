export default {
  news: 'News',
  common: 'Common',
  placeholderNews: 'What`s the news?',
  placeholderMsg: 'Answer on message',
  consumer: 'Consumer',
  searchMemberPlaceholder: 'Start typing name',
  menu: {
    edit: 'Edit',
    delete: 'Delete record',
    unsubscribe: 'Unsubscribe from notifications',
    confirm: {
      title: 'Are you sure you want to delete this record?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
    errors: {
      delete: 'Error while deleting record',
    },
  },
  confirmModalDelete: {
    title:
      'If you delete a channel, its recording history will be deleted. Do you really want to delete the "{channel}" channel ?',
    btnCancel: 'Cancellation',
    btnConfirm: 'Delete',
  },
  notView: 'No one has seen this record',
};
