export default {
  users: {
    title: 'Users',
    description: 'Add people to the task',
  },
  text: {
    title: 'Text',
    description: 'Complete the task with data',
  },
  number: {
    title: 'Number',
    description: 'Insert any number you need',
  },
  date: {
    title: 'Date',
    description: 'Plan your time effectively',
  },
  files: {
    title: 'Files',
    description: 'Attach the required files',
  },
  duration: {
    title: 'Duration',
    description: 'Fixate the duration of anything',
  },
  link: {
    title: 'Link',
    description: 'Insert useful links',
  },
  email: {
    title: 'E-mail',
    description: 'Enter contact address',
  },
  phone: {
    title: 'Phone',
    description: 'Specify important contacts',
  },
  price: {
    title: 'Price',
    description: 'Specify the price of the work (₽, $, €)',
  },
  tags: {
    title: 'Tags',
    description: 'Tags for filtering tasks',
  },

  dateTypes: {
    date: 'Date',
    time: 'Time',
    datetime: 'Datetime',
  },
};
