import router from '@/router';

const parseFalseToUndefined = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    // eslint-disable-next-line no-undefined
    acc[key] = value || undefined;
    return acc;
  }, {});

const appendGetParams = (params, { isSaveHistory = true } = {}) => {
  const location = { query: { ...router.currentRoute.value.query, ...parseFalseToUndefined(params) } };
  return isSaveHistory ? router.push(location) : router.replace(location);
};

export default appendGetParams;
