export default {
  dataSources: {
    title: 'Database',
    deleteConfirm: {
      title: 'Are you sure you want to delete the database?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
  },
  cardTypes: {
    delete: 'Delete',
    duplicate: 'Duplicate',
    edit: 'Edit',
    select: 'Make primary',
    title: 'Card Type',
    create: 'Create Card Type',
    noData: 'No results',
    connectCardType: 'Connect Card Type',
    placeholderName: 'Enter a title',
    disconnect: 'Disconnect',
    merge: 'Merge',
    detail: {
      return: 'Return,',
      title: 'Title of your card,',
      required: 'Required',
      date: 'DD month HH:MM',
      creatorLabel: 'Creator: First Name Last Name',
      tabs: {
        modules: 'Modules',
        fields: 'Fields',
        widgets: 'Widgets',
      },
      helperModule: 'Drag and drop a module from the corresponding list on the right',
      helperField: 'Drag and drop a field from the corresponding list on the right',
    },
    deleteConfirm: {
      title: 'Do you really want to delete card type: "{type}"?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
    disconnectConfirm: {
      title: 'Do you really want to disconnect card type: "{type}"?',
      btnCancel: 'Cancel',
      btnConfirm: 'Disconnect',
    },
  },
  datasourceDetails: {
    general: {
      datasource: 'Database',
      enterTitle: 'Enter title',
      key: 'Key',
      enterKey: 'Enter key',
      comment: 'Comment',
      enterText: 'Enter text',
      keyMessage: 'Used to generate a unique task identifier',
    },

    statuses: {
      title: 'Connect statuses',
      deleteConfirm: {
        title: 'Are you sure you want to delete the status «{status}»?',
        btnCancel: 'Cancel',
        btnConfirm: 'Delete',
      },
    },
    customFields: {
      title: 'Connect custom field',
      deleteConfirm: {
        title: 'Are you sure you want to delete the field «{field}»',
        btnCancel: 'Cancel',
        btnConfirm: 'Delete',
      },
    },

    stages: {
      title: 'Stage',
      titles: 'Stages',
      notSelected: 'Not selected',
      enterStageName: 'Enter stage name',
      enterComment: 'Enter comment',
      description: 'Description',
      edit: 'Edit',
      confirm: {
        title:
          'By deleting a stage, it will automatically be detached from the cards. Do you really want to delete the stage «{stage}» stage?',
        btnCancel: 'Cancel',
        btnConfirm: 'Delete',
      },
      helpInfo: {
        period: 'A stage without an added period has indefinite status',
        stage: 'A project phase that can be given a deadline',
      },
      create: 'Create a new stage',
      empty: 'No stages',
    },

    templates: {
      title: 'Templates',
      notSelected: 'Not selected',
      noRepeat: 'No repeat',
      enterName: 'Enter template name',

      deleteConfirm: 'Are you sure you want to delete the template?',
      massDeleteConfirm: 'Are you sure you want to delete the selected templates?',
      delete: 'Delete',
      cancel: 'Cancel',
      confirm: {
        title: 'Are you sure you want to delete the «{template}» template ?',
        btnCancel: 'Cancel',
        btnConfirm: 'Delete',
      },
      duplicate: 'Duplicate',
      link: 'Link',
      createTask: 'Create task',
      edit: 'Edit',
      repeat: 'Repeat',
      addFavorites: 'Add to Favorites',
      removeFavorites: 'Remove from Favorites',
      favorites: 'Favorites',
      timetable: 'Create a timetable',
      schedule: 'Schedule',
    },
  },

  statuses: {
    title: 'Create statuses',
    newStatus: 'New status',

    deleteConfirm: {
      title: 'Are you sure you want to delete the status?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },

    actions: {
      merge: 'Merge',
      edit: 'Edit',
      delete: 'Delete',
    },
  },

  tags: {
    title: 'Create tags',
    newTag: 'New tag',
    deleteConfirm: {
      title: 'Are you sure you want to remove the tag «{tag}»?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },

    actions: {
      merge: 'Merge',
      delete: 'Delete',
    },
  },

  customFields: {
    title: 'Create custom fields',
    deleteConfirm: {
      title: 'Are you sure you want to delete the «{field}» field?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
  },
};
