import { createStore } from 'vuex';

import auth from '@/store/modules/auth';
import billing from '@/store/modules/billing';
import errors from '@/store/modules/errors';
import feeds from '@/store/modules/feeds';
import files from '@/store/modules/files';
import layout from '@/store/modules/layout';
import loading from '@/store/modules/loading';
import modals from '@/store/modules/modals/modals';
import notifications from '@/store/modules/notifications.js';
import payouts from '@/store/modules/payouts';
import projects from '@/store/modules/projects/projects';
import sockets from '@/store/modules/sockets';
import tasks from '@/store/modules/tasks';
import teams from '@/store/modules/teams';
import title from '@/store/modules/title';
import user from '@/store/modules/user';
import view from '@/store/modules/view';

export default createStore({
  strict: import.meta.env.PROD,
  modules: {
    auth,
    user,
    teams,
    projects,
    tasks,
    notifications,
    modals,
    payouts,
    loading,
    layout,
    errors,
    sockets,
    billing,
    files,
    feeds,
    title,
    view,
  },

  actions: {
    // Ресет всего приложения, нужен при удаления всех данных, если пользователь выходит из системы
    RESET_DATA({ dispatch }) {
      const modules = [
        'user',
        'teams',
        'projects',
        'tasks',
        'notifications',
        'modals',
        'payouts',
        'loading',
        'sockets',
        'billing',
        'files',
        'feeds',
        'title',
        'view',
      ];
      modules.forEach((module) => dispatch(`${module}/RESET`));
    },

    // Ресет только загруженного контента, используется при переключении команд
    RESET_DATA_CONTENT({ dispatch }) {
      const modules = ['projects', 'tasks', 'modals', 'payouts', 'loading', 'billing', 'files', 'feeds'];
      modules.forEach((module) => dispatch(`${module}/RESET`));
    },
  },
});
