import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getTaskComments(taskId) {
    const defaultUrl = `/tasks/comments/${taskId}/list/`;
    const sharedUrl = `shared/tasks/comment/${taskId}/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl));
  },
  createTaskComment({ taskId, requestBody }) {
    const defaultUrl = `/tasks/comments/${taskId}/create/`;
    const sharedUrl = `shared/tasks/comment/${taskId}/`;

    return session.post(getRequestUrl(defaultUrl, sharedUrl), requestBody);
  },
  deleteTaskComment({ taskId, commentId, requestBody }) {
    return session.delete(`/tasks/comments/${taskId}/delete/${commentId}/`, { data: requestBody });
  },
  editTaskComments({ taskId, commentId, requestBody }) {
    return session.put(`/tasks/comments/${taskId}/edit/${commentId}/`, requestBody);
  },
};
