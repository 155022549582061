import type { RouteLocationRaw, RouteRecordRaw } from 'vue-router';

import { requireAuth, requireNotAuth } from '@/router/hooks/auth';

import TeamHelper from '@/helpers/TeamHelper';
import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import activity from '@/router/routes/activity';
import auth from '@/router/routes/auth';
import billing from '@/router/routes/billing';
import feeds from '@/router/routes/feeds';
import finances from '@/router/routes/finances';
import payouts from '@/router/routes/payouts';
import reports from '@/router/routes/reports';
import shared from '@/router/routes/shared';
import tasks from '@/router/routes/tasks';
import team from '@/router/routes/team';
import updates from '@/router/routes/updates';
import { views } from '@/router/routes/views';
import wizard from '@/router/routes/wizard';

const goToPageIfHaveToken = (redirectName: string): RouteLocationRaw => {
  const token = localStorage.getItem('AUTH');
  const teamId = TeamHelper.getLastTeamId();
  const isAccountDeleted = Boolean(localStorage.getItem('deletedAccount'));
  let route: RouteLocationRaw = { name: pageNames.auth.LOGIN };

  if (token) {
    route = { name: pageNames.wizard.WIZARD };

    if (teamId) {
      route = { name: redirectName, params: { teamId } };
    }

    if (isAccountDeleted) {
      route = {
        name: pageNames.auth.RESTORE_ACCOUNT,
        params: {
          teamId,
        },
      };
    }
  }

  return route;
};

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/',
    redirect: () => goToPageIfHaveToken(pageNames.views.USER_VIEW),
  },
  {
    path: '/shared/:token/',
    component: () => import('@/layouts/Shared.vue'),
    children: [...shared],
  },
  {
    path: '/auth/',
    component: () => import('@/layouts/NoAuthenticated.vue'),
    children: [...auth],
    beforeEnter: requireNotAuth,
  },
  {
    path: '/wizard/',
    children: [...wizard],
    component: () => import('@/layouts/WizardLayout.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/:teamId(\\d+)/',
    component: () => import('@/layouts/Default.vue'),
    children: [
      ...activity,
      ...tasks,
      ...team,
      ...payouts,
      ...billing,
      ...updates,
      ...feeds,
      ...reports,
      ...finances,
      ...views,
      {
        path: ':pathMatch(.*)*',
        component: () => import('@/pages/errors/PageNotFound.vue'),
        meta: {
          title: new Translation('routes.notFound'),
        },
        props: () => ({ hasBtn: false }),
      },
    ],
    beforeEnter: requireAuth,
  },
  {
    path: '/page-not-found',
    name: pageNames.errors.PAGE_NOT_FOUND,
    meta: {
      title: new Translation('routes.notFound'),
    },
    component: () => import('@/pages/errors/PageNotFound.vue'),
  },
  {
    path: '/page-error-invite',
    name: pageNames.errors.PAGE_INVITE_ERROR,
    meta: {
      title: new Translation('routes.noAccess'),
    },
    component: () => import('@/pages/errors/PageInviteError.vue'),
  },
  {
    path: '/page-no-access',
    name: pageNames.errors.PAGE_NO_ACCESS,
    meta: {
      title: new Translation('routes.noAccess'),
    },
    component: () => import('@/pages/errors/PageNoAccess.vue'),
  },
  {
    path: '/page-mobile-only',
    name: pageNames.errors.PAGE_MOBILE_ONLY,
    meta: {
      title: new Translation('routes.mobileOnly'),
    },
    component: () => import('@/pages/errors/PageMobileOnly.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => goToPageIfHaveToken(pageNames.errors.PAGE_NOT_FOUND),
  },
];

export default routes;
