export default {
  name: 'Title',
  datasource: 'Data base',
  datasourcePlaceholder: 'Storage of cards for a specific project',
  type: 'Type',
  typePlaceholder: 'Method of visual display of cards',
  access: 'Display cards',
  editMode: 'Edit',
  modalViewDatasource: {
    btnCancel: 'Cancel',
    btnConfirm: 'Modify',
    titleSource: 'Do you really want to change the database of the view?',
    titleView: 'Do you really want to change the type of view?',
    titleAccess: 'Do you really want to change the accessibility of the view?',
  },
  views: {
    myTasks: 'My tasks',
    allInOne: 'All in one',
    view: 'View',
    archive: 'Archive',
  },
  tabs: {
    general: 'General',
    access: 'Access',
    status: 'Statuses',
    tags: 'Tags',
    types: 'Card types',
    cards: 'Card',
  },
  breadcrumbs: {
    views: 'Views - display',
    viewSettings: 'View settings',
    settings: 'Settings',
    viewCreate: 'Create a view',
  },
  blockStatuses: 'Lock statuses',
  cardType: 'Type customization',
  unlockStatuses: 'Unlock statuses',
  duplicate: 'Duplicate',
  duplicateDescription:
    'You can duplicate the view with the current display type and source, or change them to other options',
  duplicateCopy: 'Duplicate with cards',
  delete: 'Delete',
  subtasks: 'Subtasks',
  subtasksActions: {
    hideAll: 'Hide all subtasks',
    showAll: 'Show all subtasks',
    showSeparately: 'Show subtasks separately',
  },
  restore: 'Restore',
  connectStatuses: 'Connect statuses',
  viewTypes: {
    board: 'Board',
    list: 'List',
    matrix: 'Matrix',
    calendar: 'Calendar',
    files: 'Files',
  },
  viewActions: {
    edit: 'Edit',
    delete: 'Delete',
    favorite: 'Add to favorite',
    fromFavorite: 'From favorite',
    settings: 'Settings',
  },
  cardSettings: {
    title: 'Customize the appearance of your cards',
    placeholder: 'Here you can see the changes on the task card',

    executors: 'Executors',
    responsible: 'Responsible',
    tags: 'Tags',
    slug: 'Key',
    viewers: 'Viewers',
    interactiveButtons: 'Interactive buttons',
    informationButtons: 'Informational buttons',
    date: 'Date',
    typeCard: 'Card type',
    status: 'Card Status',
    repeat: 'Card repeat',
    cover: 'Card cover',
    cost: 'Cost',

    name: 'Task layout',

    tagDesign: 'Design',
    tagMobile: 'Mobile layout',
    tagApproved: 'Approved',
    tagTeam: 'Team',
  },
  emoji: 'Emoji',
  icons: 'Icons',
  upload: 'Upload',
  card: 'Card',
  template: 'Template',
  stage: 'Stage',
  status: 'Status',
  uploadText: 'Select or drag the files (jpeg, png) you want to upload here (up to 2MB)',
  showMore: 'Show more',
  create: 'Create',
  goToView: 'Go to view',
};
