export default {
  datasource: 'Database',
  datasourceInput: 'Input datasource',
  key: 'Key',
  service: 'Serves to generate a unique task identifier',
  inputKey: 'Input key',
  inputText: 'Input text',
  comment: 'Comment',
  create: 'Create',
  cancel: 'Cancel',
};
