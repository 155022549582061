import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';
import { linkGantt, tasksWithLinks, taskTimeline } from '@/models/tasks/TaskGantt';

import ZodHelper from '@/helpers/ZodHelper';

export type RequestBodyTaskGantt = {
  date_start?: string | null;
  archived?: boolean;
  date_start_time?: string | null;
  deadline?: string | null;
  name?: string;
  deadline_time?: string | null;
  completed?: boolean;
  gantt_type?: string;
  parent?: number | null;
};
export type ResponseBodyTaskGantt = {
  id: number;
  date_start: string | null;
  date_start_time: string | null;
  deadline: string | null;
  name: string;
  cost: string | null;
  parent: number | null;
  currency_value?: any | null;
  deadline_time: string | null;
  priority: string;
  task_labels: any;
  level: number;
  blocked_by_another?: Array<any>;
  blocked_by_yourself?: Array<any>;
  gantt_type: string;
  executors: {
    avatar: string | null;
    color: string;
    finished?: boolean;
    fullName: string;
    id: number;
    executorId: number;
    itsMe?: boolean;
  }[];
  responsibles: {
    avatar: string | null;
    color: string;
    fullName: string;
    responsibleId: number;
    id: number;
  }[];
  completed: boolean;
};
type RequestTaskGantt = {
  taskId: number;
  shiftTask?: boolean;
  requestBody: RequestBodyTaskGantt;
};
const apiGanttTasks = {
  getGanttList: ({ teamId, viewId, getParams }: { teamId: number; viewId: number; getParams: any }) => {
    const sharedUrl = `/shared/tasks/${viewId}/timeline-gantt-list/`;
    const defaultUrl = `/tasks/gantt-list/${teamId}/`;
    return ZodHelper.request(
      () =>
        session.get(getRequestUrl(defaultUrl, sharedUrl), {
          params: { ...getParams, views: viewId },
        }),
      taskTimeline.array(),
    );
  },
  getGanttListWithLinks: ({ teamId, viewId, getParams }: { teamId: number; viewId: number; getParams: any }) => {
    const sharedUrl = `/shared/tasks/${viewId}/timeline-gantt-list/`;
    const defaultUrl = `/tasks/gantt-list/${teamId}/`;
    return ZodHelper.request(
      () =>
        session.get(getRequestUrl(defaultUrl, sharedUrl), {
          params: { ...getParams, views: viewId, gantt: true, dontShowSubtasks: false },
        }),
      tasksWithLinks,
    );
  },
  editGanttTask: ({ taskId, requestBody, shiftTask }: RequestTaskGantt) => {
    return session.patch(`/tasks/edit/${taskId}/`, requestBody, { params: { shift_task: shiftTask, gantt: true } });
  },
  createGanttTask: ({
    teamId,
    ...requestBody
  }: {
    teamId: number;
    name: string;
    type: number;
    view: number;
    datasource: number;
    gantt_type?: string;
  }) => {
    return session.post(`/tasks/create/${teamId}/`, requestBody, { params: { gantt: true } });
  },
  dragAndDropGanttTask: ({
    prevTask,
    viewId,
    taskId,
    teamId,
    parent,
    is_gantt,
    shiftTask,
  }: {
    prevTask: number | null;
    viewId: number;
    taskId: number;
    teamId: number;
    parent: number | null;
    is_gantt: boolean;
    shiftTask?: boolean;
  }) => {
    return session.patch(`/tasks/gantt-edit/${teamId}/${taskId}/`, {
      prev_task: prevTask,
      view: viewId,
      shift_task: shiftTask,
      is_gantt,
      parent,
    });
  },
  changeGanttComplete: ({ taskId, userId, completed }: { taskId: number; userId: number; completed: boolean }) => {
    return session.patch(`/tasks/executors/${taskId}/update/${userId}/`, { finished: completed });
  },
  createLink({
    taskId,
    requestBody,
    shiftTask,
  }: {
    taskId: number;
    requestBody: { blocked_task: number; blocking_task: number; connection_type: string };
    shiftTask?: boolean;
  }) {
    return ZodHelper.request(
      () => session.post(`tasks/task-block/create-lock/${taskId}/`, { ...requestBody, shift_task: shiftTask }),
      linkGantt,
    );
  },
  deleteLink({ taskId, linkId }: { taskId: number; linkId: number }) {
    return session.delete(`tasks/task-block/delete-lock/${taskId}/${linkId}/`);
  },
};

export default apiGanttTasks;
