import accounts from '@/locales/en/finances/accounts';
import articles from '@/locales/en/finances/articles';
import counterparts from '@/locales/en/finances/counterparts';
import directions from '@/locales/en/finances/directions';
import transactions from '@/locales/en/finances/transactions';

const finances = {
  create: 'Create',

  counterparts,
  directions,
  articles,
  accounts,
  transactions,

  notFound: 'Nothing was found',
};

export default finances;
