import type { App } from 'vue';

import { initGtm } from './GTM';
import { initVeeValidate } from './Veevalidate';
import { initAmplitude } from './amplitude';
import { initMediaQuery } from './mediaQuery';
import { initPortal } from './portal';
import { initSentry } from './sentry';
import { initVueTippy } from './tippy/tippy';
import { initTranslate } from './translate';
import { initVueQuery } from './vueQuery';

import '@/styles/elements/datepicker.scss';

export const initAllPlugins = async (app: App): Promise<void> => {
  await Promise.all([
    initPortal(app),
    initGtm(app),
    initMediaQuery(app),
    initAmplitude(app),
    initVueQuery(app),
    initVeeValidate(),
    initTranslate(app),
    initVueTippy(app),
    initSentry(app),
  ]);
};
