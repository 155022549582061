import { z } from 'zod';

export const tag = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  team: z.number(),
});

export type Tag = z.infer<typeof tag>;
