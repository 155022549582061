export default {
  title: 'Contact us',
  send: 'Send',

  email: 'E-mail',
  phone: 'Phone number',
  message: 'Message',

  notification: 'Your request has been submitted, someone will contact you soon',
};
