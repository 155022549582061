import { z } from 'zod';

import { customFieldCodes } from '@/constants/tasks/customFields';

export const customFieldValue = z.object({
  id: z.number(),
  value: z.string(),
  color: z.string(),
});
export const customFieldBase = z.object({
  id: z.string(),
  values: z.array(customFieldValue),
  name: z.string(),
  type: z.nativeEnum(customFieldCodes),
  extras: z.array(z.string()).nullable(),
  multiple_choice: z.boolean(),
});
export const customField = customFieldBase.transform(({ multiple_choice, ...value }) => ({
  ...value,
  isMultiple: multiple_choice,
}));

export const customFieldToCardTypeField = customField.transform(({ id, name, type }) => ({
  default: false,
  id,
  name,
  type,
  icon: type,
}));

export type CustomField = z.infer<typeof customField>;
