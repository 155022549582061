export default {
  name: 'Name',
  placeholder: 'Set name',
  source: 'Database',
  type: 'Type',
  nothing: 'Choose a card display option',
  create: 'Create',
  view: 'Display cards',
  settings: 'All settings',
  viewSelect: {
    public: 'All',
    private: 'Only yours',
  },
  placeholderAccess: 'Team members will see cards according to this setting',
  selectView: 'Select the display format',
  viewName: 'Name of view',
  selectSource: 'Select database',
  types: {
    board: 'Board',
    calendar: 'Calendar',
    list: 'List',
    timeline: 'Timeline',
    matrix: 'Matrix',
    files: 'Files',
    stages: 'Stages',
    gantt: 'Gantt',
    folder: 'Folder',
  },
  alpha: 'Alpha version',
  cancel: 'Cancel',
  needUpdate: 'Tariff needs to be improved',
  expandSettings: 'Expanded settings',
  enterTitle: 'Enter title',
};
