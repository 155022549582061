import session from '@/api/session';

export default {
  getReport({ teamId, getParams: params }) {
    return session.post(`payouts/${teamId}/report/create/`, { name: 'Отчет' }, { params, responseType: 'blob' });
  },
  getPayouts({ teamId, getParams: params }) {
    return session.get(`payouts/payout/${teamId}/list/`, { params });
  },
  getPayoutsExecutor({ teamId, executorId }) {
    return session.get(`payouts/payout/${teamId}/list/${executorId}/`);
  },
  getPayoutDetails({ teamId, payoutId }) {
    return session.get(`payouts/payout/${teamId}/detail/${payoutId}/`);
  },
  createPayout({ teamId, payout }) {
    return session.post(`payouts/payout/${teamId}/create/`, payout);
  },
  editPayout({ teamId, payoutId, requestBody }) {
    return session.patch(`payouts/payout/${teamId}/edit/${payoutId}/`, requestBody);
  },
  confirmPayout({ teamId, payoutId, requestBody }) {
    return session.patch(`payouts/payout/${teamId}/accept/${payoutId}/`, requestBody);
  },
  deletePayout({ teamId, payoutId }) {
    return session.delete(`payouts/payout/${teamId}/delete/${payoutId}/`);
  },
};
