import type { App } from 'vue';

export const initMaxlength = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.directive('maxlength', {
      beforeMount(el, binding) {
        el.dataset.maxlength = Number(binding.value).toString();

        el.addEventListener('input', ({ target }: Event) => {
          if (target instanceof HTMLInputElement || (target as HTMLElement).isContentEditable) {
            const text = (target as HTMLElement).innerText;

            if (el.dataset.maxlength && text.length >= Number(el.dataset.maxlength)) {
              (target as HTMLElement).innerText = text.slice(0, Number(el.dataset.maxlength) - 1);
              const sel = document.getSelection();
              const node = (target as HTMLElement).firstChild;

              if (sel && node && node.textContent) {
                const r = sel.getRangeAt(0);
                sel.getRangeAt(0).setEnd(node, node.textContent.length);
                sel.removeRange(r);
              }
            }
          }
        });
      },

      updated(el, binding) {
        el.dataset.maxlength = Number(binding.value);
      },
    });

    resolve();
  });
};
