import filtersCompleting from './filtersCompleting';

import actions from '@/locales/en/constants/tasks/actions';
import checklistActions from '@/locales/en/constants/tasks/checklistActions';
import contentFilters from '@/locales/en/constants/tasks/contentFilters';
import customFields from '@/locales/en/constants/tasks/customFields';
import datasourceCreate from '@/locales/en/constants/tasks/datasourceCreate';
import deadlines from '@/locales/en/constants/tasks/deadlines';
import filesActions from '@/locales/en/constants/tasks/filesActions';
import groupActions from '@/locales/en/constants/tasks/groupActions';
import linksOption from '@/locales/en/constants/tasks/linksOption';
import priorities from '@/locales/en/constants/tasks/priorities';
import projects from '@/locales/en/constants/tasks/projects';
import statuses from '@/locales/en/constants/tasks/statuses';
import subtaskActions from '@/locales/en/constants/tasks/subtaskActions';
import taskActions from '@/locales/en/constants/tasks/taskActions';
import taskUpdates from '@/locales/en/constants/tasks/taskUpdates';
import typeRepeat from '@/locales/en/constants/tasks/typeRepeat';
import week from '@/locales/en/constants/tasks/week';

export default {
  filtersCompleting,
  projects,
  taskActions,
  statuses,
  priorities,
  actions,
  taskUpdates,
  deadlines,
  datasourceCreate,
  week,
  customFields,
  typeRepeat,
  groupActions,
  linksOption,
  subtaskActions,
  contentFilters,
  checklistActions,
  filesActions,
};
