import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

import useTranslate from '@/hooks/common/useTranslate';

import routes from '@/router/routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach(async (to) => {
  const { translate } = useTranslate();
  await nextTick();

  if (to.meta.seoTitle || !to.meta.title) return;

  store.dispatch('title/SET_TITLE', translate(to.meta.title));
});

export default router;
