export default {
  title: 'Accounts',
  reference: 'Accounts are a list of your bank accounts. Banking integrations available',
  listEmpty: "You don't have any accounts added yet",

  create: {
    create: 'Create',
    edit: 'Edit',
    account: 'Bank account',
    card: 'Bank card',
    cash: 'Cash',
    eMoney: 'Electronic Money',

    add: 'Create',
    save: 'Save',
    cancel: 'Cancel',
  },

  activity: {
    individual: 'Individual',
    entity: 'Legal Entity',
    individualEntrepreneur: 'Individual Entrepreneur',
  },

  currency: {
    rub: '₽ (RUB)',
    eur: '€ (EUR)',
    usd: '$ (USD)',
  },

  filter: {
    all: 'All',
    active: 'Active',
    archive: 'Archived',
  },

  fields: {
    name: 'Name',
    type: 'Account type',
    template: 'Template',
    inn: 'TIN',
    balance: 'Balance',
    currency: 'Currency',
    fixBalance: 'Record balance',
    group: 'Group',
  },

  createTemplate: 'Create template',

  fieldSettings: 'Fields settings',
  field: 'Fields',

  clearAll: 'Clear filters',

  errorCreate: 'Fill in the required fields: Name, Balance',

  operations: 'Account operations',
  archiveAccounts: 'Account archiving',
  archivePlaceholder: 'When an account is archived, all related transactions will be archived',
  resetAccounts: 'Restore Account',
  resetPlaceholder: 'When restoring an account, all related transactions will be restored',
  deleteAccounts: 'Deleting an account',
  deletePlaceholder: 'When deleting an account, all associated transactions will be deleted',
  archive: 'Archive account and transactions',
  reset: 'Restore account and transactions',
  delete: 'Delete account and transactions',
  cancel: 'Cancel',
};
