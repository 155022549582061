import { createGtm } from '@gtm-support/vue-gtm';
import type { App } from 'vue';

export const initGtm = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.use(
      createGtm({
        id: 'GTM-MDB3H58',
      }),
    );
    resolve();
  });
};
