import session from '@/api/session';

import type { User } from '@/types/types';

type ChangePassword = {
  old_password: string;
  new_password: string;
};

type ResponseLogin = {
  token: string;
  user: User;
};

export default {
  login(username: string, password: string) {
    return session.post<ResponseLogin>('users/user/login/', { username, password });
  },
  logout(key: string) {
    return session.post<null>('users/user/logout/', { key });
  },
  changePassword({ old_password, new_password }: ChangePassword) {
    return session.put<null>('users/user/change-password/', {
      old_password,
      new_password,
    });
  },
};
