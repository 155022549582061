export const svgIcons = {
  ACTIVITY: 'activity',
  ALPHA: 'alpha',
  FEED: 'feed',
  FINANCES: 'finances',
  MY_TASKS: 'my-tasks',
  PROJECTS: 'projects',
  REPORTS: 'reports',
  CHEVRON_UP: 'chevron-up',
  ARROW: 'arrow',
  DOWNLOAD: 'download',
  HELP: 'help',
  SETTINGS: 'settings',
  SORTING_DATE: 'sorting_date',
  ROCKET: 'rocket',
  LIGHTNING: 'lightning',
  ROCKET_GRADIENT: 'rocket_gradient',
  CALENDAR: 'calendar',
  PLUS: 'plus',
  CHOICE: 'choice',
  CALCULATOR: 'calculator',
  USER: 'user',
  RESPONSIBLE: 'responsible',
  TASK_STATUS: 'task-status',
  TASK_PRIORITY: 'task-priority',
  CROSS: 'cross',
  CLOCK: 'clock',
  TASK_STAGE: 'task-stage',
  TASK_TAGS: 'task-tags',
  FIRE: 'fire',
  TASK_CREATOR: 'task-creator',
  PRICE: 'price',
  CHECK_ALL: 'check-all',
  PLAY: 'play',
  FINANCES_ACCOUNT: 'finances_account',
  CONTRACTOR: 'contractor',
  DIRECTION: 'direction',
  EXPENSE: 'expense',
  CURRENCY: 'currency',
  STACK: 'stack',
  ENTER: 'enter',
  ATTACHMENT: 'attachment',
  LOCK: 'lock',
  LOCK_OPEN: 'lock-open',
  SUBTASKS: 'subtasks',
  SUBTASK_TITLE: 'subtask_title',
  ALERT: 'alert',
  COMMENT: 'comment',
  DRAG_AND_DROP: 'drag-and-drop',
  FOLDER: 'folder',
  FOLDER_OPEN: 'folder-open',
  CHECKLIST: 'checklist',
  COMMENT_PROCESSING: 'comment-processing',
  INTEGRATIONS: 'integrations',
  DESCRIPTION: 'description',
  ARCHIVE: 'archive',
  ASSIGN_TO_ME: 'assign-to-me',
  COPY: 'copy',
  DATABASE: 'database',
  LINK: 'link',
  LINK_EXTERNAL: 'link-external',
  PENCIL: 'pencil',
  TASK: 'task',
  DELETE: 'delete',
  MOVE_TO: 'move-to',
  REPEAT: 'repeat',
  RESET: 'reset',
  PAUSE: 'pause',
  SHARE: 'share',
  HISTORY: 'history',
  PICTURE: 'picture',
  USERS: 'users',
  TEXT: 'text',
  TIMELINE: 'timeline',
  POUND: 'pound',
  AT: 'at',
  PHONE: 'phone',
  CHAIN: 'chain',
  LANGUAGE: 'language',
  EYE: 'eye',
  SAVE: 'save',
  SELECT: 'select',
  TEMPLATE: 'template',
  MAGNIFY: 'magnify',
  TUNE: 'tune',
  BURGER: 'burger',
  SHTAB: 'shtab',
  TASK_REPEAT: 'task-repeat',
  TARIFF: 'tariff',
  PAYMENT: 'payment',
  ALL_IN_ONE: 'all-in-one',
  STAR: 'star',
  TELEGRAM: 'telegram',
  TELEGRAM_ICON: 'telegram-icon',
  VK: 'vk',
  YOUTUBE: 'youtube',
  GOOGLE_CALENDAR: 'google-calendar',
  GOOGLE: 'google',
  YANDEX: 'yandex',
  APPLE: 'apple',
  CIRCLE: 'circle',
  OPERATION: 'operation',
  COLOR_PICKER: 'color-picker',
  ARROW_DOUBLE: 'arrow-double',
  BOARD: 'board',
  LIST: 'list',
  MATRIX: 'matrix',
  MINUS: 'minus',
  MERGE: 'merge',
  UNPIN: 'unpin',
  INFO: 'info',
  SPLIT: 'split',
  MILESTONE: 'milestone',
  MILESTONE_TRANSPARENT: 'milestone-transparent',
  ANSWER: 'answer',
  TRELLO: 'trello',
  GANTT: 'gantt',
  JIRA: 'jira',
  CSV: 'csv',
  WEEEK: 'weeek',
  WRIKE: 'wrike',
  ASANA: 'asana',
  COVER: 'cover',
  EMBED: 'embed',
  EMAIL: 'email',
  BANK: 'bank',
  BITBUCKET: 'bitbucket',
  TINKOFF: 'tinkoff',
  GITHUB: 'github',
  TOCHKA_BANK: 'tochka-bank',
  GITLAB: 'gitlab',
  SENTRY: 'sentry',
  LAMBDATEST: 'lambdatest',
  QASE: 'qase',
  SLACK: 'slack',
  TEAMS: 'teams',
  CURTAIN: 'curtain',
  DATE_CREATED: 'date-created',
  DATE_COMPLETED: 'date-completed',
  COPY_LINK: 'copy-link',
} as const;
