export default {
  title: {
    year: 'Connecting plan {name} ({type})',
    editYear: 'Change plan {name} ({type})',
  },

  input: {
    label: 'number of users',
    placeholder: '0 to ∞',
  },

  intervalMessage: {
    month: '{count} months',
    years: '{count} years',
  },

  year: 'year',
  month: 'month',

  success: {
    debited: '{sum} has been debited from your balance',
    trial: 'Trial connected successfully',
    tariffConnected: 'Tariff {name} ({interval}) successfully connected',
  },

  error: 'Error when changing tariff',
  topUpBalance: 'Top up balance',

  promoCode: {
    enterPromo: 'Enter promotional code',
    apply: 'Apply',
    delete: 'Delete',
    valid: 'The promotional code will be valid until {date}',
    invalid: 'Promotional code is invalid',

    havePromo: 'I have a promo code',
  },

  total: {
    title: 'Total',
    tariff: 'Tariff',
    promoCode: 'Promo code',
    perUser: 'Per user',

    connect: 'Connect',

    discountYear: 'You save {discount}%',
    discountMonth: 'The longer the subscription period, the greater the savings',
  },

  aPay: {
    title: 'Additional payment',
    description: 'Payment for additional participants is made immediately',
  },

  connectError: {
    activate: 'To activate the {name} tariff,',
    unable: 'Unable to connect to {name} tariff',
    or: 'or',
    reduceMembers: 'reduce the number of users',
    reduceProjects: 'reduce the number of projects',
    buy: 'buy more',

    maxMembers: 'Maximum number of users in a team: {count}',
    maxProjects: 'Maximum number of projects: {count}',
    maxStorage: 'Free up file storage',
  },

  errors: {
    minMembers: 'You cannot select fewer users than you have in your team',
    maxMembers: 'You cannot add more than {count} users',
    reduceMembers: 'Reduce the number of users',
  },
  changeTariffCardType: 'Customize cards to your needs and tasks',
  changeTariff: 'Switch to the "PRO" tariff to unlock the full potential of SHTAB',
  selectTariff: 'Select tariff',
};
