const debounce = (fn: (...args: any[]) => void, ms: number = 500) => {
  let timeout: undefined | number;

  return (...args: any[]) => {
    // Args, может пригодиться, если надо получать Event
    clearTimeout(timeout);
    // @ts-ignore
    timeout = setTimeout(() => fn(...args), ms);
  };
};

export default debounce;
