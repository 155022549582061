import { createPermissionHook } from '@/router/hooks/permissions.js';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageViews } from '@/constants/permissions/listPermissions.js';
import { portalNames } from '@/constants/portals';

export const views = [
  {
    path: 'views/user',
    name: pageNames.views.USER_VIEW,
    component: () => import('@/pages/views/UserView.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId), sortingCode: String(route.query.sorting ?? '') }),
    meta: {
      title: new Translation('view.viewGeneral.views.myTasks'),
      breadcrumbs: [
        {
          title: new Translation('view.viewGeneral.views.myTasks'),
        },
      ],
    },
  },
  {
    path: 'views/all-in-one',
    name: pageNames.views.ALL_IN_ONE,
    component: () => import('@/pages/views/AllInOneView.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId), sortingCode: String(route.query.sorting ?? '') }),
    meta: {
      title: new Translation('view.viewGeneral.views.allInOne'),
      breadcrumbs: [
        {
          title: new Translation('view.viewGeneral.views.allInOne'),
        },
      ],
    },
  },
  {
    path: 'views/archive',
    name: pageNames.views.ARCHIVE,
    component: () => import('@/pages/views/ArchiveView.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId) }),
    meta: {
      title: new Translation('view.viewGeneral.views.archive'),
      breadcrumbs: [
        {
          title: new Translation('view.viewGeneral.views.archive'),
        },
      ],
    },
  },
  {
    path: 'views/view/:viewId(\\d+)',
    name: pageNames.views.VIEW,
    component: () => import('@/pages/views/RoomView.vue'),
    props: (route) => ({
      teamId: Number(route.params.teamId),
      viewId: Number(route.params.viewId),
      sortingCode: String(route.query.sorting ?? ''),
    }),
    meta: {
      title: new Translation('view.viewGeneral.breadcrumbs.views'),
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.view,
        },
      ],
    },
  },
  {
    path: 'views/settings/:viewId(\\d+)',
    name: pageNames.views.VIEW_SETTINGS,
    component: () => import('@/pages/views/ViewSettings.vue'),
    props: (route) => ({ teamId: Number(route.params.teamId), viewId: Number(route.params.viewId) }),
    meta: {
      title: new Translation('view.viewGeneral.breadcrumbs.settings'),
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.view,
        },
      ],
    },
    beforeEnter: createPermissionHook(canManageViews, { name: pageNames.errors.PAGE_NO_ACCESS }),
  },

  // Redirect from old routes with rooms
  {
    path: 'rooms/:roomId(\\d+)/views/:viewId(\\d+)',
    redirect: (to) => ({
      name: pageNames.views.VIEW,
      params: { viewId: Number(to.params.viewId), teamId: Number(to.params.teamId) },
    }),
  },
];
