export default {
  LOGIN: 'Login',
  LOGIN_SUCCESS: 'LoginSuccess',
  REGISTRATION: 'Registration',
  REGISTRATION_INVITED_USER: 'RegistrationInvitedUser',
  REGISTRATION_SUCCESS: 'RegistrationSuccess',
  RESTORE_ACCOUNT: 'RestoreAccount',
  RESTORE_PASSWORD: 'RestorePassword',
  RESTORE_ACCOUNT_SUCCESS: 'RestoreAccountSuccess',
  RESTORE_CHANGE_PASSWORD: 'RestoreChangePassword',
  RESTORE_CHECK_TOKEN: 'RestoreCheckToken',
  ACTIVATE_ACCOUNT: 'ActivateAccount',
  INVITATION_CHECK_TOKEN: 'Invitation',
  SOCIAL: 'Social',
};
