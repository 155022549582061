import apiPayouts from '@/api/payouts';

import checkResponse from '@/utils/checkResponse';
import downloadFile from '@/utils/downloadFile';
import parseDateToJSON from '@/utils/parseDateToJSON';

import RequestError from '@/errors/RequestError';

const root = { root: true };

const state = {
  payouts: [],
  payoutSelected: null,
  totalInformation: null,
  executorSelected: null,

  filters: {
    dateRange: {
      start: null,
      end: null,
    },
    search: '',
  },

  searchExecutors: '',
};

const getters = {
  getParams({ filters: { dateRange, search } }) {
    return {
      date_from: parseDateToJSON(dateRange.start),
      date_until: parseDateToJSON(dateRange.end),
      search,
    };
  },
};

const mutations = {
  setPayouts(state, payouts) {
    state.payouts = payouts;
  },
  extendPayout(state, payoutExtended) {
    const payoutIndex = state.payouts.findIndex(({ id }) => id === payoutExtended.id);
    state.payouts[payoutIndex] = payoutExtended;
  },
  updatePayout({ payouts }, payoutUpdated) {
    const payoutIndex = payouts.findIndex((payout) => payout.id === payoutUpdated.id);
    state.payouts[payoutIndex] = payoutUpdated;
  },

  setTotalInformation(state, information) {
    if (!information.total_time) information.total_time = { hours: 0, minutes: 0 };
    state.totalInformation = information;
  },

  setPayoutSelected(state, payout) {
    state.payoutSelected = payout;
  },
  setExecutorSelected(state, executor) {
    state.executorSelected = executor;
  },

  setFilterDateRange(state, dateRange) {
    state.filters.dateRange = dateRange;
  },
  setFilterSearch(state, search) {
    state.filters.search = search;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },

  setSearchExecutors(state, search) {
    state.searchExecutors = search;
  },

  resetPayouts(state) {
    state.payouts = [];
  },
  resetTotalInformation(state) {
    state.totalInformation = null;
  },
  resetExecutorSelected(state) {
    state.executorSelected = null;
  },
  resetFilters(state) {
    state.filters = {
      dateRange: {
        start: null,
        end: null,
      },
      search: '',
    };
  },
  resetSearchExecutors(state) {
    state.searchExecutors = '';
  },
  resetPayoutSelected(state) {
    state.payoutSelected = null;
  },
};

const actions = {
  RESET({ commit }) {
    commit('resetPayouts');
    commit('resetTotalInformation');
    commit('resetFilters');
    commit('resetSearchExecutors');
    commit('resetPayoutSelected');
    commit('resetExecutorSelected');
  },

  async GET_REPORT({
    rootState: {
      teams: {
        teamSelected: { id: teamId },
      },
    },
    getters: { getParams },
  }) {
    try {
      const requestData = {
        teamId,
        getParams,
      };
      const { data: reportBinaryExcel } = await apiPayouts.getReport(requestData);
      downloadFile(reportBinaryExcel, 'xlsx');
    } catch (e) {
      console.error('Ошибка при загрузке excel файла с отчетом о выплатах', e);
      throw e;
    }
  },
  async GET_PAYOUT_SELECTED(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
      state,
      commit,
    },
    payoutId,
  ) {
    try {
      const savedPayout = state.payouts.find((payout) => payout.id === payoutId);
      if (savedPayout) {
        commit('setPayoutSelected', savedPayout);
        return;
      }

      const { data: payout } = await apiPayouts.getPayoutDetails({ teamId, payoutId });
      commit('setPayoutSelected', payout);
    } catch (e) {
      console.error('Ошибка при получени выплаты', e);
      checkResponse(e);
      throw e;
    }
  },
  async GET_PAYOUT_DETAILS(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
      commit,
    },
    payoutId,
  ) {
    try {
      const { data: payout } = await apiPayouts.getPayoutDetails({ teamId, payoutId });
      commit('extendPayout', payout);
    } catch (e) {
      console.error('Ошибка при получени полной информации о выплате', e);
      throw e;
    }
  },
  async GET_PAYOUTS({
    rootState: {
      teams: {
        teamSelected: { id: teamId },
      },
    },
    getters: { getParams },
    commit,
  }) {
    try {
      commit('loading/startLoading', 'payouts', root);

      const requestData = {
        teamId,
        getParams,
      };
      const {
        data: { payouts, total },
      } = await apiPayouts.getPayouts(requestData);
      commit('setPayouts', payouts.reverse());
      commit('setTotalInformation', total);

      return payouts;
    } catch (e) {
      console.error('Ошибка при получении всех выплат пользователя', e);
      throw e;
    } finally {
      commit('loading/finishLoading', 'payouts', root);
    }
  },
  async GET_PAYOUTS_EXECUTOR(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
      commit,
      dispatch,
    },
    executorId,
  ) {
    try {
      dispatch('RESET');
      commit('loading/startLoading', 'payouts', root);

      const {
        data: { payouts, total, user },
      } = await apiPayouts.getPayoutsExecutor({ teamId, executorId });

      commit('setPayouts', payouts.map((payout) => ({ ...payout, user })).reverse());
      commit('setTotalInformation', total);
      commit('setExecutorSelected', user);

      return payouts;
    } catch (e) {
      console.error('Ошибка при получении всех выплат совершенных пользователю', e);
      throw e;
    } finally {
      commit('loading/finishLoading', 'payouts', root);
    }
  },
  async CREATE_PAYOUT(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    payout,
  ) {
    try {
      const { data: payoutCreated } = await apiPayouts.createPayout({ teamId, payout });
      return payoutCreated;
    } catch (e) {
      console.error('Ошибка при создании платежа', e);
      throw new RequestError(e);
    }
  },
  async EDIT_PAYOUT(
    {
      commit,
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    { payoutId, requestBody },
  ) {
    try {
      const { data: payoutUpdated } = await apiPayouts.editPayout({ teamId, payoutId, requestBody });
      commit('updatePayout', payoutUpdated);
    } catch (e) {
      console.error('Ошибка при редактировании платежа', e);
      throw e;
    }
  },
  async CONFIRM_PAYOUT(
    {
      rootState: {
        teams: { teamSelected },
      },
    },
    { payoutId, requestBody, teamId = teamSelected.id },
  ) {
    try {
      const { data: payoutEdited } = await apiPayouts.confirmPayout({
        teamId,
        payoutId,
        requestBody,
      });
      return payoutEdited;
    } catch (e) {
      console.error('Ошибка при редактировании платежа', e);
      throw e;
    }
  },
  async DELETE_PAYOUT(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    payoutId,
  ) {
    try {
      const { data: payoutDeleted } = await apiPayouts.deletePayout({ teamId, payoutId });
      return payoutDeleted;
    } catch (e) {
      console.error('Ошибка при удалении платежа', e);
      throw e;
    }
  },
  async CREATE_SALARY(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    salaryRate,
  ) {
    try {
      const { data: salaryCreated } = await apiPayouts.createSalary({ teamId, salaryRate });
      return salaryCreated;
    } catch (e) {
      console.error('Ошибка при создании зарплаты', e);
      throw e;
    }
  },
  async EDIT_SALARY(
    {
      rootState: {
        teams: {
          teamSelected: { id: teamId },
        },
      },
    },
    { salaryId, requestBody },
  ) {
    try {
      const { data: salaryCreated } = await apiPayouts.editSalary({ teamId, salaryId, requestBody });
      return salaryCreated;
    } catch (e) {
      console.error('Ошибка при редактировании зарплаты', e);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
