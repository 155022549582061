import z from 'zod';

import type { ResponseBodyTaskGantt } from '@/api/tasks/apiGanttTasks';
import { cardLink } from '@/models/Link';

export const taskGanttTransformer = ({
  date_start,
  deadline,
  date_start_time,
  deadline_time,
  ...data
}: ResponseBodyTaskGantt) => {
  const timeStart = date_start_time ? 'T' + date_start_time : 'T00:00:00';
  const timeEnd = deadline_time ? 'T' + deadline_time : 'T23:59:59';
  const dateStart = new Date(deadline ? deadline + timeEnd : 0);
  if (date_start && !deadline) {
    return { dateStart: new Date(date_start + timeStart), dateEnd: new Date(date_start + timeEnd), ...data };
  }
  if (!date_start && deadline) {
    if (new Date(deadline + timeEnd).getHours() < 8) {
      dateStart.setDate(dateStart.getDate() - 1);
    }
    dateStart.setHours(8, 0, 0);
    return { dateStart, dateEnd: new Date(deadline + timeEnd), ...data };
  }

  return {
    dateStart: date_start ? new Date(date_start + timeStart) : null,
    dateEnd: deadline ? new Date(deadline + timeEnd) : null,
    ...data,
  };
};
export const taskTimeline = z
  .object({
    id: z.number(),
    executors: z.array(
      z
        .object({
          executor: z.object({
            id: z.number(),
            avatar: z.string().nullable(),
            color: z.string(),
            full_name: z.string(),
          }),
          finished: z.boolean(),
          id: z.number(),
          its_me: z.boolean(),
        })
        .transform(({ its_me, executor: { avatar, full_name, color, id }, id: executorId, ...fieldsExecutor }) => {
          return { itsMe: its_me, avatar, fullName: full_name, executorId, id, color, ...fieldsExecutor };
        }),
    ),
    cost: z.string().nullable(),
    priority: z.string(),
    parent: z.number().nullable(),
    name: z.string(),
    level: z.number(),
    date_start: z.string().nullable(),
    date_start_time: z.string().nullable(),
    deadline: z.string().nullable(),
    gantt_type: z.enum(['task', 'milestone']),
    deadline_time: z.string().nullable(),
    completed: z.boolean(),
    currency_value: z.any(),
    type: z.object({
      icon: z.string(),
      id: z.number(),
      name: z.string(),
    }),
    task_labels: z.array(
      z.object({
        id: z.number(),
        color: z.string(),
        name: z.string(),
      }),
    ),
    status: z
      .object({
        id: z.number(),
        order: z.number(),
        team: z.number(),
        color: z.string(),
        title: z.string(),
      })
      .nullable(),
    responsibles: z.array(
      z
        .object({
          id: z.number(),
          responsible: z.object({
            id: z.number(),
            avatar: z.string().nullable(),
            color: z.string(),
            full_name: z.string(),
          }),
        })
        .transform(({ responsible: { avatar, full_name, color, id }, id: responsibleId, ...fieldsExecutor }) => {
          return { avatar, fullName: full_name, color, id, responsibleId, ...fieldsExecutor };
        }),
    ),
  })
  .transform(taskGanttTransformer);
export const taskGantt = z
  .object({
    id: z.number(),
    executors: z.array(
      z
        .object({
          executor: z.object({
            id: z.number(),
            avatar: z.string().nullable(),
            color: z.string(),
            full_name: z.string(),
          }),
          finished: z.boolean(),
          id: z.number(),
          its_me: z.boolean(),
        })
        .transform(({ its_me, executor: { avatar, full_name, color, id }, id: executorId, ...fieldsExecutor }) => {
          return { itsMe: its_me, avatar, fullName: full_name, executorId, id, color, ...fieldsExecutor };
        }),
    ),
    blocked_by_another: z.array(cardLink),
    blocked_by_yourself: z.array(cardLink),
    gantt_type: z.enum(['task', 'milestone']),
    cost: z.string().nullable(),
    priority: z.string(),
    parent: z.number().nullable(),
    name: z.string(),
    level: z.number(),
    date_start: z.string().nullable(),
    date_start_time: z.string().nullable(),
    deadline: z.string().nullable(),
    deadline_time: z.string().nullable(),
    completed: z.boolean(),
    currency_value: z.any(),
    type: z.object({
      icon: z.string(),
      id: z.number(),
      name: z.string(),
    }),
    task_labels: z.array(
      z.object({
        id: z.number(),
        color: z.string(),
        name: z.string(),
      }),
    ),
    status: z
      .object({
        id: z.number(),
        order: z.number(),
        team: z.number(),
        color: z.string(),
        title: z.string(),
      })
      .nullable(),
    responsibles: z.array(
      z
        .object({
          id: z.number(),
          responsible: z.object({
            id: z.number(),
            avatar: z.string().nullable(),
            color: z.string(),
            full_name: z.string(),
          }),
        })
        .transform(({ responsible: { avatar, full_name, color, id }, id: responsibleId, ...fieldsExecutor }) => {
          return { avatar, fullName: full_name, color, id, responsibleId, ...fieldsExecutor };
        }),
    ),
  })
  .transform(taskGanttTransformer);

export const linkGantt = z
  .object({
    blocked_task: z.number(),
    blocking_task: z.number(),
    connection_type: z.enum(['FS', 'FF', 'SS', 'SF']),
    id: z.number(),
  })
  .transform(({ blocked_task, blocking_task, connection_type, ...link }) => ({
    blockedTask: blocked_task,
    blockingTask: blocking_task,
    connectionType: connection_type,
    ...link,
  }));
export const tasksWithLinks = z.object({
  tasks: taskGantt.array(),
  links: linkGantt.array(),
});
export type TasksWithLinksGantt = z.infer<typeof tasksWithLinks>;
export type LinkGantt = z.infer<typeof linkGantt>;
export type TaskGantt = z.infer<typeof taskGantt>;
export type TaskTimeline = z.infer<typeof taskTimeline>;
