export default {
  share: 'Share',
  history: 'History',
  screenshots: 'Screenshots',
  deleteTask: 'Archive',
  taskRepeat: 'Repeat',
  customFields: 'Fields',
  coverSettings: 'Cover',
  createTemplate: 'Template',
  error: 'This functionality is not available on the Free plan',
};
