import session from '@/api/session';
import { taskTemplateResponsible } from '@/models/tasks/TaskTemplate';

import ZodHelper from '@/helpers/ZodHelper';

type DefaultParams = {
  datasourceId: number;
  templateId: number;
  responsibleId: number;
};
type CreateParams = DefaultParams & {
  requestBody: {
    responsible: number;
    task_template: number;
  };
};

export const apiTaskTemplateResponsible = {
  createTemplateResponsible({ datasourceId, templateId, responsibleId, requestBody }: CreateParams) {
    return ZodHelper.request(
      () =>
        session.post(`tasks/task-templates/${datasourceId}/responsible/${templateId}/${responsibleId}/`, requestBody),
      taskTemplateResponsible,
    );
  },
  deleteTemplateResponsible({ datasourceId, templateId, responsibleId }: DefaultParams) {
    return session.delete(`tasks/task-templates/${datasourceId}/responsible/${templateId}/${responsibleId}/`);
  },
};
