export default {
  inputs: {
    search: 'Search',
    startTyping: 'Start typing...',
    multiUsers: 'Start typing name',

    notEmpty: 'This field cannot be empty',
  },

  checkbox: {
    delete: 'Delete',
  },

  select: {
    notSelected: 'Not selected',
    noOptions: 'No other variants',

    dropdown: {
      noResults: 'No results',
      noData: 'No data',
      loading: 'Loading',
    },

    inviteMembers: {
      addEmail: 'Add {email} for invitation',
      inputEmail: 'Start typing an e-mail address',
    },
  },
  dropdownUsersOrDepSelect: {
    departments: 'Departments',
    users: 'Users',
    all: 'Available to all',
  },

  noDate: 'no date',

  linkCopied: 'Link copied',

  return: 'Return',
  loadingTitle: 'Loading',

  notification: {
    cancel: 'Cancel',
  },

  queryHandler: {
    error: 'An error occurred.',
    tryAgain: 'Please try again later.',
    noAccess: 'You do not have access to this view',
  },
  createTemplate: 'Create new template',
};
