import activity from '@/router/helper/pageNames/activity';
import auth from '@/router/helper/pageNames/auth';
import errors from '@/router/helper/pageNames/errors';
import feeds from '@/router/helper/pageNames/feeds';
import finances from '@/router/helper/pageNames/finances';
import payouts from '@/router/helper/pageNames/payouts';
import reports from '@/router/helper/pageNames/reports';
import shared from '@/router/helper/pageNames/shared';
import tasks from '@/router/helper/pageNames/tasks';
import teams from '@/router/helper/pageNames/teams';
import updates from '@/router/helper/pageNames/updates';
import views from '@/router/helper/pageNames/views';
import wizard from '@/router/helper/pageNames/wizard';

const pageNames = {
  activity,
  auth,
  teams,
  tasks,
  payouts,
  reports,
  errors,
  updates,
  feeds,
  shared,
  wizard,
  finances,
  views,
};

export default pageNames;
