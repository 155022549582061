import TextHelper from '@/helpers/TextHelper';

import permissions from '@/constants/permissions';
import {
  canAddManualTime,
  canCreateTasks,
  canDeleteTasks,
  canDeleteTeam,
  canManageActivity,
  canManagePayouts,
  canManageTasks,
  canManageTeam,
  canViewReports,
  canCreateViews,
  canManageViews,
  canCreateTaskComments,
  canViewTasks,
} from '@/constants/permissions/listPermissions';

const createInitialState = () => ({
  role: TextHelper.safeParseJSON(localStorage.getItem('userRole')),
  permissions: [],
});

const state = createInitialState();

const getters = {
  checkPermission(state) {
    return (permission) => {
      return (state.role && permissions[state.role].includes(permission)) || state.permissions.includes(permission);
    };
  },

  [canDeleteTeam](_skip, { checkPermission }) {
    return checkPermission(canDeleteTeam);
  },
  [canManageTeam](_skip, { checkPermission }) {
    return checkPermission(canManageTeam);
  },
  [canCreateTasks](_skip, { checkPermission }) {
    return checkPermission(canCreateTasks);
  },
  [canManageTasks](_skip, { checkPermission }) {
    return checkPermission(canManageTasks);
  },
  [canDeleteTasks](_skip1, { checkPermission }, _skip2, getters) {
    const canDeleteByTeamSettings = getters['teams/teamSelectedCanUsersDeleteFlag'];
    return checkPermission(canDeleteTasks) || (state.role === 'USER' && canDeleteByTeamSettings);
  },
  [canCreateTaskComments](_skip, { checkPermission }) {
    return checkPermission(canCreateTaskComments);
  },
  [canViewTasks](_skip, { checkPermission }) {
    return checkPermission(canViewTasks);
  },
  [canManagePayouts](_skip, { checkPermission }) {
    return checkPermission(canManagePayouts);
  },
  [canViewReports](_skip, { checkPermission }) {
    return checkPermission(canViewReports);
  },
  [canManageActivity](_skip, { checkPermission }) {
    return checkPermission(canManageActivity);
  },
  [canAddManualTime](_skip, { checkPermission }) {
    return checkPermission(canAddManualTime);
  },
  [canCreateViews](_skip, { checkPermission }) {
    return checkPermission(canCreateViews);
  },
  [canManageViews](_skip, { checkPermission }) {
    return checkPermission(canManageViews);
  },
};

const mutations = {
  setRole(state, role) {
    state.role = role;
    localStorage.setItem('userRole', JSON.stringify(role));
  },

  addPermission(state, permission) {
    state.permissions = [...state.permissions, permission];
  },

  deletePermission(state, permission) {
    state.permissions = state.permissions.filter((_permission) => _permission !== permission);
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  INITIALIZE_USER_PERMISSIONS({ commit, getters }, teamMember) {
    try {
      const { role, can_add_manual_time } = teamMember;

      const permissions = new Map([[canAddManualTime, can_add_manual_time]]);

      permissions.forEach((value, key) => {
        if (value && !getters[key]) {
          commit('addPermission', key);
        } else if (!value && getters[key]) {
          commit('deletePermission', key);
        }
      });

      commit('setRole', role);
    } catch (e) {
      console.error('Ошибка при получении прав пользователя', e);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
