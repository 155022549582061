export const canUseFeed = 'canUseFeed';
export const canManualAddTime = 'canManualAddTime';
export const canUseCustomFields = 'canUseCustomFields';
export const canUseTaskHistory = 'canUseTaskHistory';
export const canUseTaskCovers = 'canUseTaskCovers';
export const canUseCardType = 'canUseCardType';
export const canChangeSharingSettings = 'canChangeSharingSettings';
export const canUseFinances = 'canUseFinances';
export const canUseGantt = 'canUseGantt';
export const canManageTemplates = 'canManageTemplates';
export const canRepeatTask = 'canRepeatTasks';
export default [
  canUseFeed,
  canManualAddTime,
  canUseCustomFields,
  canUseTaskHistory,
  canUseTaskCovers,
  canUseCardType,
  canChangeSharingSettings,
  canUseFinances,
  canUseGantt,
  canManageTemplates,
  canRepeatTask,
];
