import router from '@/router';

import pageNames from '@/router/helper/pageNames';

const goToNoAccessPage = () => {
  const Page403 = {
    name: pageNames.errors.PAGE_NO_ACCESS,
  };
  router.push(Page403);
};

export default goToNoAccessPage;
