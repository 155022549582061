import session from '@/api/session';
import { taskTrackedTime } from '@/models/tasks/TaskTrackedTime';

import ZodHelper from '@/helpers/ZodHelper';

export default {
  getTaskTrackedTime(taskId: number, getParams: { need_children_info: boolean }) {
    // TODO change back with false flag
    return ZodHelper.request(
      () =>
        session.get(`tasks/time-by-users/${taskId}/`, {
          params: getParams.need_children_info ? getParams : {},
        }),
      taskTrackedTime,
    );
  },
};
