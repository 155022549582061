export default {
  title: 'Team settings',
  general: 'General',
  handbook: 'Handbook',
  dataSources: 'Databases',
  statuses: 'Statuses',
  tags: 'Tags',
  customFields: 'Custom fields',
  cardType: 'Card types',
};
