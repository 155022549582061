import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManagePayouts } from '@/constants/permissions/listPermissions';

const requirePermission = createPermissionHook(canManagePayouts, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'payouts',
    name: pageNames.payouts.PAYOUTS,
    meta: {
      title: new Translation('routes.payouts.salary'),
    },
    component: () => import('@/pages/payouts/Payouts.vue'),
    children: [
      {
        path: '',
        name: pageNames.payouts.PAYOUTS_DEFAULT,
        meta: {
          title: new Translation('routes.payouts.listPayments'),
          seoTitle: new Translation('routes.payouts.seoSalary'),
          breadcrumbs: [
            {
              title: new Translation('routes.payouts.salary'),
            },
            {
              title: new Translation('routes.payouts.listPayments'),
            },
          ],
        },
        component: () => import('@/components/payouts/ListPayouts.vue'),
      },
      {
        path: 'executors',
        name: pageNames.payouts.PAYOUTS_EXECUTORS,
        meta: {
          title: new Translation('routes.payouts.executors'),
          seoTitle: new Translation('routes.payouts.seoExecutors'),
          breadcrumbs: [
            {
              title: new Translation('routes.payouts.salary'),
            },
            {
              title: new Translation('routes.payouts.executors'),
            },
          ],
        },
        component: () => import('@/components/payouts/ListExecutors.vue'),
        beforeEnter: requirePermission,
      },
    ],
  },
  {
    path: 'payout/details/:payoutId',
    name: pageNames.payouts.PAYOUT_DETAILS,
    meta: {
      title: new Translation('routes.payouts.details'),
      breadcrumbs: [
        {
          title: new Translation('routes.payouts.salary'),
          url: {
            name: pageNames.payouts.PAYOUTS,
          },
        },
        {
          title: new Translation('routes.payouts.payoutInfo'),
        },
      ],
    },
    component: () => import('@/pages/payouts/PayoutDetails.vue'),
  },
  {
    path: 'payouts/executor/:executorId',
    name: pageNames.payouts.PAYOUTS_EXECUTOR,
    meta: {
      title: new Translation('routes.payouts.userPayouts'),
      seoTitle: new Translation('routes.payouts.salary'),
      breadcrumbs: [
        {
          title: new Translation('routes.payouts.salary'),
          url: {
            name: pageNames.payouts.PAYOUTS,
          },
        },
        {
          title: new Translation('routes.payouts.userPayouts'),
        },
      ],
    },
    component: () => import('@/pages/payouts/PayoutsExecutor.vue'),
    beforeEnter: requirePermission,
  },
];
