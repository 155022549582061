import session from '@/api/session';

import type { User } from '@/types/types';

type TaskViewer = {
  count: number;
  user: User;
};

export default {
  getTaskViewers(taskId: number) {
    return session.get<Array<TaskViewer>>(`tasks/viewers/${taskId}/`);
  },
};
