export default {
  filters: {
    executor: 'Executor',
    responsible: 'Supervisor',
    timeTracked: 'Time tracking',
    view: 'View',
    status: 'Status',
    priority: 'Priority',
    stage: 'Stage',
    datasource: 'Database',
    tags: 'Tags',
    completed: 'Completed',
    dateCreated: 'Date created',
  },
};
