import comments from '@/store/modules/tasks/modules/comments';
import details from '@/store/modules/tasks/modules/details/details';
import files from '@/store/modules/tasks/modules/files';

const actions = {
  RESET({ commit }) {
    commit('comments/reset');
    commit('details/reset');
    commit('files/reset');
  },
};

export default {
  namespaced: true,

  modules: {
    details,
    files,
    comments,
  },

  actions,
};
