export default class Translation {
  public path: string;

  constructor(path: string) {
    this.path = path;
  }

  /**
   * Uses for detection of the class instance after cloning by {@link structuredClone}
   * {@link structuredClone} doesn't preserve prototypes and converts all custom classes to raw objects
   *
   * @param instance
   */
  static [Symbol.hasInstance](instance: unknown) {
    if (instance && typeof instance === 'object' && 'path' in instance) {
      return true;
    }
    return false;
  }
}
