export default {
  salary: 'Salary ',
  seoSalary: 'Salary - ',
  listPayments: 'List of payments',
  executors: 'Assignees',
  seoExecutors: 'Assignees - ',
  details: 'Details',
  payoutInfo: 'Payment information',
  userPayouts: 'Payouts to user',
};
