// @ts-ignore
import Inputmask from 'inputmask';
import type { App } from 'vue';

export const initMask = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    // Регистрирую глобальную директиву для маски на инпуте
    app.directive('mask', {
      beforeMount(el, binding) {
        if (binding.value) Inputmask(binding.value).mask(el);
      },
      unmounted(el) {
        if (el) {
          Inputmask.remove(el);
        }
      },
    });
    resolve();
  });
};
