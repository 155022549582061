import type { AxiosError, AxiosResponse } from 'axios';
import { z, ZodError } from 'zod';
import { createMock as _createMock } from 'zodock';

import RequestError from '@/errors/RequestError';

export default class ZodHelper {
  public static getPaginationModel<Schema extends z.ZodTypeAny>(schema: Schema) {
    return z.object({ count: z.number().optional(), num_pages: z.number(), results: schema });
  }

  public static async request<Schema extends z.ZodTypeAny>(promise: () => Promise<AxiosResponse>, schema: Schema) {
    try {
      const response = await promise();
      const parsedData = await schema.parseAsync(response.data);
      return { ...response, data: parsedData } as AxiosResponse<z.infer<typeof schema>>;
    } catch (e) {
      if (e instanceof ZodError) {
        throw e;
      }
      throw new RequestError(e as AxiosError);
    }
  }

  public static createMock<Schema extends z.ZodTypeAny>(schema: Schema) {
    if (import.meta.env.PROD) throw new Error('Mocks are not available in production');

    return _createMock(schema);
  }

  public static imitateApiRequest<Schema extends z.ZodTypeAny>(
    schema: Schema,
  ): Promise<AxiosResponse<z.infer<typeof schema>>> {
    if (import.meta.env.PROD) throw new Error('Request imitation is not available in production');

    return new Promise((resolve) =>
      resolve({
        status: 200,
        statusText: 'OK',
        headers: {},
        data: ZodHelper.createMock(schema),
        config: {},
      }),
    );
  }
}
