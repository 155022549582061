import AxiosHelper from '@/helpers/AxiosHelper';

const getRequestUrl = (defaultUrl: string, sharedUrl: string): string => {
  if (AxiosHelper.getHeaders('Shared')) {
    return sharedUrl;
  }
  return defaultUrl;
};

export default getRequestUrl;
