import routerHelper from '@/router/helper';
import Translation from '@/utils/translation';

export default [
  {
    path: 'directions',
    name: routerHelper.pageNames.finances.FINANCES_DIRECTIONS,
    component: () => import('@/pages/finances/Directions.vue'),
    meta: {
      title: new Translation('routes.finances.financesDirections'),
      breadcrumbs: [
        {
          title: new Translation('routes.finances.finances'),
        },
        {
          title: new Translation('routes.finances.directions'),
        },
      ],
    },
    props: (route) => ({ teamId: Number(route.params.teamId) }),
  },
];
