import type { App } from 'vue';

import i18n from '@/plugins/i18n';

import Translation from '@/utils/translation';

const translate = {
  install: (app: App) => {
    const translateHandler = (title: string | Translation) => {
      if (title instanceof Translation) {
        return i18n.t(title.path);
      }
      return title;
    };

    app.config.globalProperties.$translate = translateHandler;
  },
};

export const initTranslate = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.use(translate);
    resolve();
  });
};
