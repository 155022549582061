import session from '@/api/session';
import { type Tools, type Updates, updatesSettings } from '@/models/updates/UpdatesSettings';

import ZodHelper from '@/helpers/ZodHelper';

export default {
  getUpdatesSettings() {
    return ZodHelper.request(() => session.get('/notices/settings/get-current-settings/'), updatesSettings);
  },
  addTool(requestBody: { notification: Updates; tool: Tools }) {
    return session.post('/notices/settings/add-tool/', requestBody);
  },
  removeTool(requestBody: { notification: Updates; tool: Tools }) {
    return session.delete('/notices/settings/delete-tool/', { data: requestBody });
  },
};
