import listPermissions, {
  canCreateTasks,
  canDeleteTasks,
  canDeleteTeam,
  canManageTasks,
  canViewReports,
  canManageActivity,
  canCreateViews,
  canManageViews,
  canViewTasks,
  canCreateTaskComments,
} from '@/constants/permissions/listPermissions';
import { ADMIN, MANAGER, OWNER, USER, GUEST } from '@/constants/permissions/listRoles';

const permissions = {
  [OWNER]: [...listPermissions],
  [ADMIN]: [...listPermissions.filter((permission) => permission !== canDeleteTeam)],
  [MANAGER]: [
    canCreateTasks,
    canManageTasks,
    canDeleteTasks,
    canViewReports,
    canManageActivity,
    canCreateViews,
    canManageViews,
    canCreateTaskComments,
  ],
  [USER]: [canCreateTasks, canManageTasks, canViewTasks, canCreateTaskComments],
  [GUEST]: [canViewTasks],
};

export default permissions;
