export default {
  makeAsCover: 'Make a cover',
  deleteCover: 'Delete cover',
  select: 'Select',
  download: 'Download',
  delete: 'Delete',
  createCoverMessage: 'Cover successfully applied',
  deleteCoverMessage: 'Cover removed',
  coverBillingTitle: 'Covers',
};
