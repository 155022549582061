import session from '@/api/session';

export default {
  createFeedLike({ feedId, requestBody }) {
    return session.post(`feeds/like/${feedId}/create/`, requestBody);
  },
  deleteFeedLike({ likeId }) {
    return session.delete(`feeds/like/${likeId}/delete/`);
  },
};
