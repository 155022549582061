import session from '@/api/session';

export default {
  getBillingTariffs() {
    return session.get(`/billing/settings/plans/`);
  },
  getTeamBillingTariff(teamId) {
    return session.get(`/billing/settings/${teamId}/`);
  },
  createBillingPersonalOffer(requestBody) {
    return session.post(`/billing/payer/personal-offer/`, requestBody);
  },
  getTeamPayers(teamId) {
    return session.get(`/billing/payer/${teamId}/`);
  },
  createTeamPayer({ teamId, requestBody }) {
    return session.post(`/billing/payer/${teamId}/`, requestBody);
  },
  getBillingCardsList(teamId) {
    return session.get(`/billing/cards/${teamId}/`);
  },
  createBillingCard({ teamId, requestBody }) {
    return session.post(`/billing/cards/${teamId}/`, requestBody);
  },
  billingPaymentFinishCreate({ cardId, requestBody }) {
    return session.post(`/billing/payment/finish/${cardId}/`, requestBody);
  },
  getBillingCardInfo({ teamId, cardId }) {
    return session.get(`/billing/cards/${teamId}/${cardId}/`);
  },
  deleteBillingCard({ teamId, cardId }) {
    return session.delete(`/billing/cards/${teamId}/${cardId}/`);
  },
  getBillingInvoice({ teamId, invoiceId }) {
    return session.get(`/billing/invoice/${teamId}/${invoiceId}/`);
  },
  sendBillingInvoice({ teamId, invoiceId, requestBody }) {
    return session.post(`/billing/invoice/${teamId}/${invoiceId}/send/`, requestBody);
  },
  topUpBalance({ teamId, requestBody }) {
    return session.post(`/billing/payment/${teamId}/`, requestBody);
  },
  getSpacesList() {
    return session.get(`/billing/payer/spaces/list/`);
  },
  deleteBillingPayer({ teamId, payerId }) {
    return session.delete(`/billing/payer/${teamId}/${payerId}/`);
  },
  editBillingPayer({ teamId, payerId, requestBody }) {
    return session.put(`/billing/payer/${teamId}/${payerId}/`, requestBody);
  },
  editBillingSettings({ teamId, requestBody }) {
    return session.patch(`/billing/settings/${teamId}/`, requestBody);
  },
  getBillingPayer({ teamId, payerId }) {
    return session.get(`/billing/payer/${teamId}/${payerId}/`);
  },
  getBillingPayments({ teamId, getParams: params }) {
    return session.get(`/billing/transactions/${teamId}/`, { params });
  },
  checkPromoCode(promoCode) {
    return session.get(`billing/promocode/find/${promoCode}/`);
  },
  createStripeSessionId(requestBody) {
    return session.post(`billing/stripe/create-subscription-session/`, requestBody);
  },
};
