import apiTasks from '@/api/tasks';

import checkResponse from '@/utils/checkResponse';
import formatDate from '@/utils/date/formatDate';

import shareActions from './actions/share';

const root = { root: true };

const getMinutesPeriod = (minutes) => {
  if (minutes < 20) return '00';
  if (minutes >= 20 && minutes < 40) return '20';
  if (minutes >= 40) return '40';

  throw new Error('Количество минут с ошибкой');
};

const getPeriod = (hours, minutes) => {
  const minutesPeriod = getMinutesPeriod(minutes);

  const start = `${hours}:${minutesPeriod}`;

  const endMinutes = (minutesPeriod + 20) % 60;
  const end = `${+minutesPeriod === 40 ? hours + 1 : hours}:${endMinutes === 0 ? '00' : endMinutes}`;

  return {
    start,
    end,
  };
};

const createInitialState = () => ({
  task: null,
  history: null,
  screenshots: null,
  maxPages: null,
});

const state = createInitialState();

const getters = {
  // Лучше избегать обращения к роутеру из стора, но в данном случае так будет оптимальнее,
  // так как дальше идет более сложное манипулирование данными
  screenshotsFilteredByExecutor({ screenshots }, _skip, rootState) {
    const { filterExecutor } = rootState.route.query;
    if (filterExecutor && filterExecutor !== 'all') {
      return screenshots.filter((screenshot) => Number(screenshot.user) === Number(filterExecutor));
    }
    return screenshots;
  },
  screenshotsByDateAndExecutor(_skip, { screenshotsFilteredByExecutor }) {
    if (!screenshotsFilteredByExecutor) return {};

    return screenshotsFilteredByExecutor.reduce((groups, screenshot) => {
      const date = formatDate(screenshot.date_range, 'yyyy-MM-dd');

      if (groups[date]) {
        if (groups[date][screenshot.user]) {
          groups[date][screenshot.user].push(screenshot);
        } else {
          groups[date][screenshot.user] = [screenshot];
        }
      } else {
        groups[date] = {
          [screenshot.user]: [screenshot],
        };
      }

      return groups;
    }, {});
  },
  screenshotsByDatetime(_skip, { screenshotsByDateAndExecutor }) {
    return Object.entries(screenshotsByDateAndExecutor).map(([date, groupByDate]) => {
      const groupByExecutorAndHours = Object.entries(groupByDate).map(([userId, screenshots]) =>
        screenshots.reduce(({ groupsByTime = {} }, screenshot) => {
          const time = new Date(screenshot.date_range);
          const hours = time.getHours();
          const minutes = time.getMinutes();

          const period = getPeriod(hours, minutes);

          if (groupsByTime[hours]) {
            if (groupsByTime[hours][period.start]) {
              groupsByTime[hours][period.start].push({ ...screenshot, period });
            } else {
              groupsByTime[hours][period.start] = [{ ...screenshot, period }];
            }
          } else {
            groupsByTime[hours] = {};
            groupsByTime[hours][period.start] = [{ ...screenshot, period }];
          }

          return {
            userId,
            groupsByTime,
          };
        }, {}),
      );

      return {
        date,
        groupsByUser: groupByExecutorAndHours,
      };
    });
  },
};

const mutations = {
  setTaskWithDetails(state, task) {
    state.task = task;
  },
  setScreenshots(state, screenshots) {
    state.screenshots = screenshots;
  },
  setMaxPages(state, maxPages) {
    state.maxPages = maxPages;
  },
  addScreenshots(state, screenshots) {
    state.screenshots = state.screenshots.concat(screenshots);
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  async GET_TASK_DETAILS({ commit }, requestData) {
    try {
      const { data: task } = await apiTasks.getTaskDetails(requestData);
      commit('setTaskWithDetails', task);
      return task;
    } catch (e) {
      console.error('Ошибка при получении детальной информации о задаче');
      checkResponse(e);
      throw e;
    }
  },
  async GET_TASK_SCREENSHOTS({ commit }, requestData) {
    try {
      commit('loading/startLoading', 'screenshots', root);
      const {
        data: { results: screenshots, num_pages: maxPages },
      } = await apiTasks.getTaskScreenshots(requestData);

      requestData.getParams.page === 1 ? commit('setScreenshots', screenshots) : commit('addScreenshots', screenshots);

      commit('setMaxPages', maxPages);
    } catch (e) {
      console.error('Ошибка при получении скриншотов задачи');
      checkResponse(e);
      throw e;
    } finally {
      commit('loading/finishLoading', 'screenshots', root);
    }
  },

  ...shareActions,
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
