export default {
  back: 'Back',
  taskRepeat: 'Task repeat',

  howOften: 'How often',

  every: 'Every',
  day: 'day',
  week: 'weeks on',
  month: 'month',
  year: 'year/years',
  period: 'days after completion',

  time: 'Time',
  h: 'h',
  m: 'm',

  start: 'Start',
  lastDayOfMonth: 'Last day of the month',
  nextRepeat: 'Next repeat: {date}',

  save: 'Save',
};
