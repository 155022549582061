import type { MessageContext } from 'vue-i18n';

export default {
  dueDate: {
    types: {
      firstDayOfTheWeek: 'First day of the week',
      lastDayOfTheWeek: 'Last day of the week',
      firstDayOfTheMonth: 'First day of the month',
      lastDayOfTheMonth: 'Last day of the month',
      afterOneDay: 'After 1 day',
      afterThreeDays: 'After 3 days',
      afterSevenDays: 'After 7 days',
    },
    tabs: {
      dayOfWeek: 'Day of week',
      date: 'Date',
    },
    start: 'Start',
    time: 'Time',
    end: 'End',
    dateOfMonth: 'Nearest {date} day of the month',
    save: 'Save',
    add: 'Add',
    change: 'Change',
    setManually: 'Set manually',

    startByWeek: ({ named, linked }: MessageContext) => {
      const weekTitle = linked(`plural.everyWeekDayShort[${named('week')}]`);

      return `Start: ${weekTitle} of the week at ${named('time')}`;
    },
    endByWeek: ({ named, linked }: MessageContext) => {
      const weekTitle = linked(`plural.everyWeekDayShort[${named('week')}]`);

      return `End: ${weekTitle} of the week at ${named('time')}`;
    },
    startByMonth: 'Start: {day} day of the month at {time}',
    endByMonth: 'End: {day} day of the month at {time}',
  },
};
