import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canManageTeam } from '@/constants/permissions/listPermissions';

const requirePermission = createPermissionHook(canManageTeam, { name: pageNames.errors.PAGE_NO_ACCESS });

export default [
  {
    path: 'tariff/balance',
    name: pageNames.teams.TEAM_SETTINGS_TARIFF_BALANCE,
    meta: {
      title: new Translation('routes.billing.topUpBalance'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.tariff'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_TARIFF,
          },
        },
        {
          title: new Translation('routes.billing.topUpBalance'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_TARIFF_BALANCE,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/TopUpBalance.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'tariff/tariffs',
    name: pageNames.teams.TEAM_SETTINGS_TARIFF_TARIFFS,
    meta: {
      title: new Translation('routes.billing.tariffs'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.billing.tariffs'),
        },
      ],
    },
    component: () => import('@/components/billing/tariff/Tariffs.vue'),
    beforeEnter: requirePermission,
  },
  {
    path: 'tariff/invoice/:invoiceId',
    name: pageNames.teams.TEAM_SETTINGS_TARIFF_INVOICE,
    meta: {
      title: new Translation('routes.billing.invoice'),
      breadcrumbs: [
        {
          title: new Translation('routes.teamSettings.team'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS,
          },
        },
        {
          title: new Translation('routes.teamSettings.tariff'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_TARIFF,
          },
        },
        {
          title: new Translation('routes.billing.invoice'),
          url: {
            name: pageNames.teams.TEAM_SETTINGS_TARIFF_INVOICE,
          },
        },
      ],
    },
    component: () => import('@/pages/billing/Invoice.vue'),
    beforeEnter: requirePermission,
  },
];
