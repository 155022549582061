import { z } from 'zod';

import { parseISO } from 'date-fns';

import { currencyModel } from './Currency';
import { status } from './Status';
import { user } from './User';
import { taskChecklist } from './tasks/TaskChecklist';
import { taskFile } from './tasks/TaskFile';
import { teamTag } from './teams/TeamTag';

export enum UpdateTypes {
  TeamInvite = 'team_invite',
  System = 'system_notice',
  UserDeleteAccount = 'user_delete_account',
  TeamBlockedUser = 'team_blocked_user',
  TeamMember = 'team_member',
  TaskAssign = 'task_assign_executor',
  TaskUpdate = 'task_change',
  PayoutNew = 'payout_new',
  TaskMention = 'task_mention_user',
  TaskResponsible = 'task_assign_responsible',
  TaskCommentAnswer = 'task_comment_answer',
  FeedCreate = 'feed_create',
  FeedTag = 'feed_tag',
  FeedCommentCreate = 'feed_comment_create',
  FeedCommentReply = 'feed_comment_reply',
  NewComment = 'new_comment',
}

const _updateTeamBase = z.object({ id: z.number(), name: z.string() });
const _updateTaskBase = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  slug: z.string().optional(),
});
const _updateBase = z.object({
  id: z.number(),
  is_read: z.boolean(),
  is_deleted: z.boolean(),
  user,
  uuid: z.string(),
  created_at: z.string().transform((date) => parseISO(date)),
});
const _updateUser = user.omit({ tracking: true });
const _taskUpdateComment = z
  .object({
    id: z.number(),
    task: z.number(),
    files: taskFile.array(),
    author: _updateUser,
    comment: z.string(),
    answer_to: z.object({ comment: z.string(), author: _updateUser }).nullable(),
  })
  .transform(({ comment, answer_to, ...rest }) => ({ text: comment, answerTo: answer_to, ...rest }));

const teamInvite = _updateBase.extend({
  type: z.literal(UpdateTypes.TeamInvite),
  data: z.object({
    team: _updateTeamBase,
    token: z.string(),
    status: z.string(),
    initiator: _updateUser,
  }),
});
const userDeleteAccount = _updateBase.extend({
  type: z.literal(UpdateTypes.UserDeleteAccount),
  data: z.object({ member: _updateUser }),
});
const teamBlockedUser = _updateBase.extend({
  type: z.literal(UpdateTypes.TeamBlockedUser),
  data: z.object({ team: _updateTeamBase }),
});
const teamMember = _updateBase.extend({
  type: z.literal(UpdateTypes.TeamMember),
  data: z.object({ initiator: _updateUser, members: _updateUser.array(), team: _updateTeamBase }),
});
const system = _updateBase.extend({
  type: z.literal(UpdateTypes.System),
  data: z
    .object({
      text: z.string(),
      title: z.string(),
      text_en: z.string(),
      title_en: z.string(),
    })
    .transform(({ text_en, title_en, ...rest }) => ({ textEn: text_en, titleEn: title_en, ...rest })),
});
const payoutNew = _updateBase.extend({
  type: z.literal(UpdateTypes.PayoutNew),
  data: z
    .object({ team: _updateTeamBase, number: z.number(), id: z.number(), get_sum_without_commission: z.number() })
    .transform(({ get_sum_without_commission, ...rest }) => ({
      sumWithoutCommission: get_sum_without_commission,
      ...rest,
    })),
});
const feedCreate = _updateBase.extend({
  type: z.literal(UpdateTypes.FeedCreate),
  data: z.object({
    team: _updateTeamBase,
    initiator: _updateUser,
    feed: z.object({ id: z.number() }),
  }),
});
const feedTag = _updateBase.extend({
  type: z.literal(UpdateTypes.FeedTag),
  data: z.object({
    team: _updateTeamBase,
    initiator: _updateUser,
    feed: z.object({ id: z.number() }),
  }),
});
const feedCommentCreate = _updateBase.extend({
  type: z.literal(UpdateTypes.FeedCommentCreate),
  data: z
    .object({
      team: _updateTeamBase.optional(),
      initiator: _updateUser,
      feed: z
        .object({ id: z.number(), description: z.object({ text: z.string() }) })
        .transform(({ description, ...rest }) => ({ ...rest, description: description.text })),
      feed_comment: z
        .object({ id: z.number(), comment_text: z.string() })
        .transform(({ comment_text, ...rest }) => ({ text: comment_text, ...rest })),
    })
    .transform(({ feed_comment, ...rest }) => ({ comment: feed_comment, ...rest })),
});
const feedCommentReply = feedCommentCreate.merge(z.object({ type: z.literal(UpdateTypes.FeedCommentReply) }));
const taskAssign = _updateBase.extend({
  type: z.literal(UpdateTypes.TaskAssign),
  data: z.object({ team: _updateTeamBase, initiator: _updateUser, task: _updateTaskBase }),
});
const taskResponsible = _updateBase.extend({
  type: z.literal(UpdateTypes.TaskResponsible),
  data: z.object({ team: _updateTeamBase, initiator: _updateUser, task: _updateTaskBase }),
});
const taskMention = _updateBase.extend({
  type: z.literal(UpdateTypes.TaskMention),
  data: z.object({
    team: _updateTeamBase,
    initiator: _updateUser,
    task: _updateTaskBase,
    text: z.string(),
    where: z.enum(['description', 'comment']),
    comment: z.object({ id: z.number() }).optional(),
  }),
});
const taskCommentAnswer = _updateBase.extend({
  type: z.literal(UpdateTypes.TaskCommentAnswer),
  data: z.object({
    team: _updateTeamBase,
    task: _updateTaskBase,
    comment: _taskUpdateComment,
  }),
});

const _taskUpdateStatus = status.omit({ id: true });
const _taskUpdateStage = z.object({ color: z.string(), title: z.string() });
const _taskUpdateDueDate = z.object({
  deadline: z.string().nullable(),
  date_start: z.string().nullable(),
  deadline_time: z.string().nullable(),
  date_start_time: z.string().nullable(),
});
const _taskUpdateSubtask = z
  .object({ subtask_name: z.string() })
  .transform(({ subtask_name, ...rest }) => ({ name: subtask_name, ...rest }));
const _taskUpdateCost = z.object({
  cost: z.string().nullable(),
  currency_value: currencyModel,
});
const _taskUpdateChecklist = taskChecklist
  .innerType()
  .pick({ text: true, resolved: true })
  .transform(({ resolved, ...rest }) => ({ isResolved: resolved, ...rest }));

const taskUpdate = _updateBase.extend({
  type: z.literal(UpdateTypes.TaskUpdate),
  data: z
    .object({
      team: _updateTeamBase.optional(),
      initiator: _updateUser,
      task: _updateTaskBase.optional(),
      deadline: z.object({ new: z.string(), old: z.string() }).optional(),
      executor: z.object({ new: _updateUser.nullable(), old: _updateUser.nullable() }).optional(),
      responsible: z.object({ new: _updateUser.nullable(), old: _updateUser.nullable() }).optional(),
      status: z
        .object({
          new: _taskUpdateStatus.or(z.string()).nullable(),
          old: _taskUpdateStatus.or(z.string()).nullable(),
        })
        .optional(),
      task_label: z.object({ new: teamTag.nullable(), old: teamTag.nullable() }).optional(),
      name: z.object({ new: z.string(), old: z.string() }).optional(),
      priority: z.object({ new: z.string(), old: z.string() }).optional(),
      description: z.object({ new: z.string().catch(''), old: z.string().catch('') }).optional(),
      planned_time_minutes: z.object({ new: z.coerce.number(), old: z.coerce.number() }).optional(),
      stage: z.object({ new: _taskUpdateStage.nullable(), old: _taskUpdateStage.nullable() }).optional(),
      due_date: z.object({ new: _taskUpdateDueDate, old: _taskUpdateDueDate }).optional(),
      cost_and_currency: z.object({ new: _taskUpdateCost, old: _taskUpdateCost }).optional(),
      subtask: z.object({ new: _taskUpdateSubtask.nullable(), old: _taskUpdateSubtask.nullable() }).optional(),
      check_list: z.object({ new: _taskUpdateChecklist.nullable(), old: _taskUpdateChecklist.nullable() }).optional(),
      check_list__resolved: z
        .object({ new: _taskUpdateChecklist.nullable(), old: _taskUpdateChecklist.nullable() })
        .optional(),
      check_list__text: z
        .object({ new: _taskUpdateChecklist.nullable(), old: _taskUpdateChecklist.nullable() })
        .optional(),
      file: z.object({ new: taskFile.nullable(), old: taskFile.nullable() }).optional(),
      comment: z.object({ new: _taskUpdateComment.nullable(), old: _taskUpdateComment.nullable() }).optional(),
      executor_finished: z
        .object({ new: z.object({ finished: z.string() }), old: z.object({ finished: z.string() }) })
        .transform((value) => ({
          new: value.new.finished === 'true',
          old: value.old.finished === 'true',
        }))
        .optional(),
      responsible_is_confirmed_closing: z
        .object({
          new: z.object({ is_confirmed_closing: z.string() }),
          old: z.object({ is_confirmed_closing: z.string() }),
        })
        .transform((value) => ({
          new: value.new.is_confirmed_closing === 'true',
          old: value.old.is_confirmed_closing === 'true',
        }))
        .optional(),
      subtasks_finished: z
        .object({
          new: z.boolean(),
          old: z.boolean(),
        })
        .optional(),
    })
    .transform(
      ({
        task_label,
        planned_time_minutes,
        due_date,
        cost_and_currency,
        check_list,
        check_list__resolved,
        check_list__text,
        executor_finished,
        responsible_is_confirmed_closing,
        ...rest
      }) => ({
        tags: task_label,
        duration: planned_time_minutes,
        dueDate: due_date,
        cost: cost_and_currency,
        checklist: check_list,
        checklistResolved: check_list__resolved,
        checklistText: check_list__text,
        executorCompleted: executor_finished,
        responsibleConfirmed: responsible_is_confirmed_closing,
        ...rest,
      }),
    ),
});

const newComment = _updateBase.extend({
  type: z.literal(UpdateTypes.NewComment),
  data: z.object({
    comment: _taskUpdateComment,
    task: _updateTaskBase,
    team: _updateTeamBase,
  }),
});

export const update = z
  .discriminatedUnion('type', [
    teamMember,
    teamBlockedUser,
    userDeleteAccount,
    teamInvite,
    system,
    taskAssign,
    taskUpdate,
    payoutNew,
    taskMention,
    taskResponsible,
    taskCommentAnswer,
    feedCreate,
    feedTag,
    feedCommentCreate,
    feedCommentReply,
    newComment,
  ])
  .transform(({ is_read, is_deleted, created_at, ...rest }) => ({
    isRead: is_read,
    isDeleted: is_deleted,
    createdAt: created_at,
    ...rest,
  }));
export type Update = z.infer<typeof update>;
