import i18n from '@/plugins/i18n';

import apiFeeds from '@/api/feeds';

import { BillingHelper } from '@/utils/BillingHelper';

import { maxFileSizeAtFreePlans } from '@/constants/billing/maxFileSizeAtFreePlans';

const root = { root: true };

const state = {
  files: [],
  loadProgress: [],
};

const mutations = {
  setFiles(state, files) {
    state.files = files;
  },
  addFile(state, file) {
    state.files = [...state.files, file];
  },
  deleteFile(state, fileId) {
    state.files = state.files.filter((file) => file.id !== fileId);
  },

  setLoadProgress(state, progress) {
    const item = state.loadProgress.find((item) => item.name === progress.name);

    if (item) {
      if (progress.count < 95) item.count = progress.count;

      if (progress.isFinish) {
        const index = state.loadProgress.findIndex((item) => item.name === progress.name);
        state.loadProgress.splice(index, 1);
      }
    } else if (progress.count < 100) {
      state.loadProgress.push(progress);
    }
  },

  removeLoadProgress(state, name) {
    state.loadProgress = state.loadProgress.filter((progress) => progress.name !== name);
  },

  reset(state) {
    state.files = [];
  },
};

const actions = {
  async ADD_FILE({ commit, rootState, dispatch, rootGetters }, { requestData, feedId }) {
    try {
      if (!BillingHelper.isFileSizeValid(requestData.fd.get('file'), rootGetters['billing/planType'])) {
        dispatch(
          'notifications/SHOW_ERROR',
          i18n.t('notifications.fileUploadLimit', { mbSize: Math.floor(maxFileSizeAtFreePlans / 1000000) }),
          root,
        );
        return;
      }

      const { data: file } = await apiFeeds.addFeedFile(requestData);
      // eslint-disable-next-line no-negated-condition
      if (!feedId) {
        commit('addFile', file);
      } else {
        const { feeds = {} } = rootState;
        const feed = feeds.feeds.find((it) => it.id === feedId);

        if (!feed.feedTempFiles) feed.feedTempFiles = [];

        commit('feeds/updateFeed', { ...feed, feedTempFiles: [...feed.feedTempFiles, file] }, root);
      }

      if (requestData.config && requestData.config.name) {
        commit('setLoadProgress', { name: requestData.config.name, count: 100, isFinish: true });
      }
      return file;
    } catch (e) {
      commit('removeLoadProgress', requestData.config.name);
      console.error(e);
      throw e;
    }
  },

  async DELETE_FILE({ state, commit, rootState }, requestData) {
    const filesFallback = [...state.files];
    const { fileId } = requestData;
    try {
      if (!requestData.feedId) {
        commit('deleteFile', requestData.fileId);
      }

      if (!requestData.feedId) return;
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === requestData.feedId);
      const { feedTempFiles = null, feed_files: feedFile } = feed;

      if (feedTempFiles && feedTempFiles.find((file) => file.id === fileId)) {
        commit(
          'feeds/updateFeed',
          {
            ...feed,
            feedTempFiles: feedTempFiles.filter((file) => file.id !== fileId),
          },
          root,
        );

        return;
      }
      await apiFeeds.deleteFeedFile(requestData);

      commit(
        'feeds/updateFeed',
        {
          ...feed,
          feed_files: feedFile.filter((file) => file.id !== fileId),
        },
        root,
      );
    } catch (e) {
      console.error('Ошибка при удалении файла', e);
      commit('setFiles', filesFallback);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
