import { z } from 'zod';

import session from '@/api/session';
import { integrationDetail, teamIntegration, type IntegrationCodes } from '@/models/teams/TeamIntegration';

import ZodHelper from '@/helpers/ZodHelper';

type CreatIntegration = {
  teamId: number;
  requestBody: {
    integration_name: string;
    integration_type: IntegrationCodes;
    team: number;
  };
};

type GetIntegration = {
  teamId: number;
  integrationId: number;
};

type GetIntegrationList = {
  teamId: number;
  getParams: {
    search?: string;
    page: number;
  };
};

type UpdateIntegration = GetIntegration & {
  requestBody: {
    integration_name?: string;
    is_active?: boolean;
  };
};

type ReconnectIntegration = GetIntegration & { requestBody: { accounts_to_change: Record<number, {}> } };

export default {
  getIntegrationsList({ teamId, getParams }: GetIntegrationList) {
    return ZodHelper.request(
      () => session.get(`integrations/${teamId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(z.array(teamIntegration)),
    );
  },
  getIntegrationDetails({ teamId, integrationId }: GetIntegration) {
    return ZodHelper.request(() => session.get(`integrations/${teamId}/${integrationId}/`), integrationDetail);
  },
  createIntegration({ teamId, requestBody }: CreatIntegration) {
    return session.post(`integrations/${teamId}/`, requestBody);
  },
  updateIntegration({ teamId, integrationId, requestBody }: UpdateIntegration) {
    return session.patch(`integrations/${teamId}/${integrationId}/`, requestBody);
  },
  reconnectAccounts({ teamId, integrationId, requestBody }: ReconnectIntegration) {
    return session.post(`integrations/${teamId}/${integrationId}/change-accounts/`, requestBody);
  },
  deleteIntegration({ teamId, integrationId }: GetIntegration) {
    return session.delete(`integrations/${teamId}/${integrationId}/`);
  },
};
