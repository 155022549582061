import session from '@/api/session';
import { datasourceStage } from '@/models/datasource/DatasourceStage';

import ZodHelper from '@/helpers/ZodHelper';

import getRequestUrl from '../utils/getRequestUrl';

import type { Nullable } from '@/types/utility';

type GetStages = {
  teamId: number;
  viewId?: number;
  datasourceId: number;
  getParams?: {
    search?: string;
  };
};
type GetStagesPaginated = GetStages & {
  getParams: {
    page: number;
  };
};

type GetAllInOneStages = Omit<GetStages, 'datasourceId' | 'viewId'>;
type GetAllInOneStagesPaginated = Omit<GetStagesPaginated, 'datasourceId' | 'viewId'>;

type CreateStage = Omit<GetStagesPaginated, 'getParams' | 'viewId'> & {
  requestBody: {
    name?: string;
    datasource?: number;
    color?: string;
    date_from?: string;
    date_until?: string;
    hours?: number;
    comment?: string;
    prev_value?: Nullable<number>;
  };
};

type EditStage = CreateStage & {
  stageId: number;
};

type DeleteStage = Omit<EditStage, 'requestBody'>;

export const stages = {
  getStages({ teamId, viewId, datasourceId, getParams }: GetStages) {
    const sharedUrl = `shared/views/${teamId}/${viewId}/stages/`;
    const defaultUrl = `datasource/stages/${teamId}/${datasourceId}/no_paginated/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams }),
      datasourceStage.array(),
    );
  },
  getStagesPaginated({ teamId, viewId, datasourceId, getParams }: GetStagesPaginated) {
    const sharedUrl = `shared/views/${teamId}/${viewId}/stages/paginated/`;
    const defaultUrl = `datasource/stages/${teamId}/${datasourceId}/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams }),
      ZodHelper.getPaginationModel(datasourceStage.array()),
    );
  },

  getAllInOneStages({ teamId, getParams }: GetAllInOneStages) {
    return ZodHelper.request(
      () => session.get(`datasource/stages/${teamId}/all-in-one/no_paginated/`, { params: getParams }),
      datasourceStage.array(),
    );
  },
  getAllInOneStagesPaginated({ teamId, getParams }: GetAllInOneStagesPaginated) {
    return ZodHelper.request(
      () => session.get(`datasource/stages/${teamId}/all-in-one/`, { params: getParams }),
      ZodHelper.getPaginationModel(datasourceStage.array()),
    );
  },

  createStage({ teamId, datasourceId, requestBody }: CreateStage) {
    return ZodHelper.request(
      () => session.post(`datasource/stages/${teamId}/${datasourceId}/`, requestBody),
      datasourceStage,
    );
  },

  editStage({ teamId, datasourceId, stageId, requestBody }: EditStage) {
    return ZodHelper.request(
      () => session.patch(`datasource/stages/${teamId}/${datasourceId}/${stageId}/`, requestBody),
      datasourceStage,
    );
  },

  deleteStage({ teamId, datasourceId, stageId }: DeleteStage) {
    return session.delete(`datasource/stages/${teamId}/${datasourceId}/${stageId}/`);
  },
};
