import session from '@/api/session';

enum Periods {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  Custom = 'custom',
}

export type TaskRepeat = {
  id?: number;
  start_schedule?: Date | string;
  period?: Periods;
  day_time?: string;
  weeks_days?: Array<number>;
  month_day?: Date | string;
  interval: string;
  task?: number;
  task_template?: number;
  datasourceId?: number;
  last_day_of_month?: boolean;
  run_time?: string | null;
};

export interface EditTaskRepeat {
  teamId: number;
  repeatId?: number;
  requestBody: TaskRepeat;
}

export type DeleteTaskRepeat = Omit<EditTaskRepeat, 'requestBody'> & {
  requestBody: {
    task?: number;
    task_template: number;
    datasourceId: number;
  };
};

export default {
  getTaskRepeat(repeatId: number, teamId: number) {
    return session.get(`/tasks/task-schedule/${teamId}/${repeatId}/`);
  },
  createTaskRepeat({ teamId, requestBody }: { teamId: number; requestBody: TaskRepeat }) {
    return session.post(`/tasks/task-schedule/${teamId}/`, requestBody);
  },
  editTaskRepeat({ repeatId, teamId, requestBody }: EditTaskRepeat) {
    return session.patch(`/tasks/task-schedule/${teamId}/${repeatId}/`, requestBody);
  },
  deleteTaskRepeat({ teamId, repeatId, requestBody }: DeleteTaskRepeat) {
    return session.delete(`/tasks/task-schedule/${teamId}/${repeatId}/`, { data: requestBody });
  },
};
