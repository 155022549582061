import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';
import { subtask } from '@/models/tasks/Subtask';

import ZodHelper from '@/helpers/ZodHelper';

export default {
  getSubtasks({ taskId, getParams }: { taskId: number; getParams: { archived: boolean } }) {
    const defaultUrl = `tasks/detail/${taskId}/subtasks/`;
    const sharedUrl = `shared/tasks/subtasks/${taskId}/list/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams }),
      subtask.array(),
    );
  },
  duplicateSubtask(taskId: number, getParams: { gantt: boolean }) {
    return session.post('tasks/task-clone/', { task: taskId }, { params: getParams });
  },
};
