import session from '@/api/session';

export interface ArchivedTasksEditData {
  teamId: number;
  requestBody: {
    ids: Array<number | string>;
    team: number;
    action: 'restore' | 'delete';
  };
}

export default {
  editArchivedTasks({ requestBody, teamId }: ArchivedTasksEditData) {
    return session.post(`/tasks/list/archived/mass-actions/${teamId}/`, requestBody);
  },
};
