export default {
  renameFile: {
    title: 'Rename',
    placeholder: 'Enter your new file name',
    ok: 'OK',
  },
  addFile: {
    upload: 'Upload files',
    drag: 'or drag them here',
  },
};
