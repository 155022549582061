import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

export default [
  {
    path: 'counterparts',
    name: pageNames.finances.FINANCES_COUNTERPARTS,
    component: () => import('@/pages/finances/counterparts/CounterpartsList.vue'),
    meta: {
      title: new Translation('routes.finances.financesCounterparts'),
      breadcrumbs: [
        {
          title: new Translation('routes.finances.finances'),
        },
        {
          title: new Translation('routes.finances.counterparts'),
        },
      ],
    },
    props: (route) => ({ teamId: Number(route.params.teamId) }),
  },
  {
    path: 'counterparts/add',
    name: pageNames.finances.FINANCES_ADD_COUNTERPART,
    component: () => import('@/pages/finances/counterparts/AddCounterpart.vue'),
    meta: {
      title: new Translation('routes.finances.financesCounterparts'),
      breadcrumbs: [
        {
          title: new Translation('routes.finances.finances'),
        },
        {
          title: new Translation('routes.finances.counterparts'),
        },
      ],
    },
  },
  {
    path: 'counterparts/edit/:counterpartId',
    name: pageNames.finances.FINANCES_EDIT_COUNTERPART,
    component: () => import('@/pages/finances/counterparts/EditCounterpart.vue'),
    meta: {
      title: new Translation('routes.finances.financesCounterparts'),
      breadcrumbs: [
        {
          title: new Translation('routes.finances.finances'),
        },
        {
          title: new Translation('routes.finances.counterparts'),
        },
      ],
    },
  },
];
