import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';

import { canViewReports } from '@/constants/permissions/listPermissions';

import consolidated from './consolidated';

const requirePermission = createPermissionHook(canViewReports, { name: pageNames.errors.PAGE_NO_ACCESS });

const reports = [
  {
    path: 'reports',
    name: pageNames.reports.REPORTS,
    redirect: { name: pageNames.reports.REPORT_CONSOLIDATED },
    children: [...consolidated],
    component: () => import('@/pages/reports/Reports.vue'),
    beforeEnter: requirePermission,
  },
];

export default reports;
