export default {
  preferences: {
    delete: 'Delete',
    template: 'Template',

    confirm: {
      title: 'Are you sure you want to delete the selected templates?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },

    notification: 'Template group has been deleted',
    error: 'Error deleting templates',
  },
  list: {
    name: 'Name',
    executor: 'Assignee',
    repeat: 'Repeat',
    noRepeat: 'not repeat',
  },
  noData: {
    title: 'You have no templates for now',
    createTemplate: 'Create template',
  },
  actions: {
    confirm: {
      title: 'Delete template',
      description: 'Are you sure you want to delete the template?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
    notifications: {
      delete: 'Template {name} has been deleted',
      link: 'Link copied',
    },
    errors: {
      delete: 'Error deleting the template',
      task: 'Error creating task from the templates page',
      template: 'Error creating template from the templates page',
    },
  },
};
