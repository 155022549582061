import routerHelper from '@/router/helper';
import Translation from '@/utils/translation';

export default [
  {
    path: 'articles',
    name: routerHelper.pageNames.finances.FINANCES_ARTICLES,
    component: () => import('@/pages/finances/Articles.vue'),
    meta: {
      title: new Translation('routes.finances.financesArticles'),
      breadcrumbs: [
        {
          title: new Translation('routes.finances.finances'),
        },
        {
          title: new Translation('routes.finances.articles'),
        },
      ],
    },
    props: (route) => ({ teamId: Number(route.params.teamId) }),
  },
];
