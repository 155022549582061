import z from 'zod';

export const cardLink = z.object({
  blocked_task: z.number(),
  blocking_task: z.number(),
  connection_type: z.string(),
  id: z.number(),
});

export type CardLink = z.infer<typeof cardLink>;
