/**
 * @deprecated
 * These translations are for compatibility with the old deprecated statuses.
 */
import styles from '@/styles/_variables.module.scss';

export default {
  opened: 'Opened',
  inProgress: 'In progress',
  onCheck: 'In review',
  closed: 'Closed',
  noData: 'There is no such status.',
  clickToCreate: `Click on button or <kbd style="background-color: ${styles.colorGreyLighter}">↵ Enter</kbd> to create.`,
  create: 'Create',
  cancel: 'Cancel',
};
