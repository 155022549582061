import apiTasks from '@/api/tasks';

const shareActions = {
  async CREATE_TASK_SHARED_TOKEN(_skip, taskId) {
    try {
      const {
        data: { 'public-token': token },
      } = await apiTasks.createTaskSharedToken(taskId);

      return token;
    } catch (e) {
      console.error('Ошибка при создании токена задачи');
      throw e;
    }
  },
  async DELETE_TASK_SHARED_TOKEN(_skip, taskId) {
    try {
      await apiTasks.deleteTaskSharedToken(taskId);
    } catch (e) {
      console.error('Ошибка при удалении токена задачи');
      throw e;
    }
  },
};

export default shareActions;
