import actions from '@/locales/en/constants/billing/actions';
import errors from '@/locales/en/constants/billing/errors';
import finances from '@/locales/en/constants/billing/finances';
import financesActions from '@/locales/en/constants/billing/financesActions';
import financesTabs from '@/locales/en/constants/billing/financesTabs';
import payerTypes from '@/locales/en/constants/billing/payerTypes';
import paymentTypes from '@/locales/en/constants/billing/paymentTypes';
import topUpTypes from '@/locales/en/constants/billing/topUpTypes';

export default { paymentTypes, payerTypes, actions, financesActions, financesTabs, finances, errors, topUpTypes };
