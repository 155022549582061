import session from '@/api/session';
import { taskTemplateExecutor } from '@/models/tasks/TaskTemplate';

import ZodHelper from '@/helpers/ZodHelper';

type DefaultParams = {
  datasourceId: number;
  templateId: number;
  executorId: number;
};
type CreateParams = DefaultParams & {
  requestBody: {
    executor: number;
    task_template: number;
  };
};
export const apiTaskTemplateExecutor = {
  createTemplateExecutor({ datasourceId, templateId, executorId, requestBody }: CreateParams) {
    return ZodHelper.request(
      () => session.post(`tasks/task-templates/${datasourceId}/executor/${templateId}/${executorId}/`, requestBody),
      taskTemplateExecutor,
    );
  },
  deleteTemplateExecutor({ datasourceId, templateId, executorId }: DefaultParams) {
    return session.delete(`tasks/task-templates/${datasourceId}/executor/${templateId}/${executorId}/`);
  },
};
