import type { App } from 'vue';
import { VueQueryPlugin, type VueQueryPluginOptions } from 'vue-query';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        notifyOnChangeProps: 'tracked',
        retry: import.meta.env.PROD ? 3 : false,
      },
    },
  },
};

export const initVueQuery = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.use(VueQueryPlugin, vueQueryPluginOptions);
    resolve();
  });
};
