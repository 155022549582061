export default {
  projects: {
    actual: 'Active',
    archive: 'Archive',
  },
  tasks: {
    tasks: 'Tasks',
    stages: 'Stages',
    archive: 'Archive',
    templates: 'Templates',

    settings: 'Settings',
  },
};
