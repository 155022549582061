import variables from '@/styles/_variables.module.scss';

export default {
  ready: 'Ready!',
  teamCreated: `<span style="color: ${variables.colorPrimary}">{team}</span> team created`,
  invitedEmployees: 'You have invited {count}.',
  inviteMore: 'Invite more',
  tariff: 'Plan',
  startWork: 'Start work',
};
