export default {
  telegram: {
    title: 'Telegram',

    link: 'Go to chat',
    linked: 'Linked',
  },
  integrations: {
    title: 'Data import',
    description: 'Choose a service for data import',
    success: 'Migration completed',
    modals: {
      poor: {
        importFromTrello: 'Import from Trello is only available on paid plans',
        importFromJira: 'Import from Jira is only available on paid plans',
        importFromAsana: 'Import from Asana is only available on paid plans',
        changeTariff: 'Change tariff',
      },
      trello: {
        stages: {
          stage1: {
            title: 'Step 1. Authorization and token',
            desc: 'You need to log in to Trello, during the authorization you will receive a token that you need to insert into the field below and click verify.',
            descWarning: 'Executors are assigned to the tasks only if they are already invited to the team in Shtab',
          },
          stage2: {
            title: 'Step 2. Choose Boards to Transfer',
            input: 'Select boards',
            desc: 'You need to log in to Trello, during the authorization you will receive a token that you need to insert into the field below and click verify.',
          },
        },
        authorize: 'Log in to Trello',
        token: {
          button: 'Check token',
          input: 'Enter token',
        },
        jsonMethod: 'JSON',
        tokenMethod: 'Токен',
        more: 'Read more...',
        method: 'Migration method',
        board: 'Choose a board',
        import: 'Start Import',
        importingStatus: 'Importing tasks in Shtab',
        fetchingStatus: 'Fetching tasks from Trello',
        importing:
          'The import process is in progress, you can close the page, the data will be transferred automatically',
        close: 'Refresh page',
      },
      jira: {
        stages: {
          stage1: {
            title: 'Authorization and token',
            desc: 'You need to log in to Jira. During the login, you will receive a token that you need to insert into the field below and click check.',
            descWarning: 'Assignees will be assigned to tasks only if they are already invited to the team in Shtab.',
          },
          stage2: {
            input: 'Select projects',
          },
        },
        domain: {
          title: 'Domain in JIRA',
          placeholder: 'For example, stikpro.atlassian.net',
        },
        mail: {
          title: 'E-mail',
          placeholder: 'Enter your Jira login',
        },
        getToken: 'Get token',
        token: {
          button: 'Check token',
          input: 'Enter the token',
        },
        importingStatus: 'Importing tasks in Shtab',
        fetchingStatus: 'Fetching tasks from Trello',
        importing:
          'The import process is in progress, you can close the page, the data will be transferred automatically',
        close: 'Refresh page',
        import: 'Start import',
        more: 'More details...',
      },
      asana: {
        stages: {
          stage1: {
            title: 'Authorization and token',
            desc: 'You need to log in to Asana. During the login, you will receive a token that you need to insert into the field below and click check.',
            descWarning: 'Assignees will be assigned to tasks only if they are already invited to the team in Shtab.',
          },
          stage2: {
            input: 'Select projects',
          },
        },
        getToken: 'Get token',
        token: {
          button: 'Check token',
          input: 'Enter the token',
        },
        importingStatus: 'Importing tasks in Shtab',
        fetchingStatus: 'Fetching tasks from Asana',
        importing:
          'The import process is in progress, you can close the page, the data will be transferred automatically',
        close: 'Refresh page',
        import: 'Start import',
        more: 'More details...',
      },
    },
  },
  googleCalendar: {
    title: 'Google Calendar',
    delete: 'Remove integration',
    datasourceTitle: 'Databases',

    addBtn: 'Add',
    startSyncBtn: 'Start sync',

    syncToggle: {
      enabled: 'Synchronization enabled',
      disabled: 'Synchronization disabled',
    },
    confirm: {
      btnDelete: 'Delete',
      btnCancel: 'Cancel',
      title: 'Are you sure you want to remove the integration?',
    },
    empty: 'Add databases to sync',
  },
};
