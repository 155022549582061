import { svgIcons } from '@/constants/icons';

import type { Nullable } from '@/types/utility';

export default class TextHelper {
  private static readonly URL_REGEX: RegExp = /(http:\/\/|ftps:\/\/|www\.|https:\/\/)\S+/gim;

  private static readonly TEXT_WRAP_REGEX: RegExp = /(?:[\n])/g;

  public static isIncludesURL(pastedText: string): boolean {
    return TextHelper.URL_REGEX.test(pastedText);
  }

  public static replaceLinksWithHTMLTags(pastedText: string): string {
    return pastedText.replace(TextHelper.URL_REGEX, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer"
                    class="link" contenteditable="false">${url}</a>&nbsp;`;
    });
  }

  public static isEmoji(str: string): boolean {
    const ranges = ['\ud83c[\udf00-\udfff]', '\ud83d[\udc00-\ude4f]', '\ud83d[\ude80-\udeff]'];
    return str.match(ranges.join('|')) !== null;
  }

  public static isSvgIcon(str: any): boolean {
    return Object.values(svgIcons).includes(str);
  }

  public static replaceTextWrapWithBR(pastedText: string): string {
    return pastedText.replace(TextHelper.TEXT_WRAP_REGEX, '<br>');
  }

  public static capitalize(str: string): string {
    if (!str) return str;

    return str[0].toUpperCase() + str.slice(1);
  }

  public static async slugify(str: string): Promise<string> {
    const _slugify = (await import('@sindresorhus/slugify')).default;
    return _slugify(str, { separator: '' }).toUpperCase().replace(/\d/g, '').slice(0, 15);
  }

  public static safeParseJSON<T>(json: string): Nullable<T> {
    if (!json) return null;

    try {
      return JSON.parse(json);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
