export default {
  title: 'Transactions',
  reference:
    'Record financial transactions to visually see the difference in income and expenses, to understand how much money is spent on renting an office, equipment, software, taxes, etc.',

  refill: 'Refill',
  debit: 'Debit',
  transfer: 'Transfer',

  date: 'Date',
  repeatOperation: 'Repeat the operation',
  repetition: 'Repetition',
  indefinitely: 'Indefinitely',
  repeatUntil: 'Repeat until',
  cause: 'Base',
  purpose: 'Purpose',
  account: 'Account',
  amount: 'Sum',
  contractor: 'Contractor',
  article: 'Expense',
  datasource: 'Database',
  task: 'Card',
  direction: 'Direction',
  comment: 'Comment',
  fromAccount: 'Transfer from account',
  toAccount: 'To account',
  convertSum: 'Converted amount',
  typeTransaction: 'Transaction type',
  type: 'Type',
  label: 'Tags',
  status: 'Status',
  period: 'Period',
  periodApplied: 'from {start} to {end}',

  financial_account: 'Account',
  financial_item: 'Expense',

  repeat: {
    week: 'Every week',
    month: 'Every month',
    twoMonths: 'Every 2 months',
    quarter: 'Every quarter',
    halfYear: 'Every six months',
    year: 'Every year',
  },

  newAccount: 'New account',
  newArticle: 'New expense',
  newContractor: 'New contractor',
  newDirection: 'New direction',

  sorting: {
    all: 'All',
    active: 'Active',
    archive: 'Archive',
  },

  actions: {
    history: 'History of changes',
    mark: 'Mark',
    duplicate: 'Copy',
    archive: 'Archive',
    reset: 'Reset',
    delete: 'Delete',
  },

  nextRepeat: 'Next Repeat',
  interval: 'Interval',
  lastRepeat: 'Last Repeat',
  repeatExpired: 'Repeat expired',
  repetitionUntil: 'Repetition to',

  empty: 'You do not have any operations added yet',

  history: {
    title: 'Change history',
    operation: 'Operations “{operation}”',
    created: 'Created: {date} in {time}',
    creator: 'Creator: ',
    when: 'When/Who',
    action: 'Action',
    change: 'Change',
    more: 'show more',
    hide: 'hide',
    changeField: ' field ',
    noChanges: 'No changes yet',
  },

  today: 'Today',
  yesterday: 'Yesterday',

  totalReference:
    'Specify the amount of the transaction taking into account the current exchange rate of the currency to the ruble',
  errorCreate: 'Fill in the required fields: Amount, Account',

  repeated: 'Repeated',
  simple: 'Simple',

  noOperations: 'No operations found',
  billing: 'Unlock the power of your financial management tool',
};
