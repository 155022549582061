import session from '@/api/session';

export default {
  getFeedDetails({ teamId, feedId }) {
    return session.get(`feeds/feed/${teamId}/detail/${feedId}/`);
  },
  getFeedsList({ teamId, getParams }) {
    return session.get(`feeds/feed/${teamId}/list/`, { params: getParams });
  },
  createFeed({ teamId, requestBody }) {
    return session.post(`feeds/feed/${teamId}/create/`, requestBody);
  },
  deleteFeed({ teamId, feedId }) {
    return session.delete(`feeds/feed/${teamId}/delete/${feedId}/`);
  },
  editFeed({ teamId, feedId, requestBody }) {
    return session.patch(`feeds/feed/${teamId}/update/${feedId}/`, requestBody);
  },
};
