import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getTaskFiles(taskId) {
    const defaultUrl = `tasks/files/${taskId}/list/`;
    const sharedUrl = `shared/tasks/files/task/${taskId}/list/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl));
  },
  addTaskFile({ taskId, fd, config }) {
    return session.post(`tasks/files/${taskId}/create/`, fd, config);
  },
  preSignFileForS3({ id: taskId, fileData, signature }) {
    return session.post(`tasks/files/${taskId}/s3/pre-sign/`, {
      ...fileData,
      signature,
    });
  },
  deleteTaskFile({ taskId, fileId }) {
    return session.delete(`tasks/files/${taskId}/delete/${fileId}/`);
  },
  deleteSelectedFiles({ taskId, filesId }) {
    return session.delete(`tasks/files/${taskId}/delete-files/`, { data: { files: filesId } });
  },
};
