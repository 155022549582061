import activity from '@/locales/en/activity/index';
import auth from '@/locales/en/auth/index';
import billing from '@/locales/en/billing/index';
import common from '@/locales/en/common';
import constants from '@/locales/en/constants/index';
import errorPages from '@/locales/en/errorPages';
import feeds from '@/locales/en/feeds/index';
import finances from '@/locales/en/finances';
import integrations from '@/locales/en/integrations';
import layout from '@/locales/en/layout/index';
import modals from '@/locales/en/modals/index';
import notifications from '@/locales/en/notifications';
import onboarding from '@/locales/en/onboarding/index';
import payouts from '@/locales/en/payouts/index';
import plural from '@/locales/en/plural';
import projects from '@/locales/en/projects/index';
import reports from '@/locales/en/reports/reports';
import routes from '@/locales/en/routes/index';
import store from '@/locales/en/store';
import tasks from '@/locales/en/tasks/index';
import team from '@/locales/en/team/index';
import tribute from '@/locales/en/tribute';
import updates from '@/locales/en/updates/index';
import view from '@/locales/en/view/index';
import wizard from '@/locales/en/wizard';

export default {
  layout,
  routes,
  constants,
  projects,
  billing,
  team,
  payouts,
  modals,
  tasks,
  activity,
  reports,
  common,
  auth,
  onboarding,
  updates,
  errorPages,
  feeds,
  store,
  view,
  wizard,
  finances,
  tribute,
  plural,
  notifications,
  integrations,
};
