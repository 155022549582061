import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';
import { user } from '@/models/User';

import ZodHelper from '@/helpers/ZodHelper';

type GetAllInOneUsers = {
  teamId: number;
  getParams?: {
    search: string;
  };
};
type GetAllInOneTasks = {
  teamId: number;
  getParams: Record<string, any>;
  config: Pick<AxiosRequestConfig, 'signal'>;
};
type ChangeAllInOneTaskPosition = {
  taskId: number;
  teamId: number;
  requestBody: any;
};

export const apiAllInOne = {
  getAllInOneUsers({ teamId, getParams }: GetAllInOneUsers) {
    return ZodHelper.request(
      () => session.get(`teams/all-in-one/${teamId}/users/`, { params: getParams }),
      user.array(),
    );
  },
  getAllInOneTasks({ teamId, getParams, config }: GetAllInOneTasks) {
    return session.get(`teams/all-in-one/${teamId}/tasks/`, { ...config, params: getParams });
  },
  changeAllInOneTaskPosition({ taskId, teamId, requestBody }: ChangeAllInOneTaskPosition) {
    return session.patch(`teams/all-in-one/${teamId}/change-task-position/${taskId}/`, requestBody);
  },
};
