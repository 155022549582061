export default {
  dueDate: {
    select: 'Select due date',
    reset: 'Reset',
    start: 'Task start',
    end: 'Task completion',
    time: 'Time',
    from: 'from',
    until: 'until',
  },
};
