export default {
  editStatus: 'Edit status',
  confirm: {
    archive: 'Are you sure you want to archive all column tasks?',
    deleteStage: 'Are you sure you want to delete the stage?',
    btnConfirm: 'Archive',
    btnCancel: 'Cancel',
    btnDelete: 'Delete',
  },
  confirmDeleteStatus: {
    title: 'Are you sure you want to remove the "{name}" status?',
    btnConfirm: 'Delete',
    btnCancel: 'Cancel',
  },
};
