export default {
  auth: 'Authorization - Shtab',
  registration: 'Registration – Shtab',
  regSuccess: 'Registration successful',
  restoreAccount: 'Restore account – Shtab',
  restorePassword: 'Restore password – Shtab',
  restoreSuccess: 'Password restore information has been sent to your email',
  activateAccount: 'Activate account – Shtab',
  socialAuth: 'Social auth - Shtab',
};
