import routerHelper from '@/router/helper';

import accounts from '@/router/routes/finances/accounts';
import articles from '@/router/routes/finances/articles';
import counterparts from '@/router/routes/finances/counterparts';
import directions from '@/router/routes/finances/directions';
import transactions from '@/router/routes/finances/transactions';

const finances = [
  {
    path: 'finances',
    redirect: { name: routerHelper.pageNames.finances.FINANCES_DIRECTIONS },
    children: [...directions, ...counterparts, ...articles, ...accounts, ...transactions],
    component: () => import('@/pages/finances/Finances.vue'),
  },
];

export default finances;
