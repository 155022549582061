export default {
  feedCreate: {
    title: 'New Feed!',
    changed: 'has published the feed into feeds',
    link: 'Go to feed',
  },
  feedCommentCreate: {
    title: 'Feed comment',
    changed: 'has added comment to the feed into feeds',
  },
  feedCommentAnswer: {
    title: 'Comment reply',
    changed: 'has replied on your comment to the feed into feeds',
  },
};
