const adjectives = ['Red', 'Blue', 'Green', 'Yellow', 'Purple', 'Orange', 'Pink', 'Black', 'White'];
const animals = ['Cat', 'Dog', 'Rabbit', 'Tiger', 'Lion', 'Elephant', 'Giraffe', 'Monkey', 'Zebra'];
const colors = [
  '#9e2f22',
  '#3fc695',
  '#d870e5',
  '#974ea0',
  '#e570af',
  '#9e70e5',
  '#6f4ea0',
  '#18bf63',
  '#14aa0a',
  '#e0bb04',
  '#dd041a',
  '#c45815',
  '#b55b2b',
];

const generateRandomName = () => {
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const animal = animals[Math.floor(Math.random() * animals.length)];
  return `${adjective} ${animal}`;
};

const generateRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export const generateAnonimous = () => {
  return {
    full_name: generateRandomName(),
    color: generateRandomColor(),
    avatar: null,
  };
};
