export default {
  ReferToCardIssuer: 'Issuer declined the operation',
  InvalidMerchant: 'Issuer declined the operation',
  PickUpCard: 'Lost Card',
  DoNotHonor: 'Issuer declined without explanation',
  Error: 'Network failure to perform an operation or incorrect CVV code',
  PickUpCardSpecialConditions: 'Lost Card',
  InvalidTransaction: 'The card is not intended for online payments',
  AmountError: 'Too small or too large transaction amount',
  InvalidCardNumber: 'Invalid Card Number',
  NoSuchIssuer: 'Unknown card issuer',
  TransactionError: 'Issuer declined without explanation',
  FormatError: 'Error on the side of the acquirer - incorrectly formed transaction',
  BankNotSupportedBySwitch: 'Unknown card issuer',
  ExpiredCardPickup: 'Expired Card Pickup',
  SuspectedFraud: 'Issuer failure - fraud is suspected',
  RestrictedCard: 'The card is not intended for online payments',
  LostCard: 'Lost Card',
  StolenCard: 'Stolen Card',
  InsufficientFunds: 'Insufficient Funds',
  ExpiredCard: 'Card is expired or expiration date is incorrect',
  TransactionNotPermitted: 'Card limitation',
  RestrictedCard2: 'The card is not intended for online payments',
  SecurityViolation: 'The card is blocked by security violation',
  ExceedWithdrawalFrequency: 'Card transactions limit is exceeded',
  IncorrectCVV: 'Incorrect CVV code',
  Timeout: 'Issuer is not available',
  CannotReachNetwork: 'Issuer is not available',
  SystemError: 'Acquiring bank or network error',
  UnableToProcess: 'The operation cannot be processed for other reasons',
  AuthenticationFailed: '3-D secure Authentication failed',
  AuthenticationUnavailable: '3-D secure Authentication unavailable',
  AntiFraud: 'Acquirer Transaction Limits',
};
