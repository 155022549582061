export default {
  title: 'Directions',
  reference:
    'Combine transactions of different types into groups to see the movement of funds within a line of business.',

  list: {
    title: 'Title',
    description: 'Description',
    enterTitle: 'Enter the title',
    notAdded: 'Not added',
    empty: "You don't have any directions added yet",
  },

  confirm: {
    title: 'Are you sure you want to remove the direction «{direction}»?',
    btnCancel: 'Cancel',
    btnConfirm: 'Delete',
  },
};
