import { z } from 'zod';

import { taskTypeMinimum } from '@/models/CardTypes';
import { tag } from '@/models/Tag';
import { taskExecutor } from '@/models/tasks/TaskExecutor';

export const subtask = z
  .object({
    id: z.number(),
    archived: z.boolean(),
    name: z.string(),
    completed: z.boolean(),
    deadline: z.string().nullable(),
    deadline_time: z.string().nullable(),
    date_start: z.string().nullable(),
    date_start_time: z.string().nullable(),
    files_count: z.number(),
    check_lists_count: z.number(),
    comments_count: z.number(),
    slug: z.string(),
    subtasks_count: z.number(),
    task_executor: taskExecutor.array(),
    task_labels: z.array(tag),
    type: taskTypeMinimum.nullable(),
  })
  .transform((value) => ({ ...value, executors: value.task_executor }));

export type Subtask = z.infer<typeof subtask>;
