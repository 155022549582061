import store from '@/store';

import routerHelper from '@/router/helper';
import pageNames from '@/router/helper/pageNames';

const requireAuth = (to, from, next) => {
  const isAccountDeleted = Boolean(localStorage.getItem('deletedAccount'));
  if (isAccountDeleted) {
    next({ path: '/' });
    return;
  }
  store.getters['auth/isAuthenticated']
    ? next()
    : next({ name: routerHelper.pageNames.auth.LOGIN, query: { ...to.query, next: to.fullPath } });
};

const requireNotAuth = (to, from, next) => {
  // Так как приглашение в команду может быть для авторизированного пользователя и нет
  if (to.name === pageNames.auth.RESTORE_ACCOUNT) {
    next();
    return;
  }
  const isException = to.name === pageNames.auth.INVITATION_CHECK_TOKEN;
  if (!isException && store.getters['auth/isAuthenticated']) next({ path: '/' });
  else next();
};

const redirectLogout = async (to, from, next) => {
  await store.dispatch('auth/LOGOUT');
  next({ name: routerHelper.pageNames.auth.LOGIN });
};

const restoreCheckToken = async (to, from, next) => {
  try {
    await store.dispatch('auth/RESTORE_CHECK_TOKEN', to.query);
    next({
      name: routerHelper.pageNames.auth.RESTORE_CHANGE_PASSWORD,
      query: {
        uid: to.query.uid,
        activation_token: to.query.restore_token,
      },
    });
  } catch {
    next({ name: routerHelper.pageNames.auth.LOGIN });
  }
};

export const INVITE_ERRORS = {
  INVALID_TOKEN: 'invalid_token_error',
  INVALID_DATED_TOKEN: 'outdated_token_error',
};

const checkInvitationToken = async ({ query, fullPath }, from, next) => {
  try {
    const { token: tokenAuth, team_id: teamId } = await store.dispatch('teams/CHECK_INVITATION_TOKEN', query.token);
    store.commit('auth/setToken', tokenAuth);

    const { DEFAULT_PAGE } = routerHelper.constants;
    const defaultPageWithData = { ...DEFAULT_PAGE, params: { ...DEFAULT_PAGE.params, teamId } };
    next(defaultPageWithData);
  } catch (e) {
    const registerInvited = routerHelper.pageNames.auth.REGISTRATION_INVITED_USER;
    const redirectInvitedError = routerHelper.pageNames.errors.PAGE_INVITE_ERROR;
    const errorCode = e.response.data.error_code?.toString();

    const displayErrorMessage = async (errorMessageField) => {
      const errorMessage = e.response.data[errorMessageField]?.toString();
      await store.dispatch('notifications/SHOW_ERROR', errorMessage);
    };

    const AUTH_ERROR_CODE = '1';
    const INVALID_TOKEN_ERROR_CODE = '2';
    const NEED_FILL_DATA_ERROR_CODE = '3';
    const OUTDATED_TOKEN_ERROR_CODE = '4';

    if (errorCode === AUTH_ERROR_CODE) {
      await store.dispatch('auth/LOGOUT');
      await checkInvitationToken({ query, fullPath }, from, next);
    } else if (errorCode === NEED_FILL_DATA_ERROR_CODE) {
      query.next = fullPath;
      next({ name: registerInvited, query });
    } else if (errorCode === INVALID_TOKEN_ERROR_CODE) {
      next({
        name: redirectInvitedError,
        query: {
          inviteError: INVITE_ERRORS.INVALID_TOKEN,
        },
      });
      await displayErrorMessage('token');
    } else if (errorCode === OUTDATED_TOKEN_ERROR_CODE) {
      next({
        name: redirectInvitedError,
        query: {
          inviteError: INVITE_ERRORS.INVALID_DATED_TOKEN,
        },
      });
      await displayErrorMessage('user');
    }
  }
};

export { requireAuth, requireNotAuth, redirectLogout, restoreCheckToken, checkInvitationToken };
