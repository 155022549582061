export default {
  header: {
    addTime: 'Time',
  },

  performance: {
    activity: 'Activity',
    time: 'Time',

    timer: 'Timer',
    infoTimer: 'By disabling the timer, the card will no longer appear in the tracker, activity and reports.',

    applications: 'Applications',
    applicationsInfo:
      "Information about the activity of applications that are running during the user's time tracking.",
    emptyApplications:
      'There is no information about the applications used. Check if application activity collection is enabled in the user profile.',

    lastTasks: 'Last tasks',
    noLastTasks: 'There are no cards, start recording the time so that you know what you were working on.',
  },

  calendar: {
    forWeek: 'For week, h',
    totalForMonth: 'Total for month',

    details: {
      time: 'Time',
      task: 'Card',
      datasource: 'Database',
      hours: 'Hours',
    },
  },

  card: {
    private: 'Contains confidential information',
    addedTime: 'Added time',
    percent: '{percent}% of activity',
    noActivity: 'No activity',
  },

  noScreenshots: 'No screenshots for this day',

  selectedScreenshots: {
    minutes: '{min} minutes selected',
    seconds: '{sec} seconds selected',
    screenshot: '{screenshots} screenshots selected',
    delete: 'Delete',
    reset: 'Unselect',

    confirm: {
      title: 'Are you sure you want to delete {screenshots} screenshots?',
      btnCancel: 'Back',
      btnConfirm: 'Delete',
    },

    notification: 'Time has been deleted successfully',
  },

  graph: {
    timePercent: '{percent}% of the time',
    workTime: 'Work time',
    graphInformation: 'The graph shows information for the last 4 weeks',
  },
};
