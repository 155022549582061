import session from '@/api/session';

export default {
  createSalary({ teamId, salaryRate }) {
    return session.post(`/payouts/salary-rate/${teamId}/create/`, salaryRate);
  },
  editSalary({ teamId, salaryId, requestBody }) {
    return session.patch(`/payouts/salary-rate/${teamId}/edit/${salaryId}/`, requestBody);
  },
};
