import { createI18n } from 'vue-i18n';

import localeEn from '@/locales/en';
import type { Locales } from '@/types/types';

export const i18n = createI18n({
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_DEFAULT_LOCALE,
  legacy: false,
  messages: {
    [import.meta.env.VITE_DEFAULT_LOCALE]: localeEn,
  } as {
    [key in Locales]: typeof localeEn;
  },
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

// TODO it looks like a hack
export default i18n.global;
