export default {
  actions: {
    restore: 'Restore',
    delete: 'Delete',
    title: 'Do you really want to delete the selected cards',
    cancel: 'Cancel',
  },

  selectedItemsCount: 'Selected cards per page: ',

  notification: {
    deleted: 'Card has been deleted successfully',
    restored: 'Card restored from archive',
  },
  table: {
    id: 'ID',
    name: 'Name',
    source: 'Database',
    date: 'Date',
    user: 'Archived by',
  },
};
