import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export const initSentry = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    if (import.meta.env.VITE_SENTRY_DSN) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_MODE,
        tracesSampleRate: 0.0,
      });
    }
    resolve();
  });
};
