export default {
  editPayer: 'Edit payer',
  addPayer: 'Add payer',

  legalName: 'Registered name of your company',
  inn: 'TIN',
  kpp: 'KPP',
  legalAddress: 'Legal address',

  bankName: 'Name of bank',
  account: 'Account No.',
  bic: 'BIC',
  corresponding: 'c/o',

  contact: 'Contact person',
  email: 'E-mail',
  phone: 'Phone:',

  terms: 'I have read the conditions',
  contract: ' of the contract, ',
  agreeContract: 'and agreed with them',

  agreePrivacy: 'I agree to the',
  privacyPolicy: ' Privacy Policy ',
  consent: ', and agree that my data is processed',

  save: 'Save',
};
