import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';

type GetFilesParams = {
  teamId: number;
  datasourceId: number;
  getParams: {
    page: number;
    ordering: string;
    is_in_trash: boolean;
    search: string;
  };
};
type AddFilesParams = Omit<GetFilesParams, 'getParams'> & {
  fd: FormData;
  config: AxiosRequestConfig;
};

type EditFilesPlaceParams = Omit<GetFilesParams, 'getParams'> & {
  requestBody: {
    files: Array<number>;
    target: boolean;
  };
};
type EditFileNameParams = Omit<GetFilesParams, 'getParams'> & {
  fileId: number;
  requestBody: {
    file_name: string;
  };
};
type PreSignFileForS3Params = Omit<GetFilesParams, 'getParams'> & {
  fileData: Record<string, number | string>;
  signature: string;
};
type DownloadFilesParams = Omit<GetFilesParams, 'getParams'> & {
  requestBody: {
    datasource_files: Array<number>;
  };
};
type DeleteFilesParams = DownloadFilesParams;
type DeleteAllFilesParams = Omit<GetFilesParams, 'getParams'>;

export const apiDataSourceFiles = {
  getFiles({ teamId, datasourceId, getParams }: GetFilesParams) {
    return session.get(`datasource/files/${teamId}/${datasourceId}/list/`, { params: getParams });
  },
  addTemporaryFiles({ fd }: AddFilesParams) {
    return session.post(`/temporary-files/`, fd);
  },
  addFiles({ teamId, datasourceId, config, fd }: AddFilesParams) {
    return session.post(`datasource/files/${teamId}/${datasourceId}/create/`, fd, config);
  },
  preSignFileForS3({ teamId, datasourceId, fileData, signature }: PreSignFileForS3Params) {
    return session.post(`datasource/files/${teamId}/${datasourceId}/s3/pre-sign/`, {
      ...fileData,
      signature,
    });
  },

  editFilesPlace({ teamId, datasourceId, requestBody }: EditFilesPlaceParams) {
    return session.patch(`datasource/files/${teamId}/${datasourceId}/update-trash/`, requestBody);
  },
  editFileName({ teamId, datasourceId, fileId, requestBody }: EditFileNameParams) {
    return session.patch(`datasource/files/${teamId}/${datasourceId}/update-name/${fileId}/`, requestBody);
  },

  deleteFiles({ teamId, datasourceId, requestBody }: DeleteFilesParams) {
    return session.delete(`datasource/files/${teamId}/${datasourceId}/delete/`, { data: requestBody });
  },
  deleteAllFiles({ teamId, datasourceId }: DeleteAllFilesParams) {
    return session.get(`datasource/files/${teamId}/${datasourceId}/delete-all/`);
  },
};
