export default {
  coverDisplay: 'Cover display',
  removeCover: 'Remove cover',
  titleTextCover: 'Text color',
  titleOnCover: 'Task title',
  images: 'Images',
  hideImages: 'Hide files',
  showMore: 'Show more',
  loadPictureForCover: 'Upload cover image',
  imagesFromUnsplash: 'Images from Unsplash database',
  findImages: 'Find pictures',
  unsplashPrivacy: 'By using images from the Unsplash website, you accept its ',
  terms: 'Terms of Use',
  and: 'and',
  privacy: 'License Policy.',
  usedAsCover: 'Used as cover',

  search: 'Search',
  searchInput: 'Start typing',
  bestPictures: 'Best photos',
  notFound: 'Nothing found',
};
