import styles from '@/styles/_variables.module.scss';

export default {
  archiveAllTasks: 'Archive all tasks',

  sortBy: 'Sort by...',
  sort: 'Sort',
  byDateAsc: `<span>by creation date</span><span style="color: ${styles.colorGreyLight}"> (newest first)</span>`,
  byDateDes: `<span>by creation date</span><span style="color: ${styles.colorGreyLight}""> (oldest first)</span>`,
  byPriorityAsc: `<span>by priority</span><span style="color: ${styles.colorGreyLight}""> (highest to lowest)</span>`,
  byPriorityDes: `<span>by priority</span><span style="color: ${styles.colorGreyLight}""> (lowest to highest)</span>`,
  byDeadlineAsc: `<span>by deadline</span><span style="color: ${styles.colorGreyLight}""> (ascending)</span>`,
  byDeadlineDes: `<span>by deadline</span><span style="color: ${styles.colorGreyLight}""> (descending)</span>`,
  byWorkTimeAsc: `<span>by work time</span><span style="color: ${styles.colorGreyLight}""> (ascending)</span>`,
  byWorkTimeDes: `<span>by work time</span><span style="color: ${styles.colorGreyLight}""> (descending)</span>`,
  byAlpha: `<span>by name</span><span style="color: ${styles.colorGreyLight}""> (alphabetically)</span>`,

  deleteStage: 'Delete stage',
  deleteStatus: `<span style="color: ${styles.colorError}">Delete status</span>`,
  editStatus: 'Edit status',
  moveTo: 'Move all tasks',
  moveToHeader: 'Move all tasks to ...',
};
