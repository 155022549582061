import router from '@/router';

const deleteQueryParams = (paramNames, extraParams = {}) => {
  const query = { ...router.currentRoute.value.query };
  const { isSaveHistory = false } = extraParams;
  // eslint-disable-next-line no-undefined
  paramNames.forEach((name) => (query[name] = undefined));

  isSaveHistory ? router.push({ query }) : router.replace({ query });
};

export default deleteQueryParams;
