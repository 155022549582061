import general from '@/locales/en/modals/profile/general';
import integrations from '@/locales/en/modals/profile/integrations';
import others from '@/locales/en/modals/profile/others';

export default {
  editProfile: 'Edit profile',
  tabs: {
    general: 'Main',
    integrations: 'Integrations',
    others: 'Other',
  },

  general,
  integrations,
  others,
};
