import { createPermissionHook } from '@/router/hooks/permissions';

import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

import { canUseTaskHistory } from '@/constants/permissions/listBillingPermissions';
import { portalNames } from '@/constants/portals';

const requirePermission = createPermissionHook(canUseTaskHistory, { name: pageNames.errors.PAGE_NO_ACCESS }, 'billing');

export default [
  {
    path: 'task/:taskId',
    props: (route) => ({ taskId: Number(route.params.taskId) }),
    component: () => import('@/pages/tasks/TaskDetails.vue'),
    name: pageNames.tasks.TASK_DETAILS,
    meta: {
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.task,
        },
      ],
    },
  },
  {
    name: pageNames.tasks.TASK_SCREENSHOTS,
    path: 'task/:taskId/screenshots',
    component: () => import('@/pages/tasks/TaskScreenshots.vue'),
    props: (route) => ({ taskId: Number(route.params.taskId) }),
    meta: {
      title: new Translation('routes.tasks.screenshots'),
      seoTitle: new Translation('routes.tasks.seoScreenshots'),
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.task,
        },
        {
          title: new Translation('routes.tasks.screenshots'),
        },
      ],
    },
  },
  {
    name: pageNames.tasks.TASK_HISTORY,
    path: 'task/:taskId/history',
    props: (route) => ({ taskId: Number(route.params.taskId) }),
    component: () => import('@/pages/tasks/TaskHistory.vue'),
    meta: {
      title: new Translation('routes.tasks.history'),
      seoTitle: new Translation('routes.tasks.seoHistory'),
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.task,
        },
        {
          title: new Translation('routes.tasks.history'),
        },
      ],
    },
    beforeEnter: requirePermission,
  },
];
