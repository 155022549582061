import { z } from 'zod';

export const datasource = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  comment: z.string(),
});

export type Datasource = z.infer<typeof datasource>;

export const datasourceInList = datasource
  .extend({ views_count: z.number() })
  .transform(({ views_count: viewsCount, ...value }) => ({
    ...value,
    viewsCount,
  }));

export type DatasourceInList = z.infer<typeof datasourceInList>;
