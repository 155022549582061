import { z } from 'zod';

export const userBase = z.object({
  id: z.number(),
  username: z.string(),
  first_name: z.string(),
  last_name: z.string().nullable(),
  full_name: z.string(),
  color: z.string(),
  avatar: z.string().nullable(),
});
export const user = userBase.extend({
  phone_number: z.string().nullish(),
  username: z.string(),
  online: z.boolean().optional(),
  tracking: z.number().nullish(),
  last_team: z.number().nullish(),
});

export type MinimumUser = z.infer<typeof userBase>;

export type User = z.infer<typeof user>;
