import z from 'zod';

export const taskFile = z
  .object({
    id: z.string(),
    url: z.string(),
    name: z.string(),
    size: z.string(),
    created_at: z.string(),
    extension: z.string(),
  })
  .transform(({ created_at, ...rest }) => {
    return { createdAt: created_at, ...rest };
  });
export type TaskFile = z.infer<typeof taskFile>;
