import type { AxiosRequestConfig } from 'axios';

import session from '@/api/session';

export default {
  getTrelloBoards({ token }: { token: string; team: number }) {
    return session.post(`/tasks/upload/trello/get-projects/`, { token });
  },
  getJiraProjects(requestData: { login: string; api_key: string; server: string }) {
    return session.post(`/tasks/upload/jira/get-projects/`, requestData);
  },
  getAsanaProjects(requestData: { token: string; teamId: number }) {
    return session.post(`/tasks/upload/asana/${requestData.teamId}/get-projects/`, requestData);
  },
  setAsanaImport(requestData: {
    token: string;
    team_id: number;
    asana_projects: { asana_id: string; workspace_id: string; name: string }[];
    user_id: string;
    task_uid: string;
  }) {
    return session.post(`/tasks/upload/asana/${requestData.team_id}/get-tasks/`, requestData);
  },
  setJiraImport(requestData: {
    api_key: string;
    team: number;
    type: string;
    jira_projects: string[];
    server: string;
    login: string;
  }) {
    return session.post(`/tasks/upload/jira/import/`, requestData);
  },
  setTrelloImport(requestData: {
    type: string;
    token?: string;
    team?: number;
    boards?: string[];
    config?: AxiosRequestConfig;
    fd?: FormData;
  }) {
    if (requestData.type === 'trello') return session.post(`/tasks/upload/trello/import/`, requestData);
    const { config, fd } = requestData;
    return session.post(`/tasks/upload/trello-json/import/`, fd, config);
  },
  getLoadingStatus(uuid: string) {
    return session.get(`/tasks/upload/status/${uuid}/`);
  },
};
