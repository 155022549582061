import * as amplitude from '@amplitude/analytics-browser';
import type { App } from 'vue';

const initialize = {
  install: () => {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, {
      appVersion: '0.0.1',
    });

    const identify = new amplitude.Identify().set('Environment', import.meta.env.VITE_MODE);
    amplitude.identify(identify);
  },
};

export const initAmplitude = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    if (import.meta.env.VITE_AMPLITUDE_KEY) {
      app.use(initialize);
    }
    resolve();
  });
};
