import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getTaskChecklist(taskId) {
    const defaultUrl = `tasks/checklists/${taskId}/list/`;
    const sharedUrl = `shared/tasks/check-list/task/${taskId}/list/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl));
  },
  deleteTaskChecklistItem({ taskId, checklistItemId }) {
    return session.delete(`tasks/checklists/${taskId}/delete/${checklistItemId}/`);
  },
  createTaskChecklistItem({ taskId, checklistItem }) {
    return session.post(`tasks/checklists/${taskId}/create/`, checklistItem);
  },
  editTaskChecklistItem({ taskId, checklistItem }) {
    return session.patch(`tasks/checklists/${taskId}/edit/${checklistItem.id}/`, checklistItem);
  },
};
