import session from '@/api/session';
import { taskExecutor } from '@/models/tasks/TaskExecutor';

import ZodHelper from '@/helpers/ZodHelper';

export type AddTaskExecutorData = {
  requestBody: {
    task: number;
    executor: number;
  };
};

type EditTaskExecutor = {
  taskId: number;
  userId: number;
  requestBody: {
    finished: boolean;
  };
};

export default {
  addTaskExecutor({ requestBody }: AddTaskExecutorData) {
    return ZodHelper.request(() => session.post(`/tasks/executors/create/`, requestBody), taskExecutor);
  },
  deleteTaskExecutor(taskId: number, executorId: number) {
    return session.delete(`/tasks/executors/${taskId}/delete/${executorId}/`);
  },
  editTaskExecutor({ taskId, userId, requestBody }: EditTaskExecutor) {
    return ZodHelper.request(
      () => session.patch(`/tasks/executors/${taskId}/update/${userId}/`, requestBody),
      taskExecutor,
    );
  },
};
