export default {
  preferences: {
    executor: 'Assignee',
    projects: 'Projects',
    tasks: 'Tasks',
    time: 'Time',
    activity: 'Activity',
  },

  noData: 'No team members found. Please modify your search',
};
