import { z } from 'zod';

import { customFieldBase } from '@/models/CustomField';

export const datasourceCustomField = z
  .object({
    id: z.number(),
    datasources_count: z.number(),
    custom_field_detail: customFieldBase.pick({ name: true, type: true }),
  })
  .transform(({ datasources_count, custom_field_detail, id }) => ({
    id,
    dataSourcesCount: datasources_count,
    ...custom_field_detail,
  }));

export type DatasourceCustomField = z.infer<typeof datasourceCustomField>;
