import session from '@/api/session';

export default {
  makeVote({ pollId, requestBody }) {
    return session.post(`feeds/poll/${pollId}/vote/`, requestBody);
  },
  updatePoll({ pollId, requestBody }) {
    return session.patch(`feeds/poll/${pollId}/update/`, requestBody);
  },
};
