import { z } from 'zod';

import type { CounterpartBody } from '@/api/finances/counterparts';

export const financesGroups = z.object({
  id: z.number(),
  name: z.string(),
  team: z.number(),
});

export const financesField = z.object({
  id: z.number().optional(),
  name: z.string(),
  value: z.string(),
  main: z.string().optional(),
});

export const financesContact = z
  .object({
    id: z.number().optional(),
    fio: z.string().nullish(),
    position: z.string().nullish(),
    phone_numbers: z.array(z.string()).nullish(),
    emails: z.array(z.string()).nullish(),
    comment: z.string().nullish(),
  })
  .transform(({ phone_numbers, ...values }) => ({
    ...values,
    phoneNumbers: phone_numbers,
  }));

export const financesDocument = z
  .object({
    id: z.string(),
    name: z.string(),
    incoming: z.boolean(),
    file: z.string().optional(),
    file_to_upload: z.string().optional(),
    type: z.string(),
    archived: z.boolean(),
    status: z.number().nullish(),
    number: z.string(),
  })
  .transform(({ file_to_upload, ...value }) => ({
    ...value,
    fileToUpload: file_to_upload,
  }));

export const settlementAccount = z.object({
  id: z.number().optional(),
  name: z.string().nullable(),
  default: z.boolean(),
  number: z.string().nullable(),
  fields: z.array(financesField),
});

export const counterpart = z
  .object({
    id: z.number(),
    name: z.string(),
    team: z.number().optional(),
    group: z.number().nullable(),
    archived: z.boolean(),
    group_detail: financesGroups.nullish(),
    fields: z.array(financesField).nullable(),
    contacts: z.array(financesContact).nullish(),
    documents: z.array(financesDocument).nullish(),
    settlement_accounts: z.array(settlementAccount).nullish(),
  })
  .transform(({ group_detail, settlement_accounts, ...value }) => ({
    ...value,
    groupDetail: group_detail,
    settlementAccounts: settlement_accounts,
  }));

export const counterpartSettings = z
  .object({
    contractor_fields: z.array(z.string()),
    settlement_account_fields: z.array(z.string()),
  })
  .transform(({ contractor_fields, settlement_account_fields }) => ({
    requisitesFields: contractor_fields,
    counterpartFields: settlement_account_fields,
  }));

export const formatCounterpartForApi = (counterpart: CounterpartBody) =>
  ({
    ...counterpart,
    contacts: counterpart.contacts?.map(({ phoneNumbers, ...rest }) => ({
      ...rest,
      phone_numbers: phoneNumbers,
    })),
    documents: counterpart.documents?.map(({ fileToUpload, ...rest }) => ({
      ...rest,
      file_to_upload: fileToUpload,
    })),
    group_detail: counterpart.groupDetail,
    settlement_accounts: counterpart.settlementAccounts,
  }) as CounterpartBody;

export type FinanceCounterpart = z.infer<typeof counterpart>;
export type FinancesGroup = z.infer<typeof financesGroups>;
export type CounterpartField = z.infer<typeof financesField>;
export type CounterpartContact = z.infer<typeof financesContact>;
export type CounterpartDocument = z.infer<typeof financesDocument>;
export type CounterpartSettlementAccount = z.infer<typeof settlementAccount>;
export type CounterpartSettings = z.infer<typeof counterpartSettings>;
