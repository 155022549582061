export default {
  title: 'Change in task',
  changed: 'has changed the task',
  details: 'Details',

  deadline: 'Deadline',
  status: 'Status',
  withoutStatus: 'Without status',
  priority: 'Priority',
  duration: 'Duration',
  dueDate: 'Due date',
  cost: 'Cost',
  titleChange: 'Title',
  subtask: {
    title: 'Subtask',
    added: 'added',
    deleted: 'deleted',
    finished: 'All subtasks completed',
  },
  executor: {
    title: 'Assignee',
    deleted: 'deleted',
    added: 'added',
  },
  responsible: {
    title: 'Supervisor',
    deleted: 'deleted',
    added: 'added',
  },
  stage: {
    title: 'Stage',
    added: 'added',
    deleted: 'deleted',
  },
  description: {
    title: 'Description',
    changed: 'Changed/added description:',
    view: 'View changes',
  },
  checklist: {
    title: 'Checklist',
    new: 'new',
    deleted: 'deleted',
  },
  files: {
    title: 'Files',
    added: 'Files added:',
    deleted: 'File {name} has been deleted.',
  },
  tags: {
    title: 'Tags',
    added: 'Tags added:',
    deleted: 'Tags deleted:',
  },
};
