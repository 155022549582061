import axios, { type AxiosResponse } from 'axios';

const SECRET_CHARS = '98 85 108 54 79 88 107 119 84 70 86 97 97 108 74 69 99 106 100 84 82 107 74 52 77 84 73 61';
const encodeSecret = () => {
  return SECRET_CHARS.split(' ')
    .map((char) => String.fromCharCode(Number(char)))
    .join('');
};

interface FileData {
  file_name: string;
  file_type: string;
  size_kb: number;
}

interface PreSignRequestParams {
  fileData: FileData;
  signature: string;
  id: string | number;
}

interface S3UploadArguments {
  id: string | number;
  preSignRequest: (payload: PreSignRequestParams) => Promise<AxiosResponse<{ file: Record<string, any>; url: string }>>;
  file: File;
}

const makeSignature = async (data: FileData): Promise<string> => {
  const targetUnescaped = unescape(encodeURIComponent(Object.values(data).toString()));
  const base64 = btoa(targetUnescaped + atob(encodeSecret()));
  const msgUint8 = new TextEncoder().encode(base64);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const uploadToS3 = (url: string, file: File) => {
  return new Promise((resolve, reject) => {
    const fileReaderBuffer = new FileReader();
    fileReaderBuffer.readAsArrayBuffer(file);
    fileReaderBuffer.onload = () => {
      const fileBuffer = fileReaderBuffer.result as ArrayBuffer;
      axios.put(url, fileBuffer).catch(reject);
    };

    const fileReaderBase64 = new FileReader();
    fileReaderBase64.readAsDataURL(file);
    fileReaderBase64.onload = ({ target }) => {
      if (target?.result) resolve(target.result);
    };
  });
};

const directS3Upload = async ({ id, preSignRequest, file }: S3UploadArguments) => {
  const fileData: FileData = {
    file_name: file.name,
    size_kb: file.size,
    file_type: file.type ?? 'application/octet-stream',
  };

  const signature = await makeSignature(fileData);
  const { data } = await preSignRequest({
    fileData,
    signature,
    id,
  });

  const fileBuffer = await uploadToS3(data.url, file);

  return { ...data, file: { ...data.file, url: fileBuffer } };
};

export default directS3Upload;
