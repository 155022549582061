const state = {
  isShow404: false,
};

const mutations = {
  show_404(state) {
    state.isShow404 = true;
  },
  hide_404(state) {
    state.isShow404 = false;
  },
};

const actions = {
  SHOW_404({ commit }) {
    commit('show_404');
  },
  HIDE_404({ commit }) {
    commit('hide_404');
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
