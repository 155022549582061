export default {
  filters: {
    status: 'By status',
    priority: 'By priority',
    stages: 'By stage',
    executor: 'By executor',
    tag: 'By tag',
  },
  listing: {
    board: 'Board',
    list: 'List',
    calendar: 'Calendar',
    matrix: 'Matrix',
  },
  systemGroups: {
    withoutStatus: 'Without status',
    withoutStage: 'Without stage',
    withoutExecutor: 'Without executor',
    withoutTag: 'Without tag',
  },
};
