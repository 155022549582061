export default {
  dates: {
    today: 'Today',
    yesterday: 'Yesterday',
    currentWeek: 'This week',
    currentMonth: 'This month',
    currentQuarter: 'This quarter',
  },
  direction: {
    refill: 'Add funds',
    withdraw: 'Charge',
  },
  statuses: {
    completed: 'Paid',
    declined: 'Charging error',
    cancelled: 'Cancelled',
    wait: 'Awaiting payment',
  },
};
