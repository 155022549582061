import session from '@/api/session';

import type { Nullable } from '@/types/utility';

type GetNewsChannel = {
  teamId: number;
  getParams?: {
    page?: number;
  };
};

type PostNewsChannel = {
  teamId: number;
  requestBody: {
    name: string;
  };
};

type EditNewsChannel = {
  teamId: number;
  channelId: number;
  requestBody: {
    name?: string;
    prev_channel?: Nullable<number>;
  };
};

type DeleteNewsChannel = {
  teamId: number;
  channelId: number;
};

export default {
  getFeedsNewsChannel({ teamId, getParams }: GetNewsChannel) {
    return session.get(`/feeds/channels/${teamId}/`, { params: getParams });
  },
  createNewsChannel({ teamId, requestBody }: PostNewsChannel) {
    return session.post(`/feeds/channels/${teamId}/`, requestBody);
  },
  editNewsChannel({ teamId, channelId, requestBody }: EditNewsChannel) {
    return session.patch(`/feeds/channels/${teamId}/${channelId}/`, requestBody);
  },
  deleteNewsChannel({ teamId, channelId }: DeleteNewsChannel) {
    return session.delete(`/feeds/channels/${teamId}/${channelId}/`);
  },
};
