import session from '@/api/session';
import { customField, customFieldToCardTypeField } from '@/models/CustomField';

import ZodHelper from '@/helpers/ZodHelper';

import type { customFieldCodes } from '@/constants/tasks/customFields';

type CustomFieldValue = {
  value: string;
  color?: string;
};
type GetCustomFields = {
  teamId: number;
  getParams: {
    page: number;
    search?: string;
    exclude_datasource?: number;
  };
};
type CreateCustomField = Pick<GetCustomFields, 'teamId'> & {
  requestBody: {
    name: string;
    type: customFieldCodes;
    team: number;
    values?: Array<CustomFieldValue>;
    extras?: Array<string>;
    multiple_choice?: boolean;
    position?: number;
  };
};
type EditCustomField = Pick<GetCustomFields, 'teamId'> & {
  fieldId: string;
  requestBody: {
    extras?: Array<string>;
    name?: string;
    values_to_add?: Array<CustomFieldValue>;
    values_to_remove?: Array<number>;
    project_to_dnd?: number;
    prev_field?: string;
  };
};
type DeleteCustomField = Pick<EditCustomField, 'teamId' | 'fieldId'>;

const customFields = {
  getCustomFields({ teamId, getParams }: GetCustomFields) {
    return ZodHelper.request(
      () => session.get(`teams/customfields/${teamId}/list/`, { params: getParams }),
      ZodHelper.getPaginationModel(customField.array()),
    );
  },
  getCustomFieldsToCardType({ teamId, getParams }: GetCustomFields) {
    return ZodHelper.request(
      () => session.get(`teams/customfields/${teamId}/list/`, { params: getParams }),
      ZodHelper.getPaginationModel(customFieldToCardTypeField.array()),
    );
  },
  createCustomField({ teamId, requestBody }: CreateCustomField) {
    return session.post(`teams/customfields/${teamId}/create/`, requestBody);
  },

  editCustomField({ teamId, fieldId, requestBody }: EditCustomField) {
    return session.patch(`teams/customfields/${teamId}/${fieldId}/`, requestBody);
  },

  deleteCustomField({ teamId, fieldId }: DeleteCustomField) {
    return session.delete(`teams/customfields/${teamId}/${fieldId}/`);
  },
};

export default customFields;
