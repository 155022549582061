import addTime from '@/locales/en/tasks/details/addTime';
import archive from '@/locales/en/tasks/details/archive';
import checklist from '@/locales/en/tasks/details/checklist';
import comments from '@/locales/en/tasks/details/comments';
import description from '@/locales/en/tasks/details/description';
import files from '@/locales/en/tasks/details/files';
import header from '@/locales/en/tasks/details/header';
import properties from '@/locales/en/tasks/details/properties';
import sidebar from '@/locales/en/tasks/details/sidebar';

export default {
  archive,
  header,
  properties,
  description,
  files,
  checklist,
  comments,
  sidebar,
  addTime,

  viewers: 'Task viewers',
};
