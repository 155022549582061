import list from '@/locales/en/updates/list/list';

export default {
  allUpdates: 'All notifications',
  settings: 'Settings',
  mobilePopupTitle: 'Notifications',
  menu: {
    allUpdates: 'All notifications',
    settings: 'Settings',
  },
  header: {
    delete: 'Clear list',
    read: 'Read all',
    confirm: {
      title: 'Are you sure you want to delete all notifications?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
  },
  noUpdates: 'You have no notifications yet',
  tools: {
    mail: 'Mail',
    push: 'Push',
    mobile_push: 'Mobile push',
    shtab: 'Shtab',
    messenger: 'Messenger',
  },
  notifications: {
    team_invite: 'Invited to the team',
    project_invite: 'Invited to the project',
    project_member: 'Accepted an invitation to the project',
    payout_new: 'New payout',
    task_change: 'Card changes',
    task_mention_user: 'New mention in the card',
    task_assign_executor: 'Assigned an executor to a card',
    task_assign_responsible: 'Assigned control to a card',
    task_comment_answer: 'Reply to a comment in a card',
    user_delete_account: 'Member has deleted their account',
    report_new: 'Report ready',
    team_blocked_user: 'The team blocked the user',
    system_notice: 'System notification',
    feed_create: 'Feed created',
    feed_tag: 'Featured in the feed',
    feed_comment_reply: 'Reply to a comment in a feed',
    feed_comment_create: 'Post comment',
    feed_like_create: 'Like the post',
    feed_poll_vote: 'Voted in the poll',
    feed_poll_vote_anon: 'Voted in the poll anonymously',
    new_task: 'New card',
    new_comment: 'New comment',
    task_deadline_attention: 'Deadline notification',
  },
  list,
};
