export default {
  title: 'Adding time',
  start: 'Start',
  end: 'End',
  date: 'Date',
  datasource: 'Database',
  task: 'Card',
  noTasks: 'No cards',
  cancel: 'Cancel',
  add: 'Add',
  edit: 'Edit',
  notification: 'Time has been added successfully',

  select: {
    noResults: 'No results',
    noOptions: 'No other variants',
  },
};
