import { z } from 'zod';

import { customFieldBase, customFieldValue } from '@/models/CustomField';
import { user } from '@/models/User';

export const taskCustomFieldValue = z
  .object({
    id: z.number(),
    value: z.string().nullable(),
    file: z.string().nullable(),
    file_extension: z.string().nullable(),
    filename: z.string().nullable(),
    size: z.string().nullable(),
    task: z.number(),
    user: user.nullable(),
    option: customFieldValue.nullable(),
    extra: z.string().nullable(),
  })
  .transform(({ file, file_extension, filename, size, ...value }) => ({
    ...value,
    file: { url: file, extension: file_extension, name: filename, size, valueId: value.id },
  }));

export const taskCustomField = customFieldBase
  .pick({ id: true, name: true, type: true, extras: true, multiple_choice: true, values: true })
  .merge(z.object({ custom_field_values: z.array(taskCustomFieldValue) }))
  .transform(({ multiple_choice, custom_field_values, ...value }) => ({
    ...value,
    isMultiple: multiple_choice,
    fieldValue: custom_field_values.length ? custom_field_values : null,
  }));

export type TaskCustomField = z.infer<typeof taskCustomField>;
export type TaskCustomFieldValue = z.infer<typeof taskCustomFieldValue>;
