import { type App, nextTick } from 'vue';

export const initScrollActive = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.directive('scroll-active', {
      beforeMount(element, { value }) {
        const { hash, container } = value;

        nextTick(() => {
          const scrollContainer = document.querySelector(container);

          const handlerScroll = (e: Event) => {
            const scrollToElement = scrollContainer.querySelector(hash);
            if (!scrollToElement) return;

            const topPosition = scrollToElement.offsetTop - 10;
            const bottomPosition = topPosition + scrollToElement.offsetHeight;
            const target = e.target as HTMLElement;

            if (target.scrollTop > topPosition && target.scrollTop < bottomPosition) {
              element.classList.add('scroll-active');
            } else {
              element.classList.remove('scroll-active');
            }
          };

          scrollContainer.addEventListener('scroll', handlerScroll, false);
        });
      },
    });

    resolve();
  });
};
