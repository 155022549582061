export default {
  monday: 'MON',
  mondayFull: 'Monday',

  tuesday: 'TUE',
  tuesdayFull: 'Tuesday',

  wednesday: 'WED',
  wednesdayFull: 'Wednesday',

  thursday: 'THU',
  thursdayFull: 'Thursday',

  friday: 'FRI',
  fridayFull: 'Friday',

  saturday: 'SAT',
  saturdayFull: 'Saturday',

  sunday: 'SUN',
  sundayFull: 'Sunday',
};
