// https://github.com/Kelin2025/vue-responsive-components
/**
 * @example
 * <div class="example" v-responsive="{ small: el => el.width <= 500 }"></div>
 */
import type { App } from 'vue';

import debounce from '@/utils/debounce';

export const initResponsive = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.directive('responsive', {
      mounted(el, conds) {
        const handleResize = debounce((entries) => {
          const cr = entries[0].contentRect;
          for (const breakpoint in conds.value) {
            if (conds.value[breakpoint](cr)) {
              el.classList.add(breakpoint);
            } else {
              el.classList.remove(breakpoint);
            }
          }
        }, 200);

        const observer = new ResizeObserver(handleResize);
        observer.observe(el);
      },
    });

    resolve();
  });
};
