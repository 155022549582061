import { setLocale } from '@vee-validate/i18n';
import { nextTick } from 'vue';

import i18n from '@/plugins/i18n';

import { Locales } from '@/types/types';

// список локализаций, которые пред-загружены
const loadedLanguages = [import.meta.env.VITE_DEFAULT_LOCALE];

const loadLocale = async (locale: Locales) => {
  try {
    if (loadedLanguages.includes(locale)) {
      return;
    }

    // Если локализация ещё не была загружена
    const localeMessages = await import(`../locales/${locale}/index.ts`);

    i18n.setLocaleMessage(locale, localeMessages.default);
    loadedLanguages.push(locale);
  } catch (e) {
    console.error('Ошибка при загрузке выбранной локализации!');
    throw e;
  }
};

export default class I18nHelper {
  public static isSupportedLocale(locale: any): locale is Locales {
    return Object.values(Locales).includes(locale);
  }

  public static get selectedLocale(): Locales {
    return i18n.locale.value;
  }

  public static async setLocale(locale: Locales): Promise<void> {
    if (i18n.locale.value === locale) return;

    try {
      await loadLocale(locale);
      i18n.locale.value = locale;
      setLocale(locale);
      document.documentElement.setAttribute('lang', locale);
      return nextTick();
    } catch (e) {
      throw new Error('Unsupported locale');
    }
  }

  public static getApiLocale(locale: Locales) {
    switch (locale) {
      case 'ru':
        return import.meta.env.VITE_LOCATION;
      case 'en':
        return import.meta.env.VITE_LOCATION_EN;
      default:
        return import.meta.env.VITE_LOCATION_EN;
    }
  }
}
