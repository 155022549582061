export default {
  title: 'Comments',

  deleted: 'Message deleted',
  edited: 'edited',
  cancel: 'Cancel',
  add: 'Leave a comment',

  edit: 'Edit',
  delete: 'Delete',

  sorting: {
    newFirst: 'New ones first',
    oldFirst: 'Old ones first',
  },
};
