export default {
  title: 'Counterparts',
  reference:
    'Add partners, contractors and other interested parties to further refine the details of financial transactions',
  listEmpty: "You don't have any partners added yet",
  selectActions: {
    active: 'Active',
    archived: 'Archived',
  },
  counterpartActions: {
    unload: 'Unload',
    reset: 'Reestablish',
    archive: 'Archive',
    delete: 'Delete',
    counterpartsSelected: 'Elements selected: {count}',
  },
  documentActions: {
    unload: 'Unload',
    reset: 'Restore',
    archive: 'Archive',
    delete: 'Delete',
  },
  columns: {
    name: 'Title',
    group: 'Group',
    account: 'Settlement Account',
  },
  requisites: {
    requisites: 'Requisites',
    title: 'Title',
    group: 'Group',
    taxpayerNumber: 'Tax Identification Number',
    registrationCode: 'Tax Registration Reason Code',
    registrationNumber: 'Registration Number',
    organizationClassifier: 'Organization Classifier',
    fullName: 'Full Name for Signature',
    inPersonOf: 'In Person Of',
    website: 'Website',
    legalAddress: 'Legal Address',
    actualAddress: 'Actual Address',
  },
  settlementAccount: {
    settlementAccount: 'Settlement Account',
    title: 'Title',
    country: 'Country',
    city: 'City',
    postalCode: 'Postal Code',
    organisationRegister: 'Register of Legal Entities',
    correspondentAccount: 'Correspondent Account',
    taxpayerNumber: 'Tax Identification Number',
    bankId: 'Bank Identification Code',
    IBAN: 'IBAN',
    holderName: 'Holder Name',
    legalAddress: 'Legal Address',
    phone: 'Phone',
    bankAddress: 'Bank Address',
    PAN: 'PAN',
  },
  placeholder: 'Configure the necessary parameters to have them always at hand',

  modalActions: {
    save: 'Save',
    cancel: 'Cancel',
  },
  addCounterpart: {
    title: 'Adding a counterpart',
    requiredMessage: 'Fill in the required fields: Name, Group',
  },
  editCounterpart: {
    title: 'Editing a counterpart',
  },
  addNewField: 'New field',
  addFieldButton: 'Field',
  notSelected: 'Not selected',
  contacts: {
    title: 'Contacts',
    newContact: 'New contact',
    noContacts: "You don't have any contacts, click New Contact to create one",

    contactPerson: 'Contact Person',
    position: 'Position',
    phone_numbers: 'Phone',
    emails: 'E-mail',
    comment: 'Comment',

    fio: 'Full name',
    positionNotSpecified: 'Position not specified',
  },
  counterpartAccount: {
    counterpartAccount: 'Settlement Account',
    accountMin: 'Account',
    newAccount: 'New Settlement Account',
    deleteAccount: 'Delete Account',
    makeAsDefault: 'Set as Default Settlement Account',
    addAccount: 'Add Account',
    saveAccount: 'Save Account',
    cancel: 'cancel',

    title: 'Title',
    phone: 'Phone',
    country: 'Country',
    bankAddress: 'Bank Address',
    city: 'City',
    organisationRegister: 'Register of Legal Entities',
    postalCode: 'Postal Code',
    IBAN: 'IBAN',
    PAN: 'PAN',
    correspondentAccount: 'Correspondent Account',
    ABA: 'ABA',
    taxpayerNumber: 'Tax Identification Number',
    SWIF: 'SWIF/BIC code',
    BIC: 'BIC',
    IFSC: 'IFSC',
    legalAddress: 'Legal Address',
    MFO: 'MFO',
    holderName: 'Holder Name',

    fillField: 'Fill in the required fields: Title, Settlement Account',
    fillName: 'Fill in the required fields: Name',

    newField: 'New field',
    deleteAccountConfirm: 'Do you really want to delete the current account?',
    btnDelete: 'Delete',
    btnCancel: 'Cancel',
  },
  documents: {
    title: 'Documents',
    newDocument: 'New document',
    noDocuments: 'No documents added',

    noNumber: 'No number',

    name: 'Title',
    number: 'Number',
    type: 'Type',
    direction: 'Direction',

    incoming: 'Incoming',
    outgoing: 'Outgoing',

    invoice: 'Invoice',
    account: 'Account',
    contract: 'Contract',
    act: 'Act',
    waybill: 'Waybill',
    report: 'Report',

    download: 'Download',
    archive: 'Archive',
    restore: 'Restore',
    delete: 'Delete',
    documentsSelected: 'Selected documents: {count}',

    loading: 'Uploading files...',
    serverWaiting: 'Waiting for server response...',
  },

  operations: 'Counterparty operations',
  archiveAccounts: 'Archive counterparty',
  archivePlaceholder: 'When a counterparty is archived, all related operations will be archived',
  resetAccounts: 'Restoring a counterparty',
  resetPlaceholder: 'When restoring a counterparty, all related transactions will be restored',
  deleteAccounts: 'Deleting a counterparty',
  deletePlaceholder: 'When deleting a counterparty, all related transactions will be deleted',
  archive: 'Archive counterparty and transactions',
  reset: 'Restore counterparty and transactions',
  delete: 'Delete counterparty and transactions',
};
