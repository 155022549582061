import session from '@/api/session';
import { taskType, taskTypeFields } from '@/models/CardTypes';

import ZodHelper from '@/helpers/ZodHelper';

export interface CardTypesParams {
  page: number;
  search?: string;
}
export interface TaskTypeData {
  id?: number;
  name: string;
  data: Object;
  icon: string;
  comment?: string;
}
export interface TaskTypeArguments {
  teamId: number;
  getParams: CardTypesParams;
}

export interface TaskTypeGetArguments {
  teamId: number;
  cardTypeId: number;
}

export interface TaskTypeFieldsArguments {
  teamId: number;
  cardTypeId: number;
  fieldId?: number;
  data: { field: string | null; default_field: string | null; prev_value: number | null };
}

export default {
  getCardTypes({ teamId, getParams }: TaskTypeArguments) {
    return ZodHelper.request(
      () => session.get(`/teams/task-type/${teamId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(taskType.array()),
    );
  },
  getCardType({ teamId, cardTypeId }: { teamId: number; cardTypeId: number }) {
    return ZodHelper.request(() => session.get(`/teams/task-type/${teamId}/${cardTypeId}/`), taskType);
  },
  getCardTypeFields({ teamId, cardTypeId }: TaskTypeGetArguments) {
    return ZodHelper.request(
      () => session.get(`/teams/task-type/${teamId}/${cardTypeId}/fields/`),
      taskTypeFields.array(),
    );
  },
  deleteCardType({ teamId, cardTypeId }: TaskTypeGetArguments) {
    return session.delete(`/teams/task-type/${teamId}/${cardTypeId}/`);
  },
  setCardType({ teamId, requestBody }: { teamId: number; requestBody: TaskTypeData }) {
    return session.post(`/teams/task-type/${teamId}/`, requestBody);
  },
  updateTeamCardType({
    teamId,
    requestBody,
    cardTypeId,
  }: {
    teamId: number;
    requestBody: TaskTypeData;
    cardTypeId: number;
  }) {
    return session.patch(`/teams/task-type/${teamId}/${cardTypeId}/`, requestBody);
  },
  duplicateTeamCardType({ cardTypeId, teamId }: TaskTypeGetArguments) {
    return session.post(`/teams/task-type/${teamId}/clone/`, { task_type: cardTypeId });
  },
  mergeTeamCardType({ teamId, cardTypeId, mergeToId }: { teamId: number; cardTypeId: number; mergeToId: number }) {
    return session.post(`/teams/task-type/${teamId}/merge/${cardTypeId}/`, { merge_to: mergeToId });
  },
  deleteTeamCardTypeField({ teamId, cardTypeId, fieldId }: { teamId: number; cardTypeId: number; fieldId: number }) {
    return session.delete(`/teams/task-type/${teamId}/${cardTypeId}/fields/${fieldId}/`);
  },
  setTeamCardTypeField({ teamId, cardTypeId, fieldId, data }: TaskTypeFieldsArguments) {
    return session.patch(`/teams/task-type/${teamId}/${cardTypeId}/fields/${fieldId}/`, data);
  },
  addTeamCardTypeField({ teamId, cardTypeId, data }: TaskTypeFieldsArguments) {
    return session.post(`/teams/task-type/${teamId}/${cardTypeId}/fields/`, data);
  },
  accessTeamCardTypeField({
    teamId,
    cardTypeId,
    fieldId,
    data,
  }: {
    teamId: number;
    cardTypeId: number;
    fieldId: number;
    data: {
      users_with_access: Array<number> | null;
      departments_with_access: Array<number> | null;
      positions_with_access: Array<number> | null;
    };
  }) {
    return session.post(`/teams/task-type/${teamId}/${cardTypeId}/fields/${fieldId}/access/`, data);
  },
};
