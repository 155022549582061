import CRUD from './CRUD';
import customFields from './customFields';
import { apiDataSourceFiles } from './files';
import { stages } from './stages';

const apiDataSources = {
  ...CRUD,
  ...customFields,
  ...stages,
  ...apiDataSourceFiles,
};

export default apiDataSources;
