import session from '@/api/session';

export default class AxiosHelper {
  public static getHeaders(key: string): string | number | boolean {
    return session.defaults.headers.common[key];
  }

  public static setHeaders(key: string, value: string): void {
    session.defaults.headers.common[key] = value;
  }

  public static deleteHeaders(key: string): void {
    delete session.defaults.headers.common[key];
  }
}
