export default {
  team: 'Team',
  tariff: 'Tariff',
  members: 'Members',
  finances: 'Finances',
  billing: 'Billing',
  billingPayments: 'Payments',
  billingPayers: 'Payers',
  billingCards: 'Cards',
  general: 'General',
  imports: 'Import data',
  integrations: 'Integrations',
  handbook: {
    dataSources: 'Databases',
    seoDataSources: 'Databases - {team}',
    seoDatasourceDetails: '{datasource} - {team}',
    statuses: 'Statuses',
    seoStatuses: 'Statuses - {team}',
    tags: 'Tags',
    seoTags: 'Tags - {team}',
    customFields: 'Custom fields',
    cardTypes: 'Card types',
    seoCardType: 'Card types - {team}',
    seoCustomFields: 'Custom fields - {team}',
  },
};
