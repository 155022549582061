import overlays from '@/store/modules/modals/modules/overlays';

const state = {
  modalCurrent: '',
  modalPayload: '',
  modalType: '',
  modalIndex: 3,

  // {name, payload}
  modalStack: [],
};

const getters = {
  isShowModal(state) {
    return !!state.modalCurrent;
  },
};

const mutations = {
  setModalCurrent(state, modalName) {
    state.modalCurrent = modalName;
  },
  setModalPayload(state, payload) {
    state.modalPayload = payload;
  },
  setModalType(state, type) {
    state.modalType = type;
  },
  setModalIndex(state, index) {
    state.modalIndex = index;
  },

  pushModal(state, modal) {
    state.modalStack.push(modal);
  },
  popModal(state) {
    state.modalStack.pop();
  },

  resetModalCurrent(state) {
    state.modalCurrent = '';
  },
  resetModalStack(state) {
    state.modalStack = [];
  },
  resetModalPayload(state) {
    state.modalPayload = '';
  },
};

const actions = {
  RESET({ commit, dispatch }) {
    commit('resetModalCurrent');
    commit('resetModalPayload');
    commit('resetModalStack');
    dispatch('overlays/RESET');
  },

  SHOW_MODAL({ commit }, { name, payload = {}, type = 'default', zIndex = 3 }) {
    commit('setModalCurrent', name);
    commit('setModalPayload', payload);
    commit('setModalType', type);
    commit('setModalIndex', zIndex);
    commit('resetModalStack');
  },
  CLOSE_MODAL({ commit }) {
    commit('setModalCurrent', null);
    commit('resetModalStack');
  },
};

export default {
  namespaced: true,

  modules: {
    overlays,
  },

  state,
  getters,
  actions,
  mutations,
};
