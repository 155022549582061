export default {
  title: 'Articles',
  reference: 'Indicate expense items to control further cash flow',
  columns: {
    title: 'Title',
    activity: 'Activity type',
    comment: 'Comment',
  },
  activity: {
    operational: 'Operational',
    financial: 'Financial',
    investment: 'Investment',
  },
  section: {
    refill: 'Refills',
    debit: 'Write-offs',
    transfer: 'Transfers',
  },
  confirm: {
    title: 'Do you really want to delete the expense "{name}"?',
    btnDelete: 'Delete',
    btnCancel: 'Cancel',
  },

  placeholder: 'Enter title',
  emptyActivity: 'Not selected',
  emptyComment: 'Not added',
  systemArticleHover: 'SystemExpense. Cannot be modified or deleted',
  createSubArticle: 'Create sub expense',
  showSubArticles: 'Show sub expenses',
  search: 'Expanses search',
};
