export default {
  projects: 'Projects',
  seoProjects: 'Active projects - ',
  archiveProjects: 'Projects archive',
  seoArchiveProjects: 'Projects archive - ',
  archive: 'Archive',
  stages: 'Stages',
  seoStages: 'Project stages - {name}',
  templates: 'Templates',
  seoTemplates: 'Project templates - ',
};
