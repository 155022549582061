const createInitialState = () => ({
  // Загрузка всего приложения
  app: false,
  // Загрузка задач выбранного проекта (только в vuex)
  tasksProject: false,
  // Загрузка детальной информации о задаче, включая комментарии, чеклист + члены проекта, так как это наобходимо для приглашения в команду
  taskDetails: false,
  // Загрузка оплат
  payouts: false,
  // Загрузка стадия
  stages: false,
  // Загрузка скриншотов к задача
  screenshots: false,
  // Загрузка обновлений (нотификаций)
  updates: false,
  // Загрузка отчетов
  reports: false,
  // Загрузка шаблонов
  templates: false,
  // Загрузка шаблона
  template: false,
  // Загрузка деталей шаблона
  templatesDetails: false,
  // Загрузка платежей
  payments: false,
  // Загрузка файлов проекта
  projectFiles: false,
  // Загрузка новостей команды
  feeds: true,
  // Загрузка обновлений новостей команды
  feedsUpdates: false,
});

const state = createInitialState();

const getters = {
  isLoading(state) {
    return (title) => state[title];
  },
};

const mutations = {
  setLoading(state, { title, isLoading }) {
    state[title] = isLoading;
  },
  startLoading(state, title) {
    state[title] = true;
  },
  finishLoading(state, title) {
    state[title] = false;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },
};

export default {
  namespaced: true,

  state,
  getters,
  mutations,
  actions,
};
