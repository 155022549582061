import type { AxiosRequestConfig } from 'axios';
import { z } from 'zod';

import session from '@/api/session';
import { teamStatus } from '@/models/teams/TeamStatus';

import ZodHelper from '@/helpers/ZodHelper';

import type { ViewScreens } from '@/constants/views/viewScreens';

import getRequestUrl from '../utils/getRequestUrl';

import type { Nullable } from '@/types/utility';

type GetStatuses = {
  teamId: number;
  getParams: {
    search?: string;
    exclude_datasource?: number;
    view?: number;
    type?: ViewScreens;
  };
};
type GetStatusesPaginated = GetStatuses & {
  getParams: {
    page: number;
  };
  config: Pick<AxiosRequestConfig, 'signal'>;
};

type CreateStatus = {
  teamId: number;
  requestBody: {
    team: number;
    title: string;
    color: string;
  };
};

type EditStatus = Omit<CreateStatus, 'requestBody'> & {
  statusId: number;
  requestBody: CreateStatus['requestBody'] | { prev_value: Nullable<number> };
};
type MergeStatus = Pick<EditStatus, 'teamId' | 'statusId'> & {
  requestBody: {
    merge_to: number;
  };
};
type DeleteStatus = Pick<EditStatus, 'teamId' | 'statusId'>;

const apiStatuses = {
  getStatusesPaginated({ teamId, getParams, config }: GetStatusesPaginated) {
    return ZodHelper.request(
      () => session.get(`/teams/status/${teamId}/`, { params: getParams, ...config }),
      ZodHelper.getPaginationModel(teamStatus.array()),
    );
  },
  getStatuses({ teamId, getParams }: GetStatuses) {
    const sharedUrl = `/shared/views/${teamId}/${getParams.view}/statuses/`;
    const defaultUrl = `/teams/status/${teamId}/no_paginated/`;

    return ZodHelper.request(
      () => session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams }),
      teamStatus
        .innerType()
        .omit({ tasks_count: true })
        .merge(z.object({ view_status_id: z.number().optional() }))
        .transform(({ view_status_id, ...rest }) => ({ ...rest, viewStatusId: view_status_id }))
        .array(),
    );
  },

  createStatus({ teamId, requestBody }: CreateStatus) {
    return session.post(`/teams/status/${teamId}/`, requestBody);
  },

  editStatus({ teamId, statusId, requestBody }: EditStatus) {
    return session.patch(`/teams/status/${teamId}/${statusId}/`, requestBody);
  },
  mergeStatus({ teamId, statusId, requestBody }: MergeStatus) {
    return session.post(`/teams/status/${teamId}/merge/${statusId}/`, requestBody);
  },

  deleteStatus({ teamId, statusId }: DeleteStatus) {
    return session.delete(`/teams/status/${teamId}/${statusId}/`);
  },
};

export default apiStatuses;
