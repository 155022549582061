import { apiTaskTemplatesCRUD } from './CRUD';
import { apiTaskTemplateChecklist } from './checklist';
import { apiTaskTemplateExecutor } from './executor';
import { apiTaskTemplateFiles } from './files';
import { apiTaskTemplateResponsible } from './responsible';
import { apiTaskTemplateUsers } from './users';

export const apiTaskTemplates = {
  ...apiTaskTemplatesCRUD,
  ...apiTaskTemplateChecklist,
  ...apiTaskTemplateFiles,
  ...apiTaskTemplateExecutor,
  ...apiTaskTemplateResponsible,
  ...apiTaskTemplateUsers,
};
