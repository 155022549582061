export default {
  topUpBalance: 'Add funds',

  payer: 'Payer',
  newPayer: 'New payer',

  payByCard: 'Pay by card',
  newCard: 'New card',

  paymentAmount: 'Payment amount',
  enterAmount: 'Enter an amount from 100 to 2 000 000 {currency}',
  pay: 'Pay',

  success: {
    title: 'Successfully added funds',
    sendEmail: 'You will receive payment confirmation to your email address.',
    checkPayments: 'You can view all of your payments from the Transactions page.',
    goToPayments: 'View transactions',
  },

  error: {
    titleCard: 'Error adding card',
    titleTopUp: 'Error when adding funds',
    tryAgain: 'Try again',
    repeat: 'Repeat payment',
  },

  toSettings: 'Return to Settings',
};
