export default {
  title: 'Upgrade storage',
  connect: 'Activate',

  connecting: 'After connecting {space} GB, {sum} will be debited from your balance every month',

  error: {
    title: 'Error upgrading storage',
    message: 'Check your balance and try again',
  },

  success: 'You have successfully activated additional storage package of {space} GB',
};
