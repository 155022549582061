export const portalNames = {
  taskCardDueDate: (taskId: number) => `taskCardDueDate-${taskId}`,
  taskCardPriority: (taskId: number) => `taskCardPriority-${taskId}`,
  taskCardExecutors: (taskId: number) => `taskCardExecutors-${taskId}`,
  taskCardTags: (taskId: number) => `taskCardTags-${taskId}`,
  taskCardSubtasks: (taskId: number) => `taskCardSubtasks-${taskId}`,
  headerRight: 'headerRight',
  sidebarTitle: 'sidebarTitle',
  sidebarTab: 'sidebarTab',
  switchToggle: 'switchToggle',
  accountsFooter: 'accountsFooter',
  accountFilters: 'accountFilters',
  transactionsFooter: 'transactionsFooter',
  transactionFilters: 'transactionFilter',
  transactionsCount: 'transactionsCount',
  counterpartsFooter: 'counterpartsFooter',
  counterpartsFilter: 'counterpartsSelect',

  breadcrumbs: {
    billingPayerAction: 'billingPayerAction',
    teamSettingsMember: 'teamSettingsMember',
    project: 'project',
    listingTypes: 'listingTypes',
    task: 'task',
    feedNumber: 'feedNumber',
    activityUser: 'activityUser',
    reportProject: 'reportProject',
    view: 'view',
    cardType: 'cardType',
    datasource: 'datasource',
  },

  task: {
    checklist: 'checklist',
    files: 'files',
    subtasks: 'subtasks',
  },
};
