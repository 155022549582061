export default {
  title: 'Files',
  upload: 'Upload file',
  filesUpload: 'Files',
  downloadAll: 'Download all',
  drag: 'or drag it here',
  showAll: 'Show all',
  collapse: 'Minimize',
  error: 'Error when deleting file',
  interruptError: 'Failed to upload file.',
  downloadSelected: 'Download',
  downloadSelectedFiles: 'Download {count} files',
  deleteSelectedFiles: 'Delete {count} files',
  deleteSelected: 'Delete',
  confirmDeleteSelectedFiles: 'Delete {count} files?',
  cancel: 'Cancel',
};
