export default {
  noAccess: {
    title: 'You have no access',
    action: 'Go to main page',
  },
  notFound: {
    title: 'page not found',
    action: 'Go to projects',
  },
  teamBlocked: {
    paidEnded: 'Paid period has ended',
    trialEnded: 'Trial period is over',
    message: 'To continue using Shtab, top up balance or change plan',
    action: 'Manage team plan',
  },
  pageMobileOnly: {
    title: 'This functionality is available in the mobile app',
    downloadAppButton: 'Download App',
    goBack: 'Return',
  },
  inviteError: {
    invalidToken: 'The invitation link is invalid',
    invalidDatesToken: 'Invitation link is invalid or expired',
  },
};
