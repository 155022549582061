export default {
  payout: 'Payout',
  openAsPage: 'Open as page',

  confirmPayout: 'Confirm payment',
  confirmReceipt: 'Confirm receipt of payment',
  payoutPaid: 'Paid',
  paymentConfirmed: 'Payout confirmed',
  newPayout: 'New payout! Awaiting payout',
  payoutConfirmed: 'Payout confirmed',

  noItems: 'No results. Please modify your search',
  notification: 'A confirmed payout cannot be deleted',

  noData: {
    title: 'You haven’t had any payouts yet',
    description: 'Your team lead will calculate your salary based on ',
    tracker: 'tracked time',
    notification: 'You will get a notification as soon as you receive your first payment.',
  },

  total: {
    totalAmount: 'Total amount',
    commission: 'Commission',
    amount: 'Payouts total',
    time: 'Total time',
    hours: '{hours}h {min}m {sec}s',
  },
  properties: {
    executor: 'Assignee',
    period: 'Working period',
    status: 'Status',
    rate: 'Rate',
    rateValue: ' per hour',

    paid: 'Paid',
    notPaid: 'Not paid',
  },
  tasks: {
    task: 'Card',
    datasource: 'Database',
    hours: 'Working hours',
    total: 'Total due',

    noData: '0 cards have been resolved within the selected period',
  },

  confirm: {
    title: 'Are you sure you want to delete the payout?',
    description: 'You will be unable to restore the payout',
    btnCancel: 'Cancel',
    btnConfirm: 'Delete',
  },

  executors: {
    noData: 'No assignees. Try to change filters',
  },
};
