export default {
  TEAM_SETTINGS: 'TeamSettings',
  TEAM_SETTINGS_TARIFF: 'TeamSettings.Tariff',
  TEAM_SETTINGS_TARIFF_TARIFFS: 'TeamSettings.Tariff.Tariffs',
  TEAM_SETTINGS_TARIFF_BALANCE: 'TeamSettings.Tariff.Balance',
  TEAM_SETTINGS_TARIFF_INVOICE: 'TeamSettings.Tariff.Invoice',
  TEAM_SETTINGS_MEMBERS: 'TeamSettings.Members',
  TEAM_SETTINGS_MEMBERS_MEMBER: 'TeamSettings.Members.Member',
  TEAM_SETTINGS_BILLING: 'TeamSettings.Billing',
  TEAM_SETTINGS_BILLING_PAYMENTS: 'TeamSettings.Billing.Payments',
  TEAM_SETTINGS_BILLING_PAYERS: 'TeamSettings.Billing.Payers',
  TEAM_SETTINGS_BILLING_PAYERS_PAYER: 'TeamSettings.Billing.Payers.Payer',
  TEAM_SETTINGS_BILLING_CARDS: 'TeamSettings.Billing.Cards',
  TEAM_SETTINGS_BILLING_CARDS_CARD: 'TeamSettings.Billing.Cards.Card',
  TEAM_SETTINGS_GENERAL: 'TeamSettings.General',
  TEAM_SETTINGS_IMPORTS: 'TeamSettings.TeamImports',
  TEAM_SETTINGS_INTEGRATIONS: 'TeamSettings.TeamIntegrations',
  HANDBOOK_DATASOURCE: 'HandbookDatasource',
  HANDBOOK_DATASOURCE_DETAILS: 'HandbookDatasourceDetails',
  HANDBOOK_CARD_TYPE: 'HandbookCardType',
  HANDBOOK_CARD_TYPE_DETAILS: 'HandbookCardTypeDetails',
  HANDBOOK_STATUSES: 'HandbookStatuses',
  HANDBOOK_TAGS: 'HandbookTags',
  HANDBOOK_CUSTOM_FIELDS: 'HandbookCustomFields',
};
