import pageNames from '@/router/helper/pageNames';

import { portalNames } from '@/constants/portals';

export default [
  {
    path: 'task/:taskId',
    name: pageNames.shared.SHARED_TASK,
    meta: {
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.task,
        },
      ],
    },
    component: () => import('@/pages/shared/SharedTask.vue'),
    props: (route) => ({ taskId: Number(route.params.taskId) }),
  },
  {
    path: 'team/:teamId/view/:viewId',
    name: pageNames.shared.SHARED_VIEW,
    meta: {
      breadcrumbs: [
        {
          portal: portalNames.breadcrumbs.view,
        },
      ],
    },
    component: () => import('@/pages/shared/SharedView.vue'),
    props: (route) => ({
      viewId: Number(route.params.viewId),
      teamId: Number(route.params.teamId),
      sortingCode: String(route.query.sorting ?? ''),
    }),
  },
];
