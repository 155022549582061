export default {
  loadPhoto: 'Upload photo',
  changePhoto: 'Change',
  deletePhoto: 'Delete avatar',
  deleteAvatarConfirm: {
    title: 'Are you sure you want to delete your avatar?',
    btnCancel: 'Cancel',
    btnConfirm: 'Delete',
  },
  photo: 'photo',
  teamName: 'Team name',
  language: 'Language',
  deleteTasks: 'Can usual member remove cards?',
  yes: 'Yes',
  no: 'No',
  avatar: 'Team avatar',
  defaultCurrency: 'The default currency',
  billing: {
    tariff: 'Plan',
    plan: 'Plan',
    count: 'Number of members',
    space: 'Storage',
    gb: ' GB',
    fileSize: 'Files size',
    withoutRestrictions: 'unlimited',

    balance: 'Balance',

    sumPayment: 'amount to write off',

    tariffReference: {
      month: 'Plan for {description}: {sum} per month for each user',
      action: 'Connect another plan',
    },

    autoPay: {
      title: 'Payment for activated options will be written off automatically from your default card',
      action: 'View activated options',
    },
  },
  deleteTeam: {
    button: 'Delete team',
    btnCancel: 'Cancel',
    bthConfirm: 'Delete',
    deleteConfirmTitle: 'Do you really want to remove the command «{name}»',
    deleteDataConfirm: 'All your data will be lost and you will not recover it',
    enterTeamTitleConfirm: 'Enter the name of the team you are going to delete',
    emailConfirm: 'Confirm the deletion of team in the letter in your mailbox',
    close: 'Close',
  },
};
