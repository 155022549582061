import i18n from '@/plugins/i18n';

import apiTasks from '@/api/tasks';

import { BillingHelper } from '@/utils/BillingHelper';
import directS3Upload from '@/utils/directS3Upload';

import { maxFileSizeAtFreePlans } from '@/constants/billing/maxFileSizeAtFreePlans';

const root = { root: true };

const state = {
  files: [],
  loadProgress: [],
};

const mutations = {
  setFiles(state, files) {
    state.files = files;
  },
  addFiles(state, files) {
    state.files = [...files, ...state.files];
  },
  deleteFile(state, fileId) {
    state.files = state.files.filter((file) => file.id !== fileId);
  },
  deleteFiles(state, filesId) {
    state.files = state.files.filter((file) => !filesId.includes(file.id));
  },

  setLoadProgress(state, progress) {
    const item = state.loadProgress.find((item) => item.name === progress.name);

    if (item) {
      if (progress.count < 95) item.count = progress.count;

      if (progress.isFinish) {
        const index = state.loadProgress.findIndex((item) => item.name === progress.name);
        state.loadProgress.splice(index, 1);
      }
    } else if (progress.count < 100) {
      state.loadProgress.push(progress);
    }
  },

  removeLoadProgress(state, name) {
    state.loadProgress = state.loadProgress.filter((progress) => progress.name !== name);
  },

  reset(state) {
    state.files = [];
  },
};

const actions = {
  async ADD_FILES({ commit, rootGetters, dispatch }, requestData) {
    try {
      if (!BillingHelper.isFileSizeValid(requestData.fd.get('file'), rootGetters['billing/planType'])) {
        dispatch(
          'notifications/SHOW_ERROR',
          i18n.t('notifications.fileUploadLimit', { mbSize: Math.floor(maxFileSizeAtFreePlans / 1000000) }),
          root,
        );
        return;
      }

      let result;

      if (JSON.parse(import.meta.env.VITE_DIRECT_S3_UPLOAD)) {
        const { file } = await directS3Upload({
          id: requestData.taskId,
          preSignRequest: apiTasks.preSignFileForS3,
          file: requestData.fd.get('file'),
        });
        commit('addFiles', [file]);
        result = [file];
      } else {
        const { data } = await apiTasks.addTaskFile(requestData);
        commit('addFiles', data.file);
        result = data.file;
      }

      if (requestData.config && requestData.config.name) {
        commit('setLoadProgress', { name: requestData.config.name, count: 100, isFinish: true });
      }
      return result;
    } catch (e) {
      commit('removeLoadProgress', requestData.config.name);
      console.error(e);
      throw e;
    }
  },
  async GET_FILES({ commit }, taskId) {
    try {
      const { data: files } = await apiTasks.getTaskFiles(taskId);

      commit('setFiles', files.reverse());
    } catch {
      console.error('Ошибка при получении файлов');
    }
  },
  async DELETE_FILE({ state, commit }, requestData) {
    const filesFallback = [...state.files];
    try {
      commit('deleteFile', requestData.fileId);
      await apiTasks.deleteTaskFile(requestData);
    } catch (e) {
      console.error('Ошибка при удалении файла', e);
      commit('setFiles', filesFallback);
      throw e;
    }
  },
  async DELETE_FILES({ state, commit }, requestData) {
    const filesFallback = [...state.files];
    try {
      commit('deleteFiles', requestData.filesId);
      await apiTasks.deleteSelectedFiles(requestData);
    } catch (e) {
      console.error('Ошибка при удалении файла', e);
      commit('setFiles', filesFallback);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
