import appendGetParams from '@/router/helper/appendGetParams';
import * as constants from '@/router/helper/constants';
import deleteQueryParams from '@/router/helper/deleteQueryParams';
import goBack from '@/router/helper/goBack';
import goTo404Page from '@/router/helper/goTo404Page';
import goToDefaultPage from '@/router/helper/goToDefaultPage';
import goToNoAccessPage from '@/router/helper/goToNoAccessPage';
import goToRestoreAccount from '@/router/helper/goToRestoreAccount';
import pageNames from '@/router/helper/pageNames';

export const routerHelper = {
  appendGetParams,
  goToDefaultPage,
  deleteQueryParams,
  goBack,
  constants,
  pageNames,
  goToRestoreAccount,
  goToNoAccessPage,
  goTo404Page,
};

// Очень странное поведение. При импорте изначально undefined, поэтому нельзя использовать при инициализации роутера
// Приходится импортировать pageNames напрямую
export default routerHelper;
