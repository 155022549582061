import store from '@/store';

const createPermissionHook =
  (permission, fallbackPage, module = 'user') =>
  (to, from, next) => {
    // Если права отсутствуют и приложение только что было запущено, то используется резервная страница,
    // если переход был с другой страницы, то навигация отклоняется и всплывает сообщение об ошибке
    if (store.getters[`${module}/permissions/${permission}`]) {
      next();
    } else if (from.name) {
      console.warn('show error');
    } else {
      // Также показать пользователю, что был редирект
      next(fallbackPage);
    }
  };

export { createPermissionHook };
