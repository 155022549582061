<template>
  <div class="app">
    <GlobalEvents @click="$root.$emit('window:click')" />
    <RouterView />
  </div>
</template>

<script>
import { GlobalEvents } from 'vue-global-events';

import LayoutHelper from '@/helpers/LayoutHelper';
import NavigatorHelper from '@/helpers/NavigatorHelper';

export default {
  name: 'App',

  components: {
    GlobalEvents,
  },

  setup() {
    LayoutHelper.setBodyBrowserClass(NavigatorHelper.getBrowserName());
  },
};
</script>

<style lang="scss" scoped>
.app {
  @include stylesForMobile {
    height: 100%;
  }
}
</style>

<style lang="scss">
@import 'styles/styles';
</style>
