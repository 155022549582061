export enum BrowserNames {
  CHROME = 'chrome',
  SAFARI = 'safari',
  FIREFOX = 'firefox',
  OPERA = 'opera',
  IE = 'ie',
  EDGE = 'edge',
}

export default class NavigatorHelper {
  public static getBrowserName(): BrowserNames {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/chrome/i.test(userAgent)) return BrowserNames.CHROME;
    if (/safari/i.test(userAgent)) return BrowserNames.SAFARI;
    if (/firefox/i.test(userAgent)) return BrowserNames.FIREFOX;
    if (/opera/i.test(userAgent)) return BrowserNames.OPERA;
    if (/MSIE|Trident/i.test(userAgent)) return BrowserNames.IE;
    if (/Edge/i.test(userAgent)) return BrowserNames.EDGE;

    throw new Error('Unknown browser');
  }

  public static isTouchDevice(): boolean {
    return window.matchMedia('(pointer: coarse)').matches;
  }

  public static async copy(value: string): Promise<void> {
    if (!value) return;

    try {
      await navigator.clipboard.writeText(value);
    } catch (e) {
      const textarea = document.createElement('textarea');
      textarea.value = value;
      document.body.appendChild(textarea);
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
  }
}
