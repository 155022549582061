import apiFeeds from '@/api/feeds';

import comments from '@/store/modules/feeds/modules/comments';
import files from '@/store/modules/feeds/modules/files';
import likes from '@/store/modules/feeds/modules/likes';
import poll from '@/store/modules/feeds/modules/poll';

const root = { root: true };

const createInitialState = () => ({
  feeds: [],
  feed: {},
  maxPages: null,
  currentProject: null,
});

const state = createInitialState();

const getters = {};

const mutations = {
  setFeeds(state, { feeds, action }) {
    if (action === 'feedsUpdates') {
      state.feeds = [...state.feeds, ...feeds];
      return;
    }
    state.feeds = feeds;
  },

  setFeed(state, { feed }) {
    state.feed = feed;
  },

  setPagesDetail(state, details) {
    const { numPages = 1 } = details;
    state.maxPages = numPages;
  },

  addFeed(state, feed) {
    state.feeds = [feed, ...state.feeds];
  },

  unshiftFeed(state, feed) {
    state.feeds = [feed, ...state.feeds];
  },

  deleteFeed(state, feedId) {
    state.feeds = state.feeds.filter((feed) => feed.id !== feedId);
  },

  updateFeed(state, feed) {
    state.feeds = state.feeds.map((currentFeed) => {
      if (currentFeed.id === feed.id) {
        return feed;
      }
      return currentFeed;
    });
  },

  updateCurrentProject(state, value) {
    state.currentProject = value || null;
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
  },

  async GET_FEED({ commit }, { teamId, feedId }) {
    try {
      commit('loading/startLoading', 'feeds', root);
      const { data: result } = await apiFeeds.getFeedDetails({ teamId, feedId });
      commit('setFeeds', { feeds: [result] });
      return result;
    } catch (e) {
      console.error('Ошибка при получении информации о новости');
      throw e;
    } finally {
      commit('loading/finishLoading', 'feeds', root);
    }
  },

  async GET_FEEDS({ commit }, { teamId, getParams = {}, loader = 'feeds' }) {
    try {
      commit('loading/startLoading', loader, root);
      // commit('updateCurrentProject', getParams.project);
      commit('updateCurrentProject', getParams.project);
      const { data: results } = await apiFeeds.getFeedsList({ teamId, getParams });
      commit('setFeeds', { feeds: results.results, action: loader });
      commit('setPagesDetail', { numPages: results.num_pages, count: results.count });
      return results.results;
    } catch (e) {
      console.error('Ошибка при получении ленты новостей команды');
      throw e;
    } finally {
      commit('loading/finishLoading', loader, root);
    }
  },

  async CREATE_FEED({ commit }, requestData) {
    try {
      const { data: feed } = await apiFeeds.createFeed(requestData);
      commit('addFeed', feed);
      return feed;
    } catch (e) {
      console.error('Ошибка при создании новости');
      throw e;
    }
  },

  async DELETE_FEED({ commit }, { teamId, feedId }) {
    try {
      await apiFeeds.deleteFeed({ teamId, feedId });
      commit('deleteFeed', feedId);
    } catch (e) {
      console.error('Ошибка при удалении новости');
      throw e;
    }
  },

  DELETE_FEED_TEMP_FILES({ commit, rootState }, feedId) {
    const { feeds = {} } = rootState;
    const feed = feeds.feeds.find((it) => it.id === feedId);

    if (!feed.feedTempFiles) return;

    commit('updateFeed', { ...feed, feedTempFiles: [] });
  },

  async EDIT_FEED({ commit, rootState }, requestData) {
    try {
      const { data: newFeedData } = await apiFeeds.editFeed(requestData);
      const { feeds = {} } = rootState;
      const feed = feeds.feeds.find((it) => it.id === requestData.feedId);
      commit('updateFeed', { ...feed, ...newFeedData, feedTempFiles: [] });
    } catch (e) {
      console.error('Ошибка при редактировании новости');
      throw e;
    }
  },
};

export default {
  namespaced: true,
  modules: {
    files,
    likes,
    comments,
    poll,
  },

  state,
  getters,
  mutations,
  actions,
};
