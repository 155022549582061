export default {
  menu: {
    reply: 'Reply',
    edit: 'Edit',
    delete: 'Delete comment',
    pin: 'Pin comment',
    confirm: {
      title: 'Are you sure you want to delete this comment?',
      btnCancel: 'Cancel',
      btnConfirm: 'Delete',
    },
    errors: {
      delete: 'Error while deleting comment',
    },
  },
  showPrevBtn: 'Show previous comments',
  showFirstBtn: 'Show comments',
  hideComments: 'Hide comments',
  showReplyBtn: 'Show reply comments',
  showNotReadReplyBtn: 'Show +{notReadCount} new reply',
};
