import session from '@/api/session';
import { user } from '@/models/User';

import ZodHelper from '@/helpers/ZodHelper';

export enum UserTypes {
  Executor = 'executor',
  Responsible = 'responsible',
  All = 'all',
}

type GetUsers = {
  taskId: number;
  type: UserTypes;
  getParams: {
    page: number;
    search?: string;
  };
};

export const apiTaskUsers = {
  getUsers({ taskId, type, getParams }: GetUsers) {
    return ZodHelper.request(
      () => session.get(`tasks/users-for-task/${type}/${taskId}/`, { params: getParams }),
      ZodHelper.getPaginationModel(user.array()),
    );
  },
};
