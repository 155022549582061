import { z } from 'zod';

import { status } from '@/models/Status';

export const teamStatus = status
  .extend({
    team: z.number(),
    tasks_count: z.number(),
  })
  .transform(({ tasks_count: cardsCount, ...value }) => ({ ...value, cardsCount }));
export type TeamStatus = z.infer<typeof teamStatus>;
