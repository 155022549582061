export default {
  title: 'Registration',
  firstName: 'Name',
  phone: 'Phone',
  email: 'E-mail',
  password: 'Password',
  passwordConfirm: 'Repeat password',
  checkbox: 'By clicking on the Register button, I agree to the ',
  personalData: 'Privacy Policy',
  go: 'Register',

  success: {
    title: 'You have successfully registered',
    confirmEmail: 'To continue working, confirm your e-mail ',
    login: 'Sign in',
  },

  accountActivation: {
    success: 'Account has been successfully activated',
    error: 'Activation error',
    redirect: 'You will be redirected to the team creation page in ',
    threeSeconds: '3 seconds',
  },

  invited: '{user} invited you to the {team} team',
};
