import session from '@/api/session';

export default {
  getTaskScreenshots({ taskId, getParams: params }) {
    return session.get(`/tasks/worktime/screenshots/${taskId}/`, { params });
  },
  createTaskWorkTime(requestData) {
    return session.post(`/tasks/worktime/create/`, requestData);
  },
  createMultipleTaskWorkTime(requestData) {
    return session.post(`/tasks/worktime/create-several/`, requestData);
  },
  deleteTaskWorkTime(requestData) {
    return session.delete(`/tasks/worktime/delete/`, { data: requestData });
  },
};
