import session from '@/api/session';

interface WebpushInfo {
  status_type: string;
  subscription: any;
  browser: string | undefined;
}

export default {
  sendWebpushInfo(data: WebpushInfo) {
    return session.post('notices/webpush/save_information', data);
  },
};
