import { shallowRef, watchEffect, onScopeDispose } from 'vue';

import type { Nullable } from '@/types/utility';

export const useMediaQuery = (query: string) => {
  let mediaQuery: Nullable<MediaQueryList> = null;
  const matches = shallowRef(false);
  const isSupported = window && 'matchMedia' in window && typeof window.matchMedia === 'function';

  const handler = (event: MediaQueryListEvent) => {
    matches.value = event.matches;
  };

  const cleanup = () => {
    if (!mediaQuery) return;

    if ('removeEventListener' in mediaQuery) {
      mediaQuery.removeEventListener('change', handler);
    } else {
      // @ts-expect-error deprecated API
      mediaQuery.removeListener(handler);
    }
  };

  const stopWatch = watchEffect(() => {
    if (!isSupported) return;

    cleanup();

    mediaQuery = window.matchMedia(query);

    if ('addEventListener' in mediaQuery) {
      mediaQuery.addEventListener('change', handler);
    } else {
      // @ts-expect-error deprecated API
      mediaQuery.addListener(handler);
    }

    matches.value = mediaQuery.matches;
  });

  onScopeDispose(() => {
    stopWatch();
    cleanup();
    mediaQuery = null;
  });

  return matches;
};
