export default {
  datasource: 'Database',
  selectDatasource: 'Select database',
  datasourceMessage: 'Database with customizations, cards, etc.',
  status: 'Status',
  selectStatus: 'Select status',
  statusMessage: 'Information about the current status of the task',
  movingType: 'Transfer type',
  cardTypeMessage: 'Display type of modules and task fields',
  copy: 'Copy',
  move: 'Move',
  moveSubtasks: 'Move subtasks',
  moveComments: 'Move comments',
  cancel: 'Cancel',
  transferCompleted: 'Transfer completed successfully',
};
