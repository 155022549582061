export default {
  name: 'Name',
  placeholder: 'Enter task name',
  createTask: 'Create task',
  datasource: 'Database',
  selectDatasource: 'Select database',
  selectCardType: 'Card type',
  error: 'Error creating task',
  errorTemplateDontPickedCardType: 'Choose a card type before creating a template',
  create: 'Create',
  cancel: 'Cancel',
};
