export default {
  header: {
    block: 'Block',
    unblock: 'Unblock',

    confirm: {
      title: 'Are you sure you want to <br /> block this member?',
      description:
        'You can bring the member back within 6 months. After this period this member will be removed from Shtab.',
      btnCancel: 'Keep the member',
      btnConfirm: 'Block',
    },
  },

  occupation: {
    inHour: '{currency} per hour',
    rate: 'Rate',
    enter: 'Indicate',
    role: 'Role',
    save: 'Save',
    edit: 'Edit',
  },

  general: {
    title: 'Main',
    email: 'Email',

    phone: 'Phone:',
    notSpecified: 'Not Indicated',

    rights: 'Rights',
    downtime: 'Downtime',
    skipDowntime: 'Stopping the tracker when there is no activity',
    checkAppsActivity: 'Capture app activity',
    screens: 'Screenshots',
    interval: 'Interval',
    blur: 'Blur',

    addTime: 'Adding time',

    notAvailable: 'Available only for paid plans with this option activated',
  },

  isBlocked: 'User blocked',
};
