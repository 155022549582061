import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

export default [
  {
    path: 'updates',
    component: () => import('@/pages/updates/Updates.vue'),
    children: [
      {
        path: '',
        name: pageNames.updates.UPDATES,
        meta: {
          title: new Translation('routes.updates.updates'),
        },
        component: () => import('@/pages/updates/AllUpdates.vue'),
      },
      {
        path: 'settings',
        name: pageNames.updates.SETTINGS,
        meta: {
          title: new Translation('routes.updates.settings'),
        },
        component: () => import('@/pages/updates/Settings.vue'),
      },
    ],
  },
];
