import { z } from 'zod';

import { customFieldBase } from '@/models/CustomField';
import { userBase } from '@/models/User';
import { viewBase } from '@/models/views/View';

import { CustomWidgetCodes } from '@/constants/tasks/customWidgets';

export const taskTypeData = z.object({
  id: z.string().optional(),
  type: z.enum(['description', 'comment', 'checklist', 'subTasks', 'files']),
  default: z.boolean(),
  name: z.string().optional(),
  isRequired: z.boolean().optional(),
});
export const taskTypeWidget = z.object({
  type: z.enum([CustomWidgetCodes.TIMER]),
  is_active: z.boolean(),
});
export const taskTypeMinimum = z.object({
  id: z.number(),
  name: z.string(),
  icon: z.string(),
});
export const taskType = z
  .object({
    id: z.number(),
    name: z.string(),
    icon: z.string(),
    data: z.object({
      modules: z.array(taskTypeData),
      widgets: z.array(taskTypeWidget),
    }),
    views: viewBase.array(),
    can_delete: z.boolean(),
    prev_value: z.number().optional(),
  })
  .transform(({ can_delete, prev_value, ...rest }) => ({
    canDelete: can_delete,
    prevValue: prev_value,
    ...rest,
  }));

export const taskTypeFieldMinimum = z
  .object({
    id: z.number(),
    field: z.string().nullable(),
    default_field: z.string().nullable(),
  })
  .transform(({ default_field, ...field }) => ({
    defaultField: default_field,
    ...field,
  }));

export const taskTypeDetail = z.object({
  id: z.number(),
  name: z.string(),
  icon: z.string(),
  data: z.object({
    modules: z.array(taskTypeData),
    widgets: z.array(taskTypeWidget),
  }),
  fields: z.array(taskTypeFieldMinimum),
});
export const taskTypeFields = z
  .object({
    id: z.number(),
    field: customFieldBase.nullable(),
    default_field: z.string().nullable(),
    prev_value: z.string().nullable().optional(),
    users_with_access: userBase.array(),
    positions_with_access: z.any().array(),
    departments_with_access: z.any().array(),
  })
  .transform(
    ({
      default_field,
      prev_value,
      users_with_access,
      positions_with_access,
      departments_with_access,
      ...taskType
    }) => ({
      defaultField: default_field,
      prevValue: prev_value,
      usersWithAccess: users_with_access,
      departmentsWithAccess: departments_with_access,
      positionsWithAccess: positions_with_access,
      ...taskType,
    }),
  );
export const viewTaskType = z
  .object({
    id: z.number(),
    task_type_detail: taskType,
    is_default: z.boolean(),
  })
  .transform(({ task_type_detail, is_default, id }) => ({
    ...task_type_detail,
    viewCardTypeId: id,
    isDefault: is_default,
  }));

export type ViewTaskType = z.infer<typeof viewTaskType>;
export type TaskTypeFields = z.infer<typeof taskTypeFields>;
export type TaskTypeMinimum = z.infer<typeof taskTypeMinimum>;
export type TaskType = z.infer<typeof taskType>;
