const dictionary = {
  ru: {
    code: 'ru',
    messages: {
      required: 'Поле обязательное',
      email: 'Некорректный email',
      min: ({ rule }) => `Поле должно содержать минимум ${rule.params[0]} символов`,
      max: ({ rule }) => `Поле должно содержать максимум ${rule.params[0]} символов`,
      phone_number: 'Неверный номер телефона',
      alpha: 'Поле должно содержать только латинские буквы',
      alpha_num: 'Поле должно содержать латинские буквы или цифры',
      alpha_and_num: 'Поле должно содержать латинские буквы и цифры',
      is_different_password: 'Новый и текущий пароли совпадают, повторите попытку',
      phone_number_start_on_plus: 'Номер должен начинаться с +',
      integer: 'Поле должно содержать только цифры',
      between: ({ rule }) => `Введите число от ${rule.params[0]} до ${rule.params[1]}`,
      confirmed: 'Поле должно совпадать',
    },
  },
  en: {
    code: 'en',
    messages: {
      required: 'Field is required',
      email: 'The email field must be a valid email',
      min: ({ rule }) => `The field must contain at least ${rule.params[0]} characters`,
      max: ({ rule }) => `The field must contain a maximum of ${rule.params[0]} characters`,
      phone_number: 'Invalid phone number',
      alpha: 'The field must contain only Latin characters',
      alpha_num: 'The field must contain Latin characters or numbers',
      alpha_and_num: 'The field must contain Latin characters and numbers',
      is_different_password: 'New and current passwords match, please try again',
      phone_number_start_on_plus: 'Phone number must start with +',
      integer: 'The field must contain only numbers',
      between: ({ rule }) => `Please enter a number between ${rule.params[0]} and ${rule.params[1]}`,
      confirmed: 'The field must match',
    },
  },
};

export default dictionary;
