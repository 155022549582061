import type { MessageContext } from 'vue-i18n';

export default {
  enterTaskName: 'Enter task name',
  createTag: 'Create tag',
  priority: 'Priority',
  dueDate: 'Due date',
  complete: 'Compete',
  goToParentTask: 'Go to parent task ',
  dueDateRange: ({ named }: MessageContext) => {
    if (!named('start')) {
      return `until ${named('end')}`;
    } else if (!named('end')) {
      return `from ${named('start')}`;
    }
    return `${named('start')} - ${named('end')}`;
  },
};
