export default {
  executors: '0 assignees | {n} assignee | {n} assignees',
  hours: '0 hours | {n} hour | {n} hours',
  days: '0 days | {n} day | {n} days',
  members: '0 members | {n} member | {n} members',
  tasks: '0 cards | {n} card | {n} cards',
  tasksPassive: '0 cards | {n} card | {n} cards',
  inviteEmployees: '0 employees | {n} employee | {n} employees',
  everyWeekDayShort: {
    1: 'Nearest MO',
    2: 'Nearest TU',
    3: 'Nearest WE',
    4: 'Nearest TH',
    5: 'Nearest FR',
    6: 'Nearest SA',
    7: 'Nearest SU',
  },
};
