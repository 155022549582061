export default {
  header: 'Poll',
  question: 'Question',
  questionPlaceholder: 'Type your question',
  answers: 'Possible answers',
  answersPlaceholder: 'Append answer',
  pollOptions: {
    anonymous: 'Anonymous poll',
    multiAnswers: 'Several answer choices',
  },
  errors: {
    duplicate: 'This answer already exist',
    stopped: 'This poll already stopped',
  },
  resultsCountLabel: 'Vote',
  actions: {
    stop: 'Stop polling',
    changeAnswer: 'Change the answer',
  },
  voteBtn: 'Vote',
};
