import { z } from 'zod';

export const datasourceStage = z
  .object({
    id: z.number(),
    name: z.string(),
    color: z.string(),
    comment: z.string(),
    date_from: z.string().nullable(),
    date_until: z.string().nullable(),
  })
  .transform(({ date_from, date_until, ...value }) => ({
    ...value,
    dateFrom: date_from ? new Date(date_from) : null,
    dateUntil: date_until ? new Date(date_until) : null,
  }));
export type DatasourceStage = z.infer<typeof datasourceStage>;
