export default {
  title: 'Reports',
  description: 'Reports include four sections with the stats accumulated while using the tracker',

  consolidated: {
    title: 'Consolidated report',
    description: 'This is a report which shows general stats for projects with a progress scale',
  },

  plan: {
    title: 'Plan/Actual',
    description:
      'This report compares planned and actual time spent while working.' +
      ' This feature may help you learn how to evaluate your tasks correctly.',
  },

  team: {
    title: 'Watch the progress of the team',
    description: 'Track how many hours employees have been working on which projects in a given period of time.',
  },
};
