import type { App } from 'vue';

import { initInputAutowidth } from './inputAutowidth';
import { initMask } from './mask';
import { initMaxlength } from './maxlength';
import { initResponsive } from './responsive';
import { initScrollActive } from './scrollActive';

export const initAllDirectives = async (app: App): Promise<void> => {
  await Promise.all([
    initInputAutowidth(app),
    initMask(app),
    initMaxlength(app),
    initResponsive(app),
    initScrollActive(app),
  ]);
};
