export default {
  email: 'E-mail',
  password: 'Password',
  login: 'Login',
  forgot: 'Forgot your password?',

  errors: {
    timeout: 'Number of attempts exceeded',
    noActivation: 'Your account has not been activated yet',
    invalid: 'Incorrect e-mail or password',
    send: 'Send another e-mail?',
    maybe: 'Have you ',
    forgot: 'forgotten your password?',
    repeatAfterMinute: 'Repeat after 60 seconds',
  },
};
