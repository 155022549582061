export default {
  payments: {
    date: 'Date',
    details: 'Transaction details',
    description: 'Description',
    amount: 'Amount',
    status: 'Status',

    teamBalance: 'Team balance',
    bankCard: 'Bank card',
    invoice: 'Account',
    users: 'Users',
    extra: 'Addnl. GB',

    statuses: {
      payed: 'Paid',
      cancel: 'Cancelled',
      declined: 'Charging error',
      waiting: 'Awaiting payment',
    },
    noResults: 'No results',
  },
  filters: {
    filters: 'Filters',
    date: 'Date',
    direction: 'Direction of payment (add/write off)',
    payer: 'Payer',
    status: 'Status',
    reset: 'Reset filters',
  },

  payers: {
    entity: 'Entity',
    ie: 'Entrepreneur',
    individual: 'Individual',

    addPayer: 'Add payer',
  },

  cards: {
    addCard: 'Add card',
  },

  confirm: {
    deleteCard: 'Are you sure want to delete this card?',
    deletePayer: 'Are you sure you want to delete the payer?',
    cancel: 'Cancel',
    delete: 'Delete',
  },

  byDefault: 'Default',
};
