import type { App } from 'vue';
import { directive, setDefaultProps } from 'vue-tippy';
import './tippy.scss';

export const initVueTippy = (app: App): Promise<void> => {
  return new Promise((resolve) => {
    app.directive('tippy', directive);
    setDefaultProps({
      theme: 'custom-tooltip',
      arrow: true,
      maxWidth: 300,
    });
    resolve();
  });
};
