export default {
  title: 'You are invoiced for {amount}',
  label: 'Invoice No. {id} dated {date}',

  sendToEmail: 'The invoice has been sent to {email}',

  description_1: "You may clear the invoice only using an entity’s or an entrepreneur's account.",
  description_2:
    'Please indicate the account number, payment ID, and date. Please check that the banking details indicated in' +
    ' the money order are the same as below. If you miss or incorrectly indicate any of this data, payment may' +
    ' not reach the addressee, or may be delayed.',
  description_3:
    'Your balance will be credited only when the bill is fully paid. If you need to credit another amount,' +
    ' please issue another invoice.',
  description_4: 'As soon as the transaction is made, you will receive a confirmation letter to your email address.',

  actions: {
    openPdf: 'Open a PDF',
    forward: 'Forward',
    enterEmail: 'Enter the e-mail address',
    send: 'Send',
  },
};
