import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  createProject({ teamId, requestBody }) {
    return session.post(`projects/feed/${teamId}/create/`, requestBody);
  },
  deleteProject(projectId) {
    return session.delete(`/projects/project/${projectId}/`);
  },
  getProjects({ teamId, getParams }) {
    return session.get(`feeds/feed/${teamId}/list/`, { params: getParams });
  },
  getProjectsPaginated({ teamId, getParams }) {
    return session.get(`/projects/project/paginated-list/${teamId}/`, { params: getParams });
  },
  getProjectDetails(projectId) {
    const defaultUrl = `/projects/project/${projectId}/`;
    const sharedUrl = '/shared/project/detail/';

    return session.get(getRequestUrl(defaultUrl, sharedUrl));
  },
  editProject({ projectId, requestBody }) {
    return session.patch(`/projects/project/${projectId}/`, requestBody);
  },
  editArchivedProjects({ teamId, requestBody }) {
    return session.post(`/projects/project/list/${teamId}/mass-actions/`, requestBody);
  },
  toggleProjectFavorites(projectId) {
    return session.patch(`/projects/project/${projectId}/favorite/`);
  },
};
