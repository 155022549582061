import session from '@/api/session';
import getRequestUrl from '@/api/utils/getRequestUrl';

export default {
  getTasksPaginated({ teamId, getParams, config = {} }) {
    const sharedUrl = `shared/tasks/${getParams.views}/list/`;
    const defaultUrl = `tasks/list/${teamId}/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams, ...config });
  },
  getTaskDetails({ taskId, getParams }) {
    const defaultUrl = `tasks/detail/${taskId}/`;
    const sharedUrl = `shared/tasks/detail/${taskId}/`;

    return session.get(getRequestUrl(defaultUrl, sharedUrl), { params: getParams });
  },
  createTask({ requestBody, teamId }) {
    return session.post(`tasks/create/${teamId}/`, requestBody);
  },
  deleteTask(taskId) {
    return session.delete(`tasks/delete/${taskId}/`);
  },
  editTask({ taskId, taskDetails }) {
    return session.patch(`tasks/edit/${taskId}/`, taskDetails);
  },
  getTaskHistory({ taskId, getParams }) {
    return session.get(`/tasks/history/list/${taskId}/`, { params: getParams });
  },
  editTaskPosition({ taskId, requestBody }) {
    return session.post(`/tasks/change-position/${taskId}/`, requestBody);
  },
  moveTask({ taskId, requestBody, getParams = {} }) {
    return session.patch(`/tasks/task-transfer/${taskId}/`, requestBody, { params: getParams });
  },
};
