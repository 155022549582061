import main from '@/api/auth/main';

import registration from './registration';
import restore from './restore';

export default {
  ...main,
  ...registration,
  ...restore,
};
