export default {
  openInNewTab: 'Open in new tab',
  edit: 'Edit',
  share: 'Link',
  assignToMe: 'Assign to me',
  duplicate: 'Duplicate',
  move: 'Move',
  archive: 'Archive',
  convertMilestone: 'Convert to milestone',
  select: 'Select',
  raise: 'Raise',
  lower: 'Lower',
  transformToSubtask: 'Transform to subtask',
  createSubtask: 'Create subtask',
  transformToTask: 'Transform to task',
  independentTask: 'Make task independent',
};
