import { z } from 'zod';

import { taskTypeDetail, taskTypeMinimum } from '@/models/CardTypes';
import { currencyModel } from '@/models/Currency';
import { user } from '@/models/User';
import { taskExecutor } from '@/models/tasks/TaskExecutor';

import { parseISO } from 'date-fns';

import { status } from '../Status';
import { datasourceStage } from '../datasource/DatasourceStage';
import { teamTag } from '../teams/TeamTag';

import { taskChecklist } from './TaskChecklist';
import { taskFile } from './TaskFile';

export const taskTemplateRepeat = z
  .object({
    id: z.number(),
    run_time: z
      .string()
      .nullable()
      .transform((value) => (value ? parseISO(value) : null)),
  })
  .transform(({ run_time, ...rest }) => ({ ...rest, date: run_time }));

export const taskTemplateExecutor = taskExecutor.omit({ finished: true });
export type TaskTemplateExecutor = z.infer<typeof taskTemplateExecutor>;

export const taskTemplateResponsible = z.object({ id: z.number(), responsible: user });
export type TaskTemplateResponsible = z.infer<typeof taskTemplateResponsible>;

const _taskTemplateBase = z.object({
  id: z.number(),
  name: z.string(),
  task_executor: taskTemplateExecutor.array(),
  task_labels: teamTag.array(),
  priority: z.string(),
  schedule: taskTemplateRepeat.array(),
  type: taskTypeMinimum.nullable(),
  custom_schedule_end_day_of_month: z.number().nullable(),
  custom_schedule_end_day_of_week: z.number().nullable(),
  custom_schedule_end_time: z.string().nullable(),
  custom_schedule_start_day_of_month: z.number().nullable(),
  custom_schedule_start_day_of_week: z.number().nullable(),
  custom_schedule_start_time: z.string().nullable(),
  schedule_type: z.number().nullable(),
});
const transformTaskTemplateBase = <T extends z.infer<typeof _taskTemplateBase>>({
  schedule,
  task_executor,
  custom_schedule_end_day_of_month,
  custom_schedule_end_day_of_week,
  custom_schedule_end_time,
  custom_schedule_start_day_of_month,
  custom_schedule_start_day_of_week,
  custom_schedule_start_time,
  task_labels,
  schedule_type,
  ...rest
}: T) => ({
  ...rest,
  executors: task_executor,
  dueDate: {
    startDayOfWeek: custom_schedule_start_day_of_week,
    startDayOfMonth: custom_schedule_start_day_of_month,
    startTime: custom_schedule_start_time,
    endDayOfWeek: custom_schedule_end_day_of_week,
    endDayOfMonth: custom_schedule_end_day_of_month,
    endTime: custom_schedule_end_time,
    type: schedule_type,
  },
  tags: task_labels,
  repeatDate: schedule.length ? schedule[0].date : null,
  repeatId: schedule.length ? schedule[0].id : null,
});

export const taskTemplateListItem = _taskTemplateBase
  .extend({
    is_favorite: z.boolean(),
    checklist_count: z.number(),
    files_count: z.number(),
  })
  .transform((template) => {
    const { checklist_count, files_count, is_favorite, ...baseTemplateTransformed } =
      transformTaskTemplateBase(template);

    return {
      ...baseTemplateTransformed,
      isFavorite: template.is_favorite,
      checklistCount: template.checklist_count,
      filesCount: template.files_count,
    };
  });
export type TaskTemplateListItem = z.infer<typeof taskTemplateListItem>;

export const taskTemplateChecklist = taskChecklist;
export type TaskTemplateChecklist = z.infer<typeof taskTemplateChecklist>;

export const taskTemplateFile = taskFile;
export type TaskTemplateFile = z.infer<typeof taskTemplateFile>;

export enum TaskTemplateDueDateType {
  FirstDayOfTheWeek = 1,
  LastDayOfTheWeek = 2,
  FirstDayOfTheMonth = 3,
  LastDayOfTheMonth = 4,
  AfterOneDay = 5,
  AfterThreeDays = 6,
  AfterSevenDays = 7,
  CustomDayOfWeek = 8,
  CustomDate = 9,
}

export const taskTemplate = _taskTemplateBase
  .extend({
    description: z.string().nullable(),
    check_lists: taskTemplateChecklist.array(),
    files: taskTemplateFile.array(),
    task_responsible: taskTemplateResponsible.array(),
    planned_time_minutes: z.number(),
    stage: datasourceStage.nullable(),
    created_by: user.nullable(),
    cost: z.string().nullable(),
    currency_value: currencyModel,
    date_created: z.string().transform((value) => parseISO(value)),
    status: status.nullable(),
    type: taskTypeDetail.nullable(),
  })
  .transform((template) => {
    const baseTemplateTransformed = transformTaskTemplateBase(template);

    return {
      ...baseTemplateTransformed,
      duration: template.planned_time_minutes,
      checklist: template.check_lists,
      responsible: template.task_responsible,
      creator: template.created_by,
      currency: template.currency_value,
      dateCreated: template.date_created,
      repeat: template.schedule.length ? template.schedule[0] : null,
    };
  });

export type TaskTemplate = z.infer<typeof taskTemplate>;
