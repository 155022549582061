import apiTasks from '@/api/tasks';

import directS3Upload from '@/utils/directS3Upload';

const root = { root: true };

const createInitialState = () => ({
  comments: [],
  repliedComment: null,
  editComment: null,
  unCommentFiles: [],
});

const state = createInitialState();

const getters = {
  getComments: (state) => state.comments,
};

const mutations = {
  setComments(state, comments) {
    state.comments = comments;
  },

  addComment(state, comment) {
    state.comments.push(comment);
  },

  setRepliedComment(state, comment) {
    state.repliedComment = comment;
    state.editComment = null;
  },
  resetRepliedComment(state) {
    state.repliedComment = null;
  },

  setEditComment(state, comment) {
    state.editComment = comment;
    state.repliedComment = null;
  },

  resetEditComment(state) {
    state.editComment = null;
  },

  addEditUnCommentFiles(state, { files = [] }) {
    const prevFiles = state.editComment.unCommentFiles || [];
    state.editComment = {
      ...state.editComment,
      unCommentFiles: [...prevFiles, ...files],
    };
  },

  deleteEditUnCommentFile(state, { fileId }) {
    if (state.editComment.unCommentFiles) {
      const newFiles = state.editComment.unCommentFiles.filter((file) => file.id !== fileId);
      state.editComment = {
        ...state.editComment,
        unCommentFiles: [...newFiles],
      };
    }
  },

  deleteEditCommentFile(state, { fileId }) {
    const newFiles = state.editComment.files.filter((file) => file.id !== fileId);
    state.editComment = {
      ...state.editComment,
      files: [...newFiles],
    };
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },

  editComment(state, { commentId, editComment }) {
    state.comments = state.comments.map((comment) => {
      if (comment.id === commentId) return editComment;
      return comment;
    });
  },
  addUnCommentFiles(state, { files }) {
    const prevFiles = state.unCommentFiles;
    state.unCommentFiles = [...prevFiles, ...files];
  },
  addToCommentFiles(state, { files, commentId }) {
    state.comments = state.comments.map((comment) => {
      if (comment.id === commentId)
        return {
          ...comment,
          ...{ files: [...comment.files, ...files] },
        };
      return comment;
    });
  },
  deleteCommentFile(state, { fileId, commentId }) {
    state.comments = state.comments.map((comment) => {
      if (comment.id === commentId)
        return {
          ...comment,
          ...{ files: comment.files.filter((it) => it.id !== fileId) },
        };
      return comment;
    });
  },
  deleteUnCommentFile(state, { fileId }) {
    state.unCommentFiles = state.unCommentFiles.filter((file) => file.id !== fileId);
  },
  deleteAllUnCommentFiles(state) {
    state.unCommentFiles = [];
  },
};

const actions = {
  async CREATE_COMMENT({ commit, dispatch }, requestData) {
    try {
      const { data: comment } = await apiTasks.createTaskComment(requestData);
      commit('deleteAllUnCommentFiles');
      commit('addComment', comment);
    } catch {
      dispatch('notifications/SHOW_ERROR', 'Ошибка при создании комментария', root);
      console.error('Ошибка при создании комментария');
    }
  },
  async GET_COMMENTS({ commit, dispatch }, taskId) {
    try {
      const { data: comments } = await apiTasks.getTaskComments(taskId);
      commit('setComments', comments);
      return comments;
    } catch {
      console.error('Ошибка при получении комментариев');
      dispatch('notifications/SHOW_ERROR', 'Ошибка при получении комментариев', root);
    }
  },
  async DELETE_COMMENT({ commit, dispatch }, requestData) {
    try {
      const { data: editComment } = await apiTasks.deleteTaskComment(requestData);
      commit('editComment', {
        commentId: requestData.commentId,
        editComment,
      });
    } catch {
      console.error('Ошибка при удалении комментария');
      dispatch('notifications/SHOW_ERROR', 'Ошибка при удалении комментария', root);
    }
  },
  async EDIT_COMMENT({ commit, dispatch }, requestData) {
    try {
      const { data: editComment } = await apiTasks.editTaskComments(requestData);
      commit('editComment', { commentId: requestData.commentId, editComment });
    } catch {
      console.error('Ошибка при редактировании комментария');
      dispatch('notifications/SHOW_ERROR', 'Ошибка при редактировании комментария', root);
    }
  },
  async DELETE_COMMENT_FILE({ commit, dispatch, getters }, { requestData, editMode }) {
    try {
      const { commentId } = requestData;
      if (!commentId) {
        if (editMode) commit('deleteEditUnCommentFile', { fileId: requestData.fileId });
        else commit('deleteUnCommentFile', { fileId: requestData.fileId });
        return;
      }

      const comments = getters.getComments;
      const comment = comments.filter((com) => com.id === commentId);
      requestData.requestBody = {
        comment_files_to_remove: [requestData.fileId],
        comment: comment[0].comment,
      };
      await apiTasks.editTaskComments(requestData);
      commit('deleteCommentFile', { fileId: requestData.fileId, commentId });
      if (editMode) commit('deleteEditCommentFile', { fileId: requestData.fileId });
    } catch {
      console.error('Ошибка при удаление файла комментария');
      dispatch('notifications/SHOW_ERROR', 'Ошибка при удаление файла комментария', root);
    }
  },
  async CREATE_COMMENT_FILES({ commit }, { requestData, editMode }) {
    try {
      let files;
      if (JSON.parse(import.meta.env.VITE_DIRECT_S3_UPLOAD)) {
        const filesToUpload = requestData.fd
          .entries()
          .filter(([field]) => field === 'file')
          .map(([, file]) => file);

        const uploadRequests = filesToUpload.map(async (fileToUpload) => {
          const { file } = await directS3Upload({
            id: requestData.taskId,
            preSignRequest: apiTasks.preSignCommentFileForS3,
            file: fileToUpload,
          });
          return file;
        });

        const uploadedFiles = await Promise.all(uploadRequests);
        files = [...uploadedFiles];
      } else {
        const { data } = await apiTasks.createCommentFile(requestData);
        files = data.file;
      }

      if (editMode) {
        commit('addEditUnCommentFiles', { files });
      } else {
        commit('addUnCommentFiles', { files });
      }

      if (requestData.config && requestData.config.name) {
        commit(
          'tasks/files/setLoadProgress',
          {
            name: requestData.config.name,
            count: 100,
            isFinish: true,
          },
          root,
        );
      }
    } catch (e) {
      commit('tasks/files/removeLoadProgress', requestData.config.name, root);
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
