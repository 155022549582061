import { computed, inject, type ComputedRef } from 'vue';

class UseMqError extends Error {
  constructor() {
    super('Media query plugin is not installed');
    this.name = 'UseMqError';
  }
}

export const useMq = () => {
  const mq = inject<ComputedRef<'desktop' | 'phone'>>('mq');

  if (!mq?.value) {
    throw new UseMqError();
  }

  const isMobile = computed(() => mq.value === 'phone');
  const isDesktop = computed(() => mq.value === 'desktop');

  return { isMobile, isDesktop };
};
