import routerHelper from '@/router/helper';

import { colorGreyLightest } from '@/styles/_variables.module.scss';

const getters = {
  pageContentBackground(_skip, _skip2, rootState) {
    switch (rootState.route.name) {
      case routerHelper.pageNames.updates.UPDATES:
      case routerHelper.pageNames.updates.SETTINGS:
        return colorGreyLightest;
      default:
        return 'white';
    }
  },
};

export default {
  namespaced: true,
  getters,
};
