import { z } from 'zod';

import { UpdateTypes } from '../Update';

export enum Tools {
  Mail = 'mail',
  Push = 'push',
  MobilePush = 'mobile_push',
  Shtab = 'shtab',
  Messenger = 'messenger',
}

const UpdateSettingTypes = {
  ...UpdateTypes,
  FeedLikeCreate: 'feed_like_create',
  FeedPollVote: 'feed_poll_vote',
  FeedPollVoteAnon: 'feed_poll_vote_anon',
  FeedTag: 'feed_tag',
  NewComment: 'new_comment',
  NewTask: 'new_task',
  ProjectInvite: 'project_invite',
  ProjectMember: 'project_member',
  DeadlineAttention: 'task_deadline_attention',
} as const;
export type Updates = (typeof UpdateSettingTypes)[keyof typeof UpdateSettingTypes];

export const updatesSettings = z.record(z.nativeEnum(UpdateSettingTypes), z.nativeEnum(Tools).array());
