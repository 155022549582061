import session from '@/api/session';

export default {
  getUserProfile() {
    return session.get('users/profile/');
  },
  editUserProfile(data) {
    return session.patch('users/profile/', data);
  },
  editUserAvatar(data) {
    return session.put('users/user/update-avatar/', data);
  },
  deleteUserProfile() {
    return session.patch('users/profile/deactivate/');
  },
};
