export default {
  creator: 'Creator',

  confirm: {
    title: 'Are you sure you want to delete the card?',
    description: 'After you delete the card, it will be moved to Archive',
    btnCancel: 'Cancel',
    btnConfirm: 'Archive',
  },

  taskRepeat: 'Card repeat',
  coverSettings: 'Cover settings',
  unsplashSettings: 'Find photos',
};
