import generateId from '@/utils/generateId';

const state = {
  notifications: [],
};

const getters = {
  hasNotificationsWithDelayedRequest({ notifications }) {
    return Boolean(notifications.find((notification) => notification.action));
  },
  notifications({ notifications }) {
    return Object.values(notifications).flat(2);
  },
};

const mutations = {
  addNotification(state, notification) {
    state.notifications.push(notification);
  },
  deleteNotification(state, notification) {
    state.notifications = state.notifications.filter((_notification) => _notification.id !== notification.id);
  },

  resetNotification() {
    state.notifications = [];
  },
};

const actions = {
  RESET({ commit }) {
    commit('resetNotification');
  },

  NEW_NOTIFICATION({ commit }, { notification, notification: { timeout = 3000 } }) {
    notification.id = generateId();

    commit('addNotification', notification);
    setTimeout(() => commit('deleteNotification', notification), timeout);
  },

  SHOW_ERROR({ commit }, message) {
    const notification = {
      message,
      type: 'error',
      id: generateId(),
    };

    commit('addNotification', notification);
    setTimeout(() => commit('deleteNotification', notification), 3000);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
