export default {
  firstName: 'Name',
  lastName: 'Last name',
  email: 'E-mail',
  save: 'Save',
  edit: 'Edit',

  changeEmailNotification: 'We have sent an email to the email address you specified to confirm the change of email',

  enterCurrentPassword: 'Enter current password',
  currentPassword: 'Current password',
  enterNewPassword: 'Enter new password',
  newPassword: 'New password',
  passwordDescription: 'The password must contain not less than 8 numbers and characters',
  repeatPassword: 'enter your password again',
  repeatNewPassword: 'Enter new password again',
  savePassword: 'Save password',
  changePassword: 'Change password',

  error: {
    default: 'Unknown error',
    email: 'The e-mail address you entered is the same as the old one',
    avatar: 'Error loading user pic',
    delete: 'You may not delete your account because you have active teams',
  },
  notification: 'The password has been changed successfully',

  phone: 'Phone number',

  deleteAccount: {
    title: 'Delete account',
    delete: 'Delete',

    confirm: {
      title: 'Are you sure you want to delete the account?',
      btnCancel: 'Cancel',
    },
  },
};
