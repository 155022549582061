import session from '@/api/session';

export default {
  addTag({ taskId, tagId, viewId }: { taskId: number; tagId: number; viewId?: number }) {
    return session.put(`/tasks/${taskId}/task-labels/`, { task_labels_add: tagId, ...(viewId && { view: viewId }) });
  },
  deleteTag(requestData: { taskId: number; tagId: number }) {
    const { taskId, tagId } = requestData;
    return session.put(`/tasks/${taskId}/task-labels/`, { task_labels_remove: tagId });
  },
};
