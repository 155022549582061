import pageNames from '@/router/helper/pageNames';
import Translation from '@/utils/translation';

export default [
  {
    path: '',
    name: pageNames.wizard.WIZARD,
    component: () => import('@/pages/wizard/Wizard.vue'),
    meta: {
      seoTitle: new Translation('routes.wizard.seoWizard'),
    },
  },
];
