export default {
  header: {
    plans: 'Tariff plans',
    space: 'Upgrade space',
    compare: 'Compare plans',
    corporation: 'Corporation & Government',
  },

  selector: {
    confirm: {
      title: 'Are you sure you want to upgrade to a free plan?',
      description: 'Funds for the {plan} plan will not be returned to the account.',
      descriptionTrial: 'When you switch to a free plan, the trial period is reset, are you sure?',
      btnCancel: 'Cancel',
      btnConfirm: 'Go to',
    },
  },

  card: {
    perMonth: 'per user per month',

    paymentForYear: 'Payment for one participant per year:',
    monthlyPlan: 'With a monthly plan',
    annualPlan: 'With an annual plan',
    calculate: 'Calculate for your team',

    popular: 'Popular',

    membersLimit: 'Up to {count} members',
    unLimitMembers: 'Unlimited number of members',

    limitProjects: 'Up to {count} projects',
    unLimitProjects: 'Unlimited number of projects',

    storageTeam: 'Storage {space} GB per team',
    storageBase: 'Storage {space} GB base',
    filesAndScreensLimit: 'Files and screenshots up to 5 MB',
    filesAndScreensUnLimit: 'Unlimited files and screenshots',

    noScreens: 'No screenshots or screencasts',
    withScreens: 'With screenshots and screencasts',
  },

  comparison: {
    tracker: 'Time tracker',
    rightsManagement: 'Rights management',
    feed: 'Feed',
    personalManager: 'Personal manager',
  },

  corporation: {
    title: 'On-premise boxed or personal cloud',
    subtitle: 'Shtab adapt to your requirements and infrastructure',
    pros: {
      infrastructure: 'Choose your server or dedicated cloud (Linux). Installation in one command using docker-compose',
      domain: 'Your own beautiful domain and updating versions through the docker registry',
      application: 'Mobile app and tracker will work for you',
      sla: 'Implementation and Technical support with the possibility of SLA',
      dataTransfer: 'Transferring data from other services',
      sso: 'Single Sign-On (SSO) integration',
    },
    connect: 'Connect with us',
  },

  moreSpace: {
    title: 'Need more space to store your files and screenshots? Expand the place',
    month: '/month',
    costGb: '/GB',
    connect: 'Connect',
    connected: 'Package connected',
  },

  noRestrictions: 'With no restrictions',
  gb: 'GB',
  changeMembersCount: 'Change the number of participants',
  startTrial: 'Start trial',
  connect: 'Connect',
  connected: 'Connected',
};
