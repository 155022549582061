import apiBilling from '@/api/billing';

import RequestError from '@/errors/RequestError';
import permissions from '@/store/modules/billing/modules/permissions';

const root = { root: true };

const createInitialState = () => ({
  cards: [],
  payers: [],
  tariff: {},
  maxPages: null,
  tariffToBeConnect: null,
});

const state = createInitialState();

const getters = {
  maxProjectsCount(state) {
    return state.tariff.plan?.max_project_count;
  },
  planType(state) {
    return state.tariff.plan.plan_type;
  },
};

const mutations = {
  setCards(state, cards) {
    state.cards = cards;
  },
  setTariff(state, tariff) {
    state.tariff = tariff;
  },
  setPayers(state, payers) {
    state.payers = payers;
  },
  setMaxPages(state, pages) {
    state.maxPages = pages;
  },
  setTariffToBeConnect(state, tariff) {
    state.tariffToBeConnect = tariff;
  },

  addPayer(state, payer) {
    state.payers.push(payer);
  },

  updatePayer(state, payer) {
    state.payers = state.payers.map((currentPayer) => {
      if (currentPayer.id === payer.id) {
        return payer;
      }
      return currentPayer;
    });
  },
  updateBalance(state, balance) {
    state.tariff.balance = balance;
  },

  deleteCard({ cards }, id) {
    const index = cards.findIndex((card) => card.id === id);
    cards.splice(index, 1);
  },
  deletePayer({ payers }, id) {
    const index = payers.findIndex((payer) => payer.id === id);
    payers.splice(index, 1);
  },

  reset(state) {
    Object.assign(state, createInitialState());
  },
  resetPayersAndCards(state) {
    Object.assign(state, { payers: createInitialState().payers, cards: createInitialState().cards });
  },
};

const actions = {
  RESET({ commit }) {
    commit('reset');
    commit('permissions/reset');
  },

  async GET_TEAM_CARDS({ commit }, teamId) {
    try {
      const { data } = await apiBilling.getBillingCardsList(teamId);
      commit('setCards', data);
      return data;
    } catch (e) {
      console.error('Ошибка при запросе карт активной команды');
      throw e;
    }
  },
  async GET_TEAM_BILLING_TARIFF({ commit }, teamId) {
    try {
      const { data } = await apiBilling.getTeamBillingTariff(teamId);
      commit('setTariff', data);
      return data;
    } catch (e) {
      console.error('Ошибка при получении тарифного плана команды');
      throw e;
    }
  },
  async GET_TEAM_PAYERS({ commit }, teamId) {
    try {
      const { data } = await apiBilling.getTeamPayers(teamId);
      commit('setPayers', data);
      return data;
    } catch (e) {
      console.error('Ошибка при получении плательщиков команды');
      throw e;
    }
  },
  async GET_BILLING_TARIFFS() {
    try {
      const { data } = await apiBilling.getBillingTariffs();
      return data;
    } catch (e) {
      console.error('Ошибка при получении тарифных планов');
      throw e;
    }
  },
  async CREATE_TEAM_PAYER({ dispatch, commit }, requestData) {
    try {
      const { data } = await apiBilling.createTeamPayer(requestData);
      commit('addPayer', data);

      const { teamId } = requestData;
      dispatch('GET_TEAM_BILLING_TARIFF', teamId);
      return data;
    } catch (e) {
      console.error('Ошибка при создании плательщика');
      throw e;
    }
  },
  async CREATE_BILLING_PERSONAL_OFFER(_skip, requestData) {
    try {
      const { data } = await apiBilling.createBillingPersonalOffer(requestData);
      return data;
    } catch (e) {
      console.error('Ошибка при отправке формы персонального предложения');
      throw e;
    }
  },
  async CREATE_BILLING_CARD({ dispatch }, requestData) {
    try {
      const { data } = await apiBilling.createBillingCard(requestData);

      const { teamId } = requestData;
      dispatch('GET_TEAM_BILLING_TARIFF', teamId);
      return data;
    } catch (e) {
      console.error('Ошибка при создании карты команды');
      throw e;
    }
  },
  async DELETE_TEAM_CARD({ commit }, { teamId, cardId }) {
    try {
      await apiBilling.deleteBillingCard({ teamId, cardId });
      commit('deleteCard', cardId);
    } catch (e) {
      console.error('Ошибка при удалении карты команды', e);
      throw e;
    }
  },
  async TOP_UP_BALANCE({ commit }, requestData) {
    try {
      const { data } = await apiBilling.topUpBalance(requestData);
      requestData.requestBody.card && commit('updateBalance', data.balance);
      return data;
    } catch (e) {
      console.error('Ошибка при пополнении баланса');
      throw e;
    }
  },
  async GET_BILLING_INVOICE(_skip, { teamId, invoiceId }) {
    try {
      const { data } = await apiBilling.getBillingInvoice({ teamId, invoiceId });
      return data;
    } catch (e) {
      console.error('Ошибка при получении данных счёта');
      throw e;
    }
  },
  async SEND_BILLING_INVOICE(_skip, requestData) {
    try {
      await apiBilling.sendBillingInvoice(requestData);
    } catch (e) {
      console.error('Ошибка при отправке счёта на почту');
      throw e;
    }
  },
  async GET_SPACES_LIST() {
    try {
      const { data } = await apiBilling.getSpacesList();
      return data;
    } catch (e) {
      console.error('Ошибка при получении дополнительного места');
      throw e;
    }
  },
  async GET_BILLING_PAYMENTS({ commit, rootState }, requestData) {
    try {
      commit('loading/startLoading', 'payments', root);

      const parseDataToServerFormat = (data) => {
        return Object.entries(data).reduce((acc, [key, value]) => {
          acc[key] = value instanceof Array ? value.join(',') : value;
          return acc;
        }, {});
      };

      const { dateCodes, directionCodes, payerCodes, paymentStatusCodes } = rootState.route.query;

      const parsedData = parseDataToServerFormat({
        dateCodes,
        directionCodes,
        payerCodes,
        paymentStatusCodes,
      });

      const getParams = {
        created_at: parsedData.dateCodes,
        payer: parsedData.payerCodes,
        transaction_type: parsedData.directionCodes,
        payment_status: parsedData.paymentStatusCodes,
      };
      requestData.getParams = { ...requestData.getParams, ...getParams };

      const {
        data: { results, num_pages: maxPages },
      } = await apiBilling.getBillingPayments(requestData);

      commit('setMaxPages', maxPages);
      return results;
    } catch {
      console.error('Ошибка при получении транзакций');
    } finally {
      commit('loading/finishLoading', 'payments', root);
    }
  },

  async DELETE_BILLING_PAYER({ commit }, requestData) {
    try {
      await apiBilling.deleteBillingPayer(requestData);
      commit('deletePayer', requestData.payerId);
    } catch (e) {
      console.error('Ошибка при удалении плательщика');
      throw e;
    }
  },
  async EDIT_BILLING_PAYER({ commit }, requestData) {
    try {
      const { data } = await apiBilling.editBillingPayer(requestData);
      commit('updatePayer', data);
    } catch (e) {
      console.error('Ошибка при редактировании плательщика');
      throw e;
    }
  },
  async EDIT_BILLING_SETTINGS({ commit, dispatch }, requestData) {
    try {
      const { data: updatedTariff } = await apiBilling.editBillingSettings(requestData);
      commit('setTariff', updatedTariff);
      dispatch('permissions/INITIALIZE_BILLING_PERMISSIONS', updatedTariff);
    } catch (e) {
      console.error('Ошибка при изменении настроек тарифа');
      throw new RequestError(e);
    }
  },
  async GET_BILLING_PAYER(_skip, requestData) {
    try {
      const { data } = await apiBilling.getBillingPayer(requestData);
      return data;
    } catch (e) {
      console.error('Ошибка при получении плательщика');
      throw e;
    }
  },
  async CHECK_PROMO_CODE(_skip, promoCode) {
    try {
      const { data: _promoCode } = await apiBilling.checkPromoCode(promoCode);
      return _promoCode;
    } catch (e) {
      console.error('Ошибка при проверке промокода');
      throw e;
    }
  },
  async CREATE_STRIPE_SESSION_ID(_skip, requestBody) {
    try {
      const {
        data: { session_id: sessionId },
      } = await apiBilling.createStripeSessionId(requestBody);
      return sessionId;
    } catch (e) {
      console.error('Ошибка при создании идентификатора страйп');
      throw e;
    }
  },
};

export default {
  namespaced: true,

  modules: {
    permissions,
  },

  state,
  getters,
  actions,
  mutations,
};
