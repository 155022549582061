export default {
  invite: 'Invite',
  personal: {
    language: 'Language',
    integrations: 'Integrations',
    partner: 'Partner Program',
    settings: 'Settings',
    logout: 'Logout',
  },
  updates: {
    latest: 'Latest',
    all: 'All',
  },
};
